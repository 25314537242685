import { Inject, Injectable } from '@angular/core';
import { IContractArticleGroup } from '@pia/pia.shared';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { ContractArticleGroupDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { ContractArticleGroupDto } from 'src/app/shared/models/contract-article-group/contract-article-group-dto.model';
import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../../shared/services/contracts/sync/database-synchronizer_T';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class ContractArticleGroupWorkItem
  extends WorkItem<ContractArticleGroupDto>
  implements IDatabaseSynchronizer_T<IContractArticleGroup>
{
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this.deletable = false;
  }

  get name(): string {
    return 'ContractArticleGroupWorkItem';
  }

  get database(): string {
    return ContractArticleGroupDB;
  }
}
