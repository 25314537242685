import { Inject, Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { IShutdownBroker } from 'src/app/business/maintenance/contracts/shutdown-broker';

import makeDebug from '../../../../makeDebug';
import { ConnectionStateService } from '../../services/connection-state.service';
import { IConnectionStateService } from '../../services/contracts/sync/connection-state-service';
import { ShutdownBroker } from '../../services/maintenance/shutdown-broker.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { ShutdownInfoListener as Listener } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:shutdown-info-listener');

@Injectable()
export class ShutdownInfoListener implements ISyncElement {
  public get name(): string {
    return Listener;
  }

  constructor(
    @Inject(ShutdownBroker) private _shutdownBroker: IShutdownBroker,
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService
  ) {}

  public execute(context: ISyncContext): Promise<void> {
    debug('entered execute function', context.params.isOnline);

    return new Promise(resolve =>
      this._shutdownBroker.shutdownWatch
        .pipe(
          take(1),
          filter(shutdownInfo => shutdownInfo != null)
        )
        .subscribe(shutdownInfo => {
          if (!shutdownInfo.userIsItLabs) {
            context.params.isOnline = !shutdownInfo.shutdown && this._connectionStateService.getSocketConnectionState();
            this._connectionStateService.setConnected(context.params.isOnline);
          }

          debug('called next with shutdownInfo and resolving', shutdownInfo, context.params.isOnline);
          resolve();
        })
    );
  }
}
