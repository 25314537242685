import { IStandardCareProposal } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { StandardCareProposalWorkItem } from 'src/app/business/standard-careproposal/standard-careproposal-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class StandardCareProposalCommandHandler extends EntityCommandHandler<IStandardCareProposal> {
  protected workItem: IWorkItem<IStandardCareProposal>;

  constructor(standardCareproposalWorkItem: StandardCareProposalWorkItem) {
    super();
    this.workItem = standardCareproposalWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'StandardCareProposalDto';
  }
}
