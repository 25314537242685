<div class="label" [ngClass]="{ 
  warning: type === LabelType.Warning, 
  information: type === LabelType.Information, 
  note: type === LabelType.Note
}" [style]="labelStyle">
  <ng-content *ngIf="type === LabelType.Custom"></ng-content>
  <div *ngIf="type !== LabelType.Custom">
    <div *ngIf="type === LabelType.Warning">Warnung</div>
    <div *ngIf="type === LabelType.Information">Hinweis</div>
    <div *ngIf="type === LabelType.Note">Notiz</div>
  </div>
</div>
