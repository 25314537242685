import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserPipesModule } from 'src/app/common/pipes/user/user-pipes.module';
import { AlbertaInputsModule } from 'src/app/shared/components/inputs/inputs-components.module';
import { WizardPageModule } from 'src/app/shared/components/wizard-page/wizard-page.module';

import { FaxCreateModal } from './fax-create.modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    UserPipesModule,
    AlbertaInputsModule,
    WizardPageModule,
  ],
  declarations: [FaxCreateModal],
})
export class FaxCreateModalModule {}
