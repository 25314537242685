import { IGroup, IRegion, IRightset } from '@pia/pia.shared';
import { ignore } from 'src/app/common/tracking/tracker';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';

export class GroupViewmodel extends BaseViewModel implements IGroup {
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  regionId: string;
  label: string;
  rightSet: string[] = [];

  // invidual Properties
  rightSetItems: IRightset[] = [];
  region: IRegion;
  rights;

  @ignore()
  showDetails: number;
  groupLeads: string[];
  constructor() {
    super();
  }

  public loadRights() {
    this.rights = {};
    this.rightSetItems.forEach(rightset => {
      if (rightset.permission) {
        Object.keys(rightset.permission).forEach(key => {
          if (this.rights[rightset.permission[key].domain]) {
            if (this.rights[rightset.permission[key].domain] < rightset.permission[key].permission) {
              this.rights[rightset.permission[key].domain] = rightset.permission[key].permission;
            }
          } else {
            this.rights[rightset.permission[key].domain] = rightset.permission[key].permission;
          }
        });
      }
    });
  }
}
