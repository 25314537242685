import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusBarService } from '../../services/statusbar.service';

@Component({
  selector: 'itl-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss'],
  animations: [
    trigger('showleaveAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
      transition(':leave', [animate('0.2s', style({ opacity: 0 }))]),
    ]),
  ],
})
export class WizardPageComponent implements OnInit, OnDestroy {
  @Input() public pageTitleTransPath: string;
  @Input() public sectionTitleTransPath: string;
  @Input() public sectionIcon: string;
  @Input() public currentPageId: number;
  @Input() public allPageCount: number;
  @Input() public pageIsValid = true;
  @Input() public isSinglePage = false;
  @Input() public allowNext = true;
  @Input() public showOnlyCancel = false;
  @Input() public showOnlyDone = false;
  @Input() public doneIcon = 'alberta-save';
  @Input() public cancelIcon = 'alberta-cancel';
  @Input() public sectionIsInvalid = false;
  @Input() public sectionHiddenInNavigation = false;
  @Output() public doneClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public cancelClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public sectionChangeClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() public loadPreviousClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() loadNextClicked: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('wizardContent', { static: true })
  private _wizardContent;

  constructor(public translate: TranslateService, private _statusBarService: StatusBarService) {}

  ngOnInit() {
    this._statusBarService.setColorFromCssVariable('--alberta-color-primary-variant');
  }

  ngOnDestroy() {
    this._statusBarService.setColorFromCssVariable('--alberta-color-primary');
  }

  public done() {
    if (!this.pageIsValid) {
      return;
    }
    this.doneClicked.emit();
  }
  public cancel() {
    this.cancelClicked.emit();
  }
  public changeSection() {
    this.sectionChangeClicked.emit();
  }
  public loadPrevious() {
    if (this.currentPageId !== 0) {
      this.loadPreviousClicked.emit();
      this._wizardContent.nativeElement.scrollTop = 0;
    }
  }
  public loadNext() {
    if (this.currentPageId !== this.allPageCount && this.allowNext) {
      this.loadNextClicked.emit();
      this._wizardContent.nativeElement.scrollTop = 0;
    }
  }
}
