import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { IRouteParser } from '../contracts/route/route-parser';
import { AppointmentRouteParser } from './appointment-route-parser';
import { AttachmentRouteParser } from './attachment-route-parser';
import { DocumentRouteParser } from './document-route-parser';
import { InstitutionDocumentRouteParser } from './institution-document-route-parser';
import { InstitutionRouteParser } from './institution-route-parser';
import { IntegratedCareRouteParser } from './integratedCare-route-parser';
import { NoteRouteParser } from './note-route-parser';
import { NursingHomeRouteParser } from './nursingHome-route-parser';
import { PatientRouteParser } from './patient-route-parser';
import { SingleOrderRouteParser } from './single-order-route-parser';

export const RouteParserToken = new InjectionToken<IRouteParser>('RouteParserToken');

@NgModule({
  providers: [],
})
export class RouteParserModule {
  static forChild(): ModuleWithProviders<RouteParserModule> {
    return { ngModule: RouteParserModule, providers: [] };
  }

  static forRoot(): ModuleWithProviders<RouteParserModule> {
    return {
      ngModule: RouteParserModule,
      providers: [
        { provide: RouteParserToken, useClass: PatientRouteParser, multi: true },
        { provide: RouteParserToken, useClass: SingleOrderRouteParser, multi: true },
        { provide: RouteParserToken, useClass: DocumentRouteParser, multi: true },
        { provide: RouteParserToken, useClass: AttachmentRouteParser, multi: true },
        { provide: RouteParserToken, useClass: IntegratedCareRouteParser, multi: true },
        { provide: RouteParserToken, useClass: NoteRouteParser, multi: true },
        { provide: RouteParserToken, useClass: AppointmentRouteParser, multi: true },
        { provide: RouteParserToken, useClass: NursingHomeRouteParser, multi: true },
        { provide: RouteParserToken, useClass: InstitutionDocumentRouteParser, multi: true },
        { provide: RouteParserToken, useClass: InstitutionRouteParser, multi: true },
      ],
    };
  }
}
