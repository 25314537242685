import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { PatientAppUserDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { IPatientAppUser } from 'src/app/shared/models/patient-app/patient-app-user';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class PatientAppUserWorkItem extends WorkItem<IPatientAppUser> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  get name(): string {
    return 'PatientAppUserWorkItem';
  }

  get database(): string {
    return PatientAppUserDB;
  }
}
