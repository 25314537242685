<img class="image" [src]="this.imageSrc" />
<div class="content-container">
  <h2 class="title">{{ this.title }}</h2>
  <p class="subtitle">{{ this.subtitle }}</p>
  <ng-container *ngIf="this.buttonText">
    <itl-button (click)="handleClick()">
      <ion-icon *ngIf="this.icon" [name]="this.icon"></ion-icon>
      {{ this.buttonText }}
    </itl-button>
  </ng-container>
</div>
