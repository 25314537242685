import { Inject, Injectable, NgZone } from '@angular/core';
import { IIntermediary } from '@pia/pia.shared';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { IntermediaryChannel } from '../../../business/institution/contracts/intermediary.channel';
import { IntermediaryModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class IntermediarySyncService extends SyncService<IIntermediary> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IIntermediary>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(IntermediaryModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return (
      channel === IntermediaryChannel.CREATE ||
      channel === IntermediaryChannel.EDIT ||
      channel === IntermediaryChannel.DELETE
    );
  }
}
