import { IPatient, IPatientNote } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PatientNoteViewModel } from 'src/app/business/patientNotes/viewmodels/patient-notes-view-model';
import { map } from 'src/app/common/mapper/mapper';
import { PatientDB, PatientNotesDB } from 'src/app/common/repository/databases';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable()
export class NotesResolver implements Resolve<{ patient: IPatient; note: IPatientNote; patientId: string }> {
  constructor(private _queryService: QueryService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const patientId = route.paramMap.get('patientId');
    const note = map(
      await this._queryService.get<IPatientNote>(route.paramMap.get('noteId'), PatientNotesDB),
      new PatientNoteViewModel()
    );
    const patient = await this._queryService.get<IPatient>(patientId, PatientDB);

    return { patientId, note, patient };
  }
}
