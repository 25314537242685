import { Component, Input, OnChanges } from '@angular/core';
import { hexToHSL } from 'src/app/shared/components/common/label/hex-to-hsl-converter';

export enum LabelType {
  Custom,
  Information,
  Warning,
  Note,
}

@Component({
  selector: 'itl-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnChanges {
  @Input() type: LabelType = LabelType.Custom;
  @Input() baseColor: string;
  public labelStyle: string;
  public LabelType = LabelType;

  ngOnChanges(): void {
    if (this.type === LabelType.Information) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-info').trim();
    } else if (this.type === LabelType.Warning) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-warning').trim();
    } else if (this.type === LabelType.Note) {
      this.baseColor = getComputedStyle(document.documentElement).getPropertyValue('--alberta-label-note').trim();
    }
    this.setLabelStyle();
  }

  private setLabelStyle(): void {
    if (!this.baseColor) {
      return;
    }
    const hsl = hexToHSL(this.baseColor);
    const hslPrefix = 'hsl(' + hsl.hue + ',' + hsl.saturation + '%,';
    this.labelStyle = `
      background-color: ${hslPrefix}95%);
      border-color: ${hslPrefix}70%);
      color: ${hslPrefix}${hsl.lightness}%);
    `;
  }
}
