import { IModel } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { SyncTimestampDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { SyncTimestampDto } from 'src/app/shared/models/sync-timestamp-dto';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class SyncTimestampWorkItem extends WorkItem<SyncTimestampDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'SyncTimestampWorkItem';
  }
  get database(): string {
    return SyncTimestampDB;
  }

  public async getSyncTimestamp(sourceName: string): Promise<Date> {
    const syncTimestamps = await super.getAll();
    const sourceTimestampItem = syncTimestamps.find(st => st._id === sourceName);
    const timestamp =
      sourceTimestampItem && sourceTimestampItem.latestTimestamp ? sourceTimestampItem.latestTimestamp : new Date(0);

    return timestamp;
  }

  public async setSyncTimestamp(sourceName: string, payload: any, documentCount: number): Promise<void> {
    if (!payload) {
      return;
    }

    const maxTimestamp = this.getLatestDate(payload);
    if (!maxTimestamp) {
      return;
    }

    await super.create(new SyncTimestampDto(sourceName, maxTimestamp, documentCount));
    console.log(`SYNCTIMESTAMPWORKITEM SETSYNCTIMESTAMP / Gespeichert ${sourceName} ${maxTimestamp}`);
  }

  public getLatestDate(items: IModel[]): Date {
    if (!items.length) {
      return undefined;
    }

    const sortedItems = items.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    return sortedItems[sortedItems.length - 1] && sortedItems[sortedItems.length - 1].timestamp;
  }
}
