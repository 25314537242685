<button
  class="stdCareProposalWrapper"
  [ngClass]="{
    'stdCareProposalWrapper--selected': selected
  }"
  (click)="this.tileClicked.emit()"
>
  {{ name }}
  <div class="stdCareProposalWithArticles" *ngIf="withArticle">
    <ion-icon name="alberta-article"></ion-icon> Artikel vorbelegt
  </div>
</button>
