import { IModel } from '@pia/pia.shared';

import { Changes } from '../common/tracking';
import { Command } from './command';
import { ICommand } from './contracts/command';

export class EntityUpdateCommand<T extends IModel> extends Command implements ICommand {
  constructor(
    public changes: Changes,
    public entity: T,
    public channel: string,
    dtoName: string,
    public additionalSyncInfo?: Record<string, any>
  ) {
    super(dtoName);
  }
}
