import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      window.logger.error('UnhandledException', error.originalError || error);
    } catch (e) {
      window.logger.error('Sentry was unable to capture exception.', e);
    }
  }
}
