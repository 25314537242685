import { IConfig, IInstitutionContactFunction } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { Deferred } from 'src/app/common/deferred/deferred';
import { TenantConfigService } from 'src/app/shared/services/tenant-config/tenant-config.service';
import { IGeneralEntityService } from './contracts/general-entity.service';
import { DEFAULTINSTITUTIONCONTACTFUNCTIONS } from './institution-contact-default-functions';

@Injectable({ providedIn: 'root' })
export class InstitutionContactFunctionService implements IGeneralEntityService {
  public restriction: any;
  public responsibleTypes = ['institutionContactFunction'];
  public type = 'institutionContactFunction';
  private _ready: Deferred<void> = new Deferred();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }
  private tenantConfig: IConfig;
  private institutionContactFunctions: IInstitutionContactFunction[];
  private language: string;

  constructor(private _tenantConfigService: TenantConfigService) {
    this.sortByTranslation = this.sortByTranslation.bind(this);
    this.language = 'de';

    this.loadConfig().then(() => this._ready.resolve());
  }
  public async getAll(): Promise<IInstitutionContactFunction[]> {
    await this._ready.promise;

    return this.institutionContactFunctions;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isRestricted(item: any): boolean {
    return false;
  }
  public clone(): IGeneralEntityService {
    return new InstitutionContactFunctionService(this._tenantConfigService);
  }
  public async find(id: string): Promise<any> {
    await this._ready.promise;

    return new Promise<any>(resolve => {
      const institutionContactFunction = this.institutionContactFunctions.find(f => f.key === id);
      if (institutionContactFunction) {
        resolve(institutionContactFunction);
      }
      resolve(null);
    });
  }
  public async query(query: string): Promise<any> {
    await this._ready.promise;

    const words = query.split(' ');
    const showAllResults = query === '***';
    const regEx = showAllResults ? new RegExp('') : this.buildRegEx(words);
    const searchResults = this.institutionContactFunctions.reduce((prev, element) => {
      const lowerCaseString = element.translation[this.language].toLowerCase();
      if (showAllResults || regEx.test(lowerCaseString)) {
        if (element) {
          prev.push(element);
        }
      }
      return prev;
    }, [] as IInstitutionContactFunction[]);

    return searchResults.sort(this.sortByTranslation);
  }

  private async loadConfig(): Promise<void> {
    if (!this.tenantConfig) {
      this.tenantConfig = await this._tenantConfigService.getTenantConfig();
    }
    if (!this.institutionContactFunctions) {
      const institutionContactFunctions = await this.getInstitutionContactFunctions();
      this.institutionContactFunctions = institutionContactFunctions
        .sort(this.sortByTranslation)
        .map(institutionContactFunction => {
          (institutionContactFunction as any)._id = institutionContactFunction.key;
          return institutionContactFunction;
        });
    }
  }

  private sortByTranslation(a: IInstitutionContactFunction, b: IInstitutionContactFunction) {
    const atranslated = a.translation[this.language] ? a.translation[this.language] : '';
    const btranslated = b.translation[this.language] ? b.translation[this.language] : '';
    return atranslated.localeCompare(btranslated);
  }

  private getInstitutionContactFunctions() {
    if (
      this.tenantConfig &&
      this.tenantConfig.institutionContactFunctions &&
      this.tenantConfig.institutionContactFunctions.length
    ) {
      return this.tenantConfig.institutionContactFunctions;
    }
    return DEFAULTINSTITUTIONCONTACTFUNCTIONS;
  }

  private buildRegEx(words: string[]): RegExp {
    let regExString = '^';
    words.forEach(word => {
      if (word !== '') {
        regExString += '(?=.*default)'.replace('default', word.toLowerCase());
      }
    });
    regExString += '.*$';
    return new RegExp(regExString, 'i');
  }
}
