import { IAudit } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { AuditDB } from 'src/app/common/repository/databases';
import { MessagePayloadDocument } from 'src/app/shared/models/share/message-payload-document';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class DocumentRouteParser implements IRouteParser {
  type = MessagePayloadType.Document;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadDocument): Promise<string> {
    const audit: IAudit = await this._queryService.get(payload.documentId, AuditDB);

    if (!audit) {
      throw new Error(`No document found for id ${payload.documentId}`);
    }

    return `/patient/${audit.patientId}/documents/${audit._id}`;
  }
}
