import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { interval, Observable, Subject } from 'rxjs';
import { map, takeUntil, timeout } from 'rxjs/operators';
import { MaintenanceDto } from 'src/app/shared/models/maintenance/maintenance-dto.model';

import {
  ICountdown,
  ICountdownInfo,
} from '../../components/maintenance/maintenance-snack-bar/contracts/countdown-info';

@Injectable({ providedIn: 'root' })
export class CountdownService {
  private day = 60 * 60 * 24;
  private _countdown$ = new Subject<ICountdownInfo>();
  public get countdown(): Observable<ICountdownInfo> {
    return this._countdown$.asObservable();
  }

  private _cancelCountDown$ = new Subject();
  private _canBeStarted = false;
  private _startDiff;
  private _endDiff;
  private _maintenance: MaintenanceDto;

  constructor(private _translateService: TranslateService) {}

  public start(maintenance: MaintenanceDto): boolean {
    if (!maintenance) {
      return false;
    }

    if (this._maintenance && this._maintenance.isEqual(maintenance)) {
      if (maintenance && maintenance.stopped) {
        this.stop();
        return false;
      }
      return true;
    }

    if (
      this._maintenance &&
      this._maintenance._id === maintenance._id &&
      (this._maintenance.start !== maintenance.start || this._maintenance.end !== maintenance.end)
    ) {
      this.stop();
    }
    this._maintenance = maintenance;

    if (this.mustBeStopped()) {
      this.stop();
      return false;
    }
    this._canBeStarted = true;
    this.startInternal();

    return true;
  }

  public stop() {
    this._cancelCountDown$.next();
    this._cancelCountDown$.complete();
    this._cancelCountDown$ = new Subject();
    this._canBeStarted = false;
    this._maintenance = null;
  }

  public redrawTimeoutMs() {
    const secondsToStart = moment(this._maintenance.start).diff(moment()) / 1000;
    if (!secondsToStart || secondsToStart < 0 || Math.floor(secondsToStart / 3600) < 1) {
      return 0;
    }

    return (secondsToStart - this.day) * 1000;
  }

  private startInternal(byEnd?: boolean) {
    if (!this._canBeStarted || this.mustBeStopped()) {
      this.stop();
      return;
    }

    interval(1000)
      .pipe(
        timeout(new Date(byEnd ? this._maintenance.end : this._maintenance.start)),
        map(tick => (byEnd ? this._endDiff : this._startDiff) / 1000 - tick),
        takeUntil(this._cancelCountDown$)
      )
      .subscribe(
        remainingSeconds =>
          this._countdown$.next({
            countdown: this.transform(remainingSeconds, byEnd),
            startEndText: byEnd
              ? this._translateService.instant('maintenance.end')
              : this._translateService.instant('maintenance.start'),
          }),
        () => {
          if (this._maintenance.end && !this.mustBeStopped()) {
            this.startInternal(true);
          }
        }
      );
  }

  private transform(time: number, byEnd: boolean): ICountdown {
    if (!time) {
      return { text: '00:00:00', lessThanOneHour: false, moreThanOneDay: false };
    }

    const days = Math.floor(time / 3600 / 24);

    if (days >= 1) {
      return { text: 'mehr als 1 Tag.', lessThanOneHour: byEnd, moreThanOneDay: !byEnd };
    }
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);

    return {
      text: `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`,
      lessThanOneHour: hours === 0 || byEnd,
      moreThanOneDay: false,
    };
  }

  private mustBeStopped() {
    this._startDiff = moment(this._maintenance.start).diff(moment());
    this._endDiff = moment(this._maintenance.end).diff(moment());

    return this._maintenance.stopped || moment(this._maintenance.end).isBefore(moment());
  }
}
