import { Injectable } from '@angular/core';
import {
  IDoctor,
  IHospital,
  IInstitution,
  InstitutionType,
  INursingHome,
  INursingService,
  IPharmacy,
} from '@pia/pia.shared';
import { DoctorDB, HospitalDB, NursingHomeDB, NursingServiceDB, PharmacyDB } from 'src/app/common/repository/databases';
import { FaxContact } from 'src/app/shared/models/fax-contact.model';
import { WizardStepItem } from 'src/app/shared/models/wizard-step-item';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionsService {
  constructor(private _queryService: QueryService) {}

  /**
   * Construct a title for the given institution and institutiontype
   */
  public getInstitutionTitle(institutionId: string, institutionType: InstitutionType): Promise<string> {
    if (!institutionId || !institutionType) {
      return Promise.resolve('');
    }

    switch (institutionType) {
      case InstitutionType.Doctor:
        return this._queryService
          .get<IDoctor>(institutionId, DoctorDB)
          .then(doctor => `${doctor.titleShort || ''} ${doctor.firstName || ''} ${doctor.lastName || ''}`);
      case InstitutionType.Hospital:
      case InstitutionType.Pharmacy:
      case InstitutionType.NursingHome:
      case InstitutionType.NursingService:
        return this.getInstitution(institutionId, institutionType).then(institution =>
          Promise.resolve(`${institution.name || ''}`)
        );
      default:
        return Promise.resolve('');
    }
  }

  /**
   * Returns an IInstitution promise of an institution (note: NOT doctor, this is a special type of institution)
   * @param id institution id
   * @param type institution type
   */
  public async getInstitution(id: string, type: InstitutionType): Promise<IInstitution> {
    switch (type) {
      case InstitutionType.Hospital:
        return await this._queryService.get<IHospital>(id, HospitalDB);
      case InstitutionType.Pharmacy:
        return await this._queryService.get<IPharmacy>(id, PharmacyDB);
      case InstitutionType.NursingHome:
        return await this._queryService.get<INursingHome>(id, NursingHomeDB);
      case InstitutionType.NursingService:
        return await this._queryService.get<INursingService>(id, NursingServiceDB);
      case InstitutionType.Doctor:
        throw new Error('InstitutionType Doctor cannot return an IInstitution. Use getDoctor() instead.');
      default:
        return undefined;
    }
  }

  /**
   * Return an IDoctor from the database. Use this instead of getInstitution() for doctors.
   * @param id doctor id
   */
  public async getDoctor(id: string): Promise<IDoctor> {
    return await this._queryService.get<IDoctor>(id, DoctorDB);
  }

  /**
   * Construct the WizardStepItems for the given institution and institutiontype
   */
  public getTabItems(institutionType: InstitutionType, hasActivitesRight: number): WizardStepItem[] {
    const tabitems: WizardStepItem[] = [];
    tabitems.push(this.getInstitutionMasterTabItem(institutionType));
    if (institutionType !== InstitutionType.Doctor) {
      tabitems.push(new WizardStepItem('institutions.crm.subunits', 'subunits', 'alberta-subunit'));
    }
    tabitems.push(new WizardStepItem('institutions.crm.institutionContacts', 'contacts', 'alberta-contact-data'));
    tabitems.push(new WizardStepItem('institutions.crm.institutionNotes', 'notes', 'alberta-note'));
    if (institutionType === InstitutionType.NursingHome && hasActivitesRight) {
      tabitems.push(new WizardStepItem('institutions.crm.activities', 'activities', 'alberta-activities'));
    }
    tabitems.push(new WizardStepItem('institutions.crm.documents', 'documents', 'alberta-document'));
    return tabitems;
  }

  private getInstitutionMasterTabItem(institutionType: InstitutionType): WizardStepItem {
    switch (institutionType) {
      case InstitutionType.Doctor:
        return new WizardStepItem('patient.details.sections.masterdata', 'doctor-master', 'alberta-clipboard');
      case InstitutionType.Hospital:
        return new WizardStepItem('patient.details.sections.masterdata', 'hospital-master', 'alberta-clipboard');
      case InstitutionType.Pharmacy:
        return new WizardStepItem('patient.details.sections.masterdata', 'pharmacy-master', 'alberta-clipboard');
      case InstitutionType.NursingHome:
        return new WizardStepItem('patient.details.sections.masterdata', 'nursing-home-master', 'alberta-clipboard');
      case InstitutionType.NursingService:
        return new WizardStepItem('patient.details.sections.masterdata', 'nursing-service-master', 'alberta-clipboard');
    }
  }

  public async getDoctorFaxContacts(id: string): Promise<FaxContact[]> {
    const doctor: IDoctor = await this._queryService.get<IDoctor>(id, DoctorDB);
    if (!doctor || !doctor.fax) {
      return [];
    }
    const title = `${doctor.title ? doctor.title : doctor.titleShort ? doctor.titleShort : ''}`;
    return [
      new FaxContact(
        `${title} ${doctor.firstName} ${doctor.lastName}`.trim(),
        doctor.fax,
        await this.getPostalAddress(doctor),
        'Arzt'
      ),
    ];
  }

  public async getInstitutionFaxContact(institution: IInstitution): Promise<FaxContact[]> {
    if (institution?.fax) {
      return [
        new FaxContact(institution.name, institution.fax, await this.getPostalAddress(institution), 'Institution'),
      ];
    } else {
      return [];
    }
  }

  private getPostalAddress(institution: IInstitution | IDoctor) {
    const postalAddress = `${institution.address}, ${institution.postalCode} ${institution.city}`;
    return postalAddress
      .replace(/undefined/g, '')
      .replace(/\s\s+/g, ' ')
      .trim();
  }
}
