import { IModel } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { Deferred } from 'src/app/common/deferred/deferred';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { IQuery } from '../contracts/query/query';
import { IQuerySearchOptions } from '../contracts/query/query-search-options';
import { IQueryService } from '../contracts/query/query-service';

@Injectable({
  providedIn: 'root',
})
export class QueryService implements IQueryService {
  private unitOfWork: IUnitOfWork;
  private _ready: Deferred<void> = new Deferred();

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    (async () => {
      this.unitOfWork = await unitOfWorkFactory.create();
      this._ready.resolve();
    })();
  }

  async get<T extends IModel>(id: string, database: string, options?: { useOnlineDatabase?: boolean }): Promise<T> {
    await this._ready.promise;

    const useOnlineDatabase = options ? !!options.useOnlineDatabase : false;

    const repository = await this.unitOfWork.create<T>(useOnlineDatabase ? `${database}::online` : database);

    return repository.get(id);
  }

  async getAll<T extends IModel>(database: string): Promise<T[]> {
    await this._ready.promise;

    const repository = await this.unitOfWork.create<T>(database);

    return repository.getAll();
  }

  async search<T extends IModel>(query: IQuery, database: string, options?: IQuerySearchOptions): Promise<T[]> {
    await this._ready.promise;

    query.isIn = (options || { isIn: false }).isIn;
    if (typeof query.isIn === 'undefined') {
      query.isIn = false;
    }

    query.plainFtsSearch = (options || { plainFtsSearch: false }).plainFtsSearch;
    if (typeof query.plainFtsSearch === 'undefined') {
      query.plainFtsSearch = false;
    }

    query.ignoreRegionIds = (options || { ignoreRegionIds: false }).ignoreRegionIds;

    if (typeof query.ignoreRegionIds === 'undefined') {
      query.ignoreRegionIds = false;
    }

    const repository = await this.unitOfWork.create<T>(query.ignoreRegionIds ? `${database}::online` : database);

    return repository.search(query);
  }
}
