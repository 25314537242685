<itl-wizard-page
  [isSinglePage]="true"
  [pageTitleTransPath]="'fax.sendFax'"
  [pageIsValid]="reportData?.recipients?.length || isIndividualNumber"
  (cancelClicked)="close()"
  (doneClicked)="done()"
>
  <itl-toggle [(ngModel)]="isIndividualNumber" [title]="'fax.individualNumber' | translate"> </itl-toggle>
  <itl-checkbox-select
    *ngIf="!isIndividualNumber"
    [selectionItems]="faxContactsSelectionItems"
    [title]="'fax.selectFaxNumber' | translate"
    [headerText]="'fax.selectFaxNumber' | translate"
    [ngModel]="[]"
    data-testid="select-fax-number"
    (change)="selectChanged($event)"
    [multipleSelectedText]="'fax.multipleSelectedText' | translate"
  >
  </itl-checkbox-select>
  <itl-text *ngIf="isIndividualNumber" [title]="'fax.typeNumber' | translate" type="tel" [(ngModel)]="individualNumber">
  </itl-text>
  <div id="detailsHeader">Zusammenfassung</div>
  <div id="detailsContainer">
    <div class="tableRow">
      <div *ngIf="patient" class="cell" id="patientName">
        <div class="cellHeader">Patientenname</div>
        {{ patient.firstName }} {{ patient.lastName }}
      </div>
      <div *ngIf="institution" class="cell" id="institutionName">
        <div class="cellHeader">Institution</div>
        {{ institution.name }}
      </div>
      <div *ngIf="doctor" class="cell" id="doctorName">
        <div class="cellHeader">Arzt</div>
        {{ doctor.firstName }} {{ doctor.lastName }}
      </div>
      <div class="cell" id="creator">
        <div class="cellHeader">Ersteller</div>
        {{ currentUserId | username | async }}
      </div>
    </div>
  </div>
</itl-wizard-page>
