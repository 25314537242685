import { Injectable, NgZone } from '@angular/core';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { AppController } from 'src/app/common/controller/app-controller';
import { AttachmentDto } from '../../models/attachment/attachment-dto.model';
import { AttachmentModelName } from '../../models/model-names';
import { AttachmentService } from '../attachment/attachment.service';
import { WebSyncService } from './web-sync.service';

@Injectable()
export class WebAttachmentSyncService extends WebSyncService {
  constructor(private _attachmentService: AttachmentService, appController: AppController, ngZone: NgZone) {
    super(AttachmentModelName, ngZone, appController);
  }

  protected async handleData(data: any): Promise<any> {
    await this._attachmentService.save(data);

    const syncState: ISyncState<AttachmentDto> = { updated: [], created: data, deleted: [] };
    return [...syncState.created, ...syncState.deleted, ...syncState.updated];
  }
}
