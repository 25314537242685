import { IModel } from '@pia/pia.shared';
import { Params } from '@angular/router';
import {
  ArticleModelName,
  GroupModelName,
  PostalCodeModelName,
  RegionModelName,
  TemplateModelName,
  UsersModelName,
} from 'src/app/shared/models/model-names';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IQuery } from 'src/app/shared/services/contracts/query/query';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';

import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { Deferred } from '../deferred/deferred';
import { paramsForServer } from '../feathers/params-for-server';

interface ISearchOptions {
  getAll?: boolean;
  operator?: 'AND' | 'OR';
  keyValuePairs?: string;
  table: string;
}

export abstract class DbQueryStrategy<T extends IModel & {}> implements IDbQueryStrategy<T> {
  private _ready = new Deferred<void>();
  protected table: string;

  protected withArchive = [
    ArticleModelName,
    UsersModelName,
    GroupModelName,
    RegionModelName,
    PostalCodeModelName,
    TemplateModelName,
  ];

  public get storage(): Readonly<IGenericStorage> {
    return this._storage;
  }

  constructor(
    private _databaseName: string,
    private _storage: IGenericStorage,
    protected feathersAppProvider: IFeathersAppProvider,
    protected user
  ) {
    this.table = this._databaseName.substring(0, this._databaseName.length - 3);
    this._ready.resolve();
  }

  public abstract getAll(): Promise<T[]>;
  public abstract getItems(keys: string[]): Promise<any[]>;
  public abstract get(id: string): Promise<T>;
  public abstract searchInternal(ids: string[], query?: IQuery): Promise<T[]>;

  get ready(): Promise<void> {
    return this._ready.promise;
  }

  public async search(query: IQuery): Promise<T[]> {
    await this.ready;

    if (!query || !query.query || query.query.length === 0) {
      return [];
    }

    this.splitTooLongQuery(query);

    const indexResults = [];
    let searchOptions: ISearchOptions = null;
    do {
      if (query.plainFtsSearch) {
        searchOptions = this.toSearchOptionsFts(query);
      } else {
        searchOptions = this.toSearchOptions(query);
      }

      const results = await this.feathersAppProvider.app
        .service('search')
        .find(this.getParamsWithAuth(this.user, { query: { searchOptions } }));
      indexResults.push(...(results || []));
    } while (query.isIn && query.queryList && query.queryList.length);

    if (!indexResults || !indexResults.length) {
      return [];
    }

    const result = await this.searchInternal(indexResults, query);

    return result || [];
  }

  protected getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query = {} } = params;

      paramsForServer({
        query,
        syncData: {
          authorization: user.authorization,
          tenantId: user.organization.tenantId,
        },
      });
    }

    return params;
  }

  private splitTooLongQuery(query: IQuery) {
    if (!query.isIn) {
      return;
    }

    const terms = query.query.split(' ');
    if ((terms || []).length > 200) {
      query.queryList = [];
      while (terms.length > 0) {
        query.queryList.push([...terms.splice(0, terms.length > 200 ? 200 : terms.length)]);
      }
    }
  }

  private toSearchOptions(query: IQuery): ISearchOptions {
    const searchOptions: ISearchOptions = { table: this.table, operator: 'AND' };
    if (query.query === '***') {
      return { ...searchOptions, getAll: true };
    }

    if (query.isIn && query.queryList && query.queryList.length) {
      query.query = query.queryList.shift().join(' ');
    }
    query.query = query.query.replace(/\+/g, '');

    searchOptions.keyValuePairs = query.query.split(' ').reduce((searchTerm, word) => {
      const indexOfColon = word.indexOf(':');

      return `${searchTerm}${searchTerm.length > 0 ? ' ' : ''}${
        indexOfColon > -1 ? `${this.splitToLowerCase(word)}*` : `${word.toLocaleLowerCase()}*`
      }`;
    }, '');

    if (query.isIn) {
      searchOptions.operator = 'OR';
    }

    return searchOptions;
  }

  /**
   * Erstellt eine Abfrage nach dem SQLite Fulltext search schema
   * https://www.sqlite.org/fts5.html
   */
  private toSearchOptionsFts(query: IQuery): ISearchOptions {
    const searchOptions: ISearchOptions = { table: this.table, operator: 'AND' };
    if (query.query === '***') {
      return { ...searchOptions, getAll: true };
    }

    if (query.isIn && query.queryList && query.queryList.length) {
      query.query = query.queryList.shift().join(' ');
    }
    query.query = query.query.replace(/\+/g, '');

    searchOptions.keyValuePairs = query.query.split(' ').reduce((searchTerm, word) => {
      const indexOfColon = word.indexOf(':');

      return `${searchTerm}${searchTerm.length > 0 ? ' ' : ''}${indexOfColon > -1 ? `${word}` : `${word}`}`;
    }, '');

    if (query.isIn) {
      searchOptions.operator = 'OR';
    }

    return searchOptions;
  }

  private splitToLowerCase(query: string): string {
    const fieldAndWord = query.split(':');

    if (fieldAndWord.length === 2) {
      return `${fieldAndWord[0]}:${fieldAndWord[1].toLocaleLowerCase()}`;
    }

    return query;
  }
}
