import { Injectable } from '@angular/core';
import { INursingHome } from '@pia/pia.shared';
import { NursingHomeWorkItem } from 'src/app/business/institution/nursing-home-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class NursingHomeCommandHandler extends EntityCommandHandler<INursingHome> {
  protected workItem: IWorkItem<INursingHome>;

  constructor(nursingHomeWorkItem: NursingHomeWorkItem) {
    super();
    this.workItem = nursingHomeWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'NursingHomeDto';
  }
}
