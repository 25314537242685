<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="defaultHref" color="light"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="!header">{{ 'chat.title' | translate }}</ng-container>
      <ng-container *ngIf="header">{{ header }}</ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
