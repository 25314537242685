import { IModel } from '@pia/pia.shared';
import { NgZone } from '@angular/core';
import { Paginated, Params, Query, Service } from '@feathersjs/feathers';
import { cloneDeep, upperFirst } from 'lodash';
import { SyncTimestampWorkItem } from 'src/app/business/timestamp/sync-timestamp-work-item';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { LogLevel } from 'src/app/common/contracts/logging/log-level';
import { IPlatformSync } from 'src/app/common/contracts/sync/platform-sync';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { paramsForServer } from 'src/app/common/feathers/params-for-server';
import { ObservableModel } from 'src/app/common/viewmodel/observable-model';

import { ICancellationToken } from '../../../common/contracts/cancellation/cancellation-token';
import { MaintenanceModelName, PatientAppUserModelName, UsersModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { IAdditionalSyncData } from '../contracts/sync/additional-sync-data';
import { IDatabaseSynchronizer } from '../contracts/sync/database-synchronizer_T';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { IFullSyncService } from '../contracts/sync/service/full-sync-service';
import { ISyncService } from '../contracts/sync/service/sync-service';
import { EventService } from '../event.service';

export abstract class SyncService<T extends IModel> implements ISyncService, IFullSyncService {
  protected _service: Service<any>;
  private _syncTimestampWorkItem: SyncTimestampWorkItem;
  private _workItem: IWorkItem<T> | IDatabaseSynchronizer<T>;
  protected limit = 2000;
  protected ignoreSync = false;
  protected ignoreState: boolean;

  constructor(
    private _name: string,
    protected _appController: IAppController,
    protected _dispatcher: IDispatcher<T>,
    private _eventService: EventService<SyncProgressEvent>,
    private _ngZone: NgZone,
    protected platformSync: IPlatformSync
  ) {
    if (!this._name) {
      throw new Error('Name of service cannot be empty or null/undefined.');
    }

    this._syncTimestampWorkItem = this._appController.getWorkItemByCtor(
      'SyncTimestampWorkItem'
    ) as SyncTimestampWorkItem;

    this._workItem = this._appController.getWorkItemByCtor(`${upperFirst(this._name)}WorkItem`);
  }

  public resolve(): ISyncService[] {
    return [this];
  }

  public abstract canSync(channel: string): boolean;

  protected patchParams(params: Params): Params {
    return params;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected afterSync(syncState: ISyncState<T>): Promise<void> {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, require-await
  protected async afterPatch(entity: T): Promise<void> {
    return;
  }

  public async write(payload: IModel, additionalData: IAdditionalSyncData, channel: string, user): Promise<T> {
    if (!payload) {
      throw new Error('Payload to sync must be a valid object of type IModel');
    }

    const method = channel.substring(channel.lastIndexOf(':') + 1);

    switch (method) {
      case 'create': {
        const params = this.getParamsWithAuth(user);
        return this._service.create(payload, params);
      }
      case 'delete': {
        return this._service.remove(payload._id, this.getParamsWithAuth(user));
      }
      case 'get': {
        return this._service.get(payload._id, this.getParamsWithAuth(user));
      }
      case 'update': {
        const params = this.getParamsWithAuth(user);
        return this._service.update(payload._id, payload, params);
      }
      case 'patch': {
        const params: Params = { query: {} };
        if (additionalData.changes) {
          const { query } = params;
          paramsForServer({
            query,
            syncData: this.createSyncData(user, additionalData),
          });
        }
        const result = await this._service.patch(payload._id, payload, params);
        await this.afterPatch(result);

        return result;
      }
    }
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._service = feathersAppProvider.app.service(this._name);

    this.removeAllListeners();

    this._service.on('created', item => this.onCreated(item));
    this._service.on('updated', item => this.onPatched(item));
    this._service.on('patched', item => this.onPatched(item));
    this._service.on('removed', item => this.onRemoved(item));
  }

  public removeAllListeners(connectionMode?: ConnectionMode): void {
    if (connectionMode && connectionMode === ConnectionMode.maintenance && this._name === MaintenanceModelName) {
      return;
    }

    this._service.removeAllListeners('created');
    this._service.removeAllListeners('updated');
    this._service.removeAllListeners('patched');
    this._service.removeAllListeners('removed');
  }

  private async syncInternal(
    token: ICancellationToken,
    user: any,
    timestamp: Date,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void,
    resetSyncTimestamp: (data) => Promise<void>
  ): Promise<any> {
    if (!this._service) {
      throw new Error(
        `Service with name ${this._name} could not be found. Please specify a correct name for your synchronisation
        service or take care your service instance is running.`
      );
    }

    if (this.ignoreSync) {
      return;
    }

    token.syncCompleted[this._name] = false;

    try {
      let query: Query = {
        $limit: 0,
        timestamp: { $gt: timestamp },
        $sort: { timestamp: 1 },
      };

      let result: any;
      // If the result is not paginated we need to query at least once since the query before limits to 0
      let total = 1;
      let skip = 0;
      let progress = 0;

      if (!token.cancelled.get()) {
        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, { query })));
        resetServiceRepeats(token, this._name);

        total = this.isPaginated(result) ? result.total : total;
        this._eventService.dispatchEvent(
          new SyncProgressEvent(this._name, this.isPaginated(result) ? result.total : result.length, progress)
        );
      }

      while (total > 0 && !token.cancelled.get()) {
        await this.sleep();

        query = { ...query, $limit: this.limit, $skip: skip };

        if (token.cancelled.get()) {
          break;
        }

        result = await this._service.find(this.patchParams(this.getParamsWithAuth(user, { query })));
        resetServiceRepeats(token, this._name);

        const data = this.isPaginated(result) ? result.data : result;

        const syncState = await (this._workItem as IDatabaseSynchronizer<T>).sync(data, token);
        await this._dispatcher.sync(this._name, syncState);
        const payload = [...syncState.created, ...syncState.deleted, ...syncState.updated];

        await resetSyncTimestamp({ payload, documentCount: result.documentCount });
        await this.afterSync(syncState);

        try {
          progress += payload.length;
          this._eventService.dispatchEvent(
            new SyncProgressEvent(this._name, this.isPaginated(result) ? result.total : result.length, progress)
          );
        } catch (e) {}
        this.limit = this.isPaginated(result) ? result.limit : this.limit;

        total -= this.limit;
        skip += this.limit;
      }

      token.syncCompleted[this._name] = total <= 0;

      if (token.cancelled.get()) {
        delete token.serviceRepeats[this._name];
        // eslint-disable-next-line no-throw-literal
        throw {
          reason: 'cancelled',
          service: this._name,
          progress: `progress: ${progress}`,
          total: `total: ${total}`,
          level: LogLevel.silent,
        };
      }
    } catch (error) {
      window.logger.error(`SYNCSERVICE SYNC / ${this._name}`, error, error.level);
      throw error;
    }
  }

  public async sync(
    token: ICancellationToken,
    user: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    const timestamp = new Date(await this._syncTimestampWorkItem.getSyncTimestamp(this._name));
    return this.syncInternal(
      token,
      user,
      timestamp,
      resetServiceRepeats,
      async data => await this._syncTimestampWorkItem.setSyncTimestamp(this._name, data.payload, data.documentCount)
    );
  }

  public async reSync(
    token: ICancellationToken,
    user: any,
    userSync: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    if (
      !userSync ||
      !userSync.status ||
      !userSync.status.length ||
      !userSync.status.some(value => value.name === this._name)
    ) {
      return;
    }

    const serviceStatus = userSync.status.find(value => value.name === this._name);

    const userClone = cloneDeep(user);
    userClone.authorization.regions = [...serviceStatus.regions];
    await this.syncInternal(token, userClone, serviceStatus.timestamp, resetServiceRepeats, async data => {
      const syncMaxTimestamp = new Date(await this._syncTimestampWorkItem.getSyncTimestamp(this._name));
      const payloadMaxTimestamp = this._syncTimestampWorkItem.getLatestDate(data.payload);

      serviceStatus.timestamp = new Date(payloadMaxTimestamp || 0);

      if (serviceStatus.timestamp <= syncMaxTimestamp) {
        return;
      }

      await this._syncTimestampWorkItem.setSyncTimestamp(this._name, data.payload, data.documentCount);
    });

    userSync.status = userSync.status.filter(value => value.name !== this._name);
  }

  protected async onCreated(data: any): Promise<void> {
    await (this._workItem as IWorkItem<T>).create(data);

    if (this.ignoreState) {
      return;
    }

    await this._dispatcher.createState(this._name, data);
  }

  protected async onPatched(data: any): Promise<void> {
    // update database only when sync mode is on or when patch is for users
    if (this.platformSync.canBeSynced || this._name === UsersModelName || this._name === PatientAppUserModelName) {
      await (this._workItem as IDatabaseSynchronizer<T>).sync(data, {
        cancelled: new ObservableModel(false, false),
        promise: undefined,
        cancel: () => undefined,
        reset: () => undefined,
      });
    }

    if (this.ignoreState) {
      return;
    }
    await this._dispatcher.updateState(this._name, [data]);
  }

  protected async onRemoved(data: any): Promise<void> {
    if (this.platformSync.canBeSynced) {
      await (this._workItem as IWorkItem<T>).delete(data);
    }

    if (this.ignoreState) {
      return;
    }

    await this._dispatcher.removeFromState(this._name, data);
  }

  private sleep(ms?: number) {
    return new Promise(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }

  private getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query } = params;

      paramsForServer({
        query,
        syncData: this.createSyncData(user),
      });
    }

    return params;
  }

  private createSyncData(user: any, additionalData?: IAdditionalSyncData): any {
    let syncData = {
      authorization: user.authorization,
      emailAPI:
        user.organization.alberta && user.organization.alberta.emailAPI ? user.organization.alberta.emailAPI : false,
      emailTo: user.organization.alberta && user.organization.alberta.emailTo ? user.organization.alberta.emailTo : [],
      returnDeliveryEmailAPI:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailAPI
          ? user.organization.alberta.returnDeliveryEmailAPI
          : false,
      returnDeliveryEmailTo:
        user.organization.alberta && user.organization.alberta.returnDeliveryEmailTo
          ? user.organization.alberta.returnDeliveryEmailTo
          : [],
      tenantId: user.organization.tenantId,
    };
    if (additionalData) {
      if (additionalData.additionalSyncInfo) {
        syncData = { ...syncData, ...additionalData.additionalSyncInfo };
      }
      if (additionalData.changes) {
        syncData['changes'] = additionalData.changes;
      }
    }

    return syncData;
  }

  private isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
