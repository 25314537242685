import { ITemplate } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { TemplateDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { TemplateDto } from 'src/app/shared/models/swodoc/template-dto.model';

import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../../shared/services/contracts/sync/database-synchronizer_T';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class TemplateWorkItem extends WorkItem<TemplateDto> implements IDatabaseSynchronizer_T<ITemplate> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this.deletable = false;
  }
  get name(): string {
    return 'TemplateWorkItem';
  }
  get database(): string {
    return TemplateDB;
  }
}
