import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IStandardCareProposal } from '@pia/pia.shared';

@Component({
  selector: 'itl-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent implements OnInit {
  @Input() selected: boolean;
  @Input() name: string;
  @Input() standardCareProposal?: IStandardCareProposal;
  @Output() tileClicked: EventEmitter<void> = new EventEmitter<void>();

  public withArticle = false;

  ngOnInit() {
    if (this.standardCareProposal) {
      if (this.standardCareProposal.predefinedArticles && this.standardCareProposal.predefinedArticles.length) {
        if (
          this.standardCareProposal.predefinedArticles.every(predefinedArticle => predefinedArticle.hideInShoppingCart)
        ) {
          this.withArticle = false;
        } else {
          this.withArticle = true;
        }
      } else {
        {
          this.withArticle = false;
        }
      }
    }
  }
}
