import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Permission } from '@pia/pia.shared';
import { from, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import {
  ArticleModelName,
  GroupModelName,
  InstitutionModelName,
  InsuranceContractModelName,
  PatientModelName,
  ProductGroupModelName,
  RegionModelName,
  StandardCareProposalModelName,
  TemplateModelName,
  UsersModelName,
} from '../models/model-names';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionValidator {
  private _isWeb: boolean;
  constructor(private _authService: AuthService, private _platform: Platform) {
    this._isWeb = !this._platform.is('cordova');
  }

  public validate(path: string): Observable<boolean> {
    return from(this._authService.init).pipe(
      filter(() => this._authService.authentication.isAuth),
      switchMap(_ => {
        if (path.includes('patient')) {
          return of(this._authService.userHasPermission(PatientModelName, Permission.Read));
        }
        if (path.includes('emergency')) {
          return of(!this._isWeb && this._authService.userHasPermission('onlineSearch', Permission.Read));
        }
        if (path.includes('insurance-contract')) {
          return of(this._isWeb && this._authService.userHasPermission(InsuranceContractModelName, Permission.Read));
        }
        if (path.includes('article')) {
          return of(this._authService.userHasPermission(ArticleModelName, Permission.Read));
        }
        if (path.includes('product-group')) {
          return of(this._isWeb && this._authService.userHasPermission(ProductGroupModelName, Permission.Read));
        }
        if (path.includes('standard-care-proposal')) {
          return of(this._authService.userHasPermission(StandardCareProposalModelName, Permission.Read));
        }
        if (
          path.includes('pharmacy') ||
          path.includes('doctor') ||
          path.includes('hospital') ||
          path.includes('nursing-service') ||
          path.includes('nursing-home')
        ) {
          return of(this._authService.userHasPermission(InstitutionModelName, Permission.Read));
        }
        if (path.includes('formulare')) {
          return of(this._isWeb && this._authService.userHasPermission(TemplateModelName, Permission.Read));
        }
        if (path.includes('calendar')) {
          return of(this._authService.userHasPermission('calendar', Permission.Read));
        }
        if (path.includes('rights-management')) {
          return of(this.hasAnyUsermanagementPermission());
        }
        if (path.includes('maintenance')) {
          return of(this.userIsItLabs());
        }
        if (path.includes('agent-admin')) {
          return of(this._authService.authentication.account.isAgent);
        }

        return of(true);
      })
    );
  }

  private hasAnyUsermanagementPermission() {
    return (
      this._authService.userHasPermission(UsersModelName, Permission.Read) ||
      this._authService.userHasPermission(GroupModelName, Permission.Read) ||
      this._authService.userHasPermission(RegionModelName, Permission.Read)
    );
  }

  private userIsItLabs() {
    return (
      this._authService &&
      this._authService.authentication &&
      this._authService.authentication.account &&
      this._authService.authentication.account.organization &&
      this._authService.authentication.account.organization.tenantId === '1TLabs'
    );
  }
}
