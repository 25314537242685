import { IModel } from '@pia/pia.shared';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { IDatabaseService } from 'src/app/shared/services/contracts/database/database-service';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';
import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { IRepository } from '../contracts/repository/repository';
import { IPlatformSync } from '../contracts/sync/platform-sync';
import { IUnitOfWork } from '../contracts/unit-of-work/unit-of-work';
import { Deferred } from '../deferred/deferred';
import {
  AttachmentDB,
  AuditDB,
  DoctorDB,
  ErpOrderDB,
  HospitalDB,
  IntegratedCareDB,
  NursingHomeDB,
  NursingServiceDB,
  PatientAppUserChannelDB,
  PatientAppUserDB,
  PatientDB,
  PatientNotesDB,
  PharmacyDB,
  SingleOrderDB,
  SyncTimestampDB,
  UsersDB,
} from '../repository/databases';
import { Repository as DesktopRepository } from '../repository/desktop-repository';
import { LocalQueryStrategy } from '../repository/local-query-strategy';
import { RemoteQueryStrategy } from '../repository/remote-query-strategy';
import { Repository } from '../repository/repository';

const ONLINE_SEARCHABLE_ENTITIES = [
  PatientDB,
  PatientNotesDB,
  ErpOrderDB,
  AuditDB,
  AttachmentDB,
  DoctorDB,
  HospitalDB,
  NursingServiceDB,
  NursingHomeDB,
  IntegratedCareDB,
  SingleOrderDB,
  PharmacyDB,
];

export class UnitOfWork implements IUnitOfWork {
  private _ready: Deferred<void> = new Deferred();
  get ready(): Promise<void> {
    return this._ready.promise;
  }

  constructor(
    private _dbProvider: IDatabaseService,
    private _repositories: Record<string, IRepository<any>>,
    private _plugins: {},
    private _storageFactory: (storage: IGenericStorage) => IGenericStorage,
    private _authenticationService: AuthService,
    private _feathersAppProvider: IFeathersAppProvider,
    private _platformSync: IPlatformSync
  ) {
    this.init();
  }

  private init() {
    this._authenticationService.authenticatedEventPublisher
      .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
      .subscribe({
        complete: async () => {
          try {
            for (const metadata of await this._dbProvider.databaseNames) {
              this._repositories[metadata.databaseName] = await this.createRepository(metadata.databaseName);
              if (ONLINE_SEARCHABLE_ENTITIES.includes(metadata.databaseName) && this._platformSync.isCordova) {
                this._repositories[`${metadata.databaseName}::online`] = await this.createOnlineRepository(
                  metadata.databaseName
                );
              }
            }
          } catch (error) {
            window.logger.error('UNITOFWORK INIT()', error);
          }
          this._ready.resolve();
        },
      });
  }

  async create<T extends IModel>(database: string): Promise<IRepository<T>> {
    await this._ready.promise;

    if (this._repositories[database]) {
      return this._repositories[database];
    }

    const repository = await this.createRepository(database);
    this._repositories[database] = repository;

    return repository;
  }

  private async createRepository(databaseName: string): Promise<IRepository<any>> {
    let repository: IRepository<any>;
    if (this._platformSync.isCordova) {
      repository = new Repository(
        this._storageFactory(await this._dbProvider.getDatabase(databaseName)),
        databaseName,
        this._plugins
      );
    } else {
      repository = await this.createDesktopRepository(databaseName);
    }

    return repository;
  }

  private async createOnlineRepository(databaseName: string): Promise<IRepository<IModel>> {
    const storage = this._storageFactory(await this._dbProvider.getDatabase(databaseName));
    const remoteQueryStrategy = new RemoteQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account
    );

    return new DesktopRepository(remoteQueryStrategy);
  }

  private async createDesktopRepository(databaseName: string) {
    const storage = this._storageFactory(await this._dbProvider.getDatabase(databaseName));
    const localQueryStrategy = new LocalQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account
    );
    const remoteQueryStrategy = new RemoteQueryStrategy(
      databaseName,
      storage,
      this._feathersAppProvider,
      this._authenticationService.authentication.account
    );

    this.bindQueryFunctions(databaseName, localQueryStrategy, remoteQueryStrategy);

    return new DesktopRepository(this._platformSync.canBeSynced ? localQueryStrategy : remoteQueryStrategy);
  }

  private bindQueryFunctions(
    databaseName: string,
    localQueryStrategy: IDbQueryStrategy<IModel>,
    remoteQueryStrategy: IDbQueryStrategy<IModel>
  ) {
    if (this._platformSync.canBeSynced) {
      return;
    }

    if (databaseName === AttachmentDB) {
      remoteQueryStrategy.get = localQueryStrategy.get.bind(localQueryStrategy);
    }

    if (databaseName === SyncTimestampDB) {
      remoteQueryStrategy.getAll = localQueryStrategy.getAll.bind(localQueryStrategy);
    }

    if (databaseName === UsersDB || databaseName === PatientAppUserDB || databaseName === PatientAppUserChannelDB) {
      remoteQueryStrategy.getAll = localQueryStrategy.getAll.bind(localQueryStrategy);
      remoteQueryStrategy.get = localQueryStrategy.get.bind(localQueryStrategy);
      remoteQueryStrategy.getItems = localQueryStrategy.getItems.bind(localQueryStrategy);
      remoteQueryStrategy.search = localQueryStrategy.search.bind(localQueryStrategy);
    }
  }
}
