import { IProductGroup } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ProductGroupWorkItem } from 'src/app/business/product-group/product-group-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ProductGroupCommandHandler extends EntityCommandHandler<IProductGroup> {
  protected workItem: IWorkItem<IProductGroup>;

  constructor(productGroupWorkItem: ProductGroupWorkItem) {
    super();
    this.workItem = productGroupWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ProductGroupDto';
  }
}
