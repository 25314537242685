import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File } from '@ionic-native/file/ngx';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ExternalStorageService {
  constructor(
    private _androidPermissions: AndroidPermissions,
    private _file: File,
    private _loadingController: LoadingController,
    private _alertController: AlertController,
    private _translateService: TranslateService
  ) {}

  public async moveExistingDatabaseToExternalStorage(androidDatabaseLocation: string, databaseName: string) {
    if (!androidDatabaseLocation) {
      return;
    }

    try {
      await this._file.checkFile(androidDatabaseLocation, databaseName);
    } catch (error) {
      if (error.code === 1) {
        const oldDatabasePath = this._file.applicationStorageDirectory + 'databases/';

        return this._file
          .checkFile(oldDatabasePath, databaseName)
          .then(async () => {
            const loading = await this._loadingController.create({
              message: this._translateService.instant('sdCard.message'),
              backdropDismiss: false,
            });

            await loading.present();
            await this._file.moveFile(oldDatabasePath, databaseName, androidDatabaseLocation, databaseName);
            await loading.dismiss().catch(error => window.logger.error('Dismiss of loading window failed.', error));

            const alert = await this._alertController.create({
              backdropDismiss: false,
              header: this._translateService.instant('sdCard.header'),
              message: this._translateService.instant('sdCard.copyDone'),
              buttons: [
                {
                  role: 'cancel',
                  text: this._translateService.instant('DONE_BUTTON'),
                },
              ],
            });
            await alert.present();
            await alert.onDidDismiss();

            window.location.reload();
          })
          .catch(error =>
            window.logger.error('Database could not be moved to external storage or app is fresh installed.', error)
          );
      }
    }
  }
}
