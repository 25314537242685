<div
  class="alberta-details-item"
  tappable
  [ngClass]="{ 'alberta-details-item--not-clickable': !clickAble }"
  (click)="triggerevent()"
>
  <div class="icon-wrapper"><ng-content select="itl-detail-item-icon"></ng-content></div>
  <div class="content-text-wrapper">
    <div class="text-wrapper"><ng-content></ng-content></div>
  </div>
</div>
