import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatChannelWithDetails } from 'src/app/shared/services/chat/chat.service';
import { ChatChannel } from 'src/app/shared/services/chat/data/db-schema';

@Component({
  selector: 'itl-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
})
export class ChannelListComponent {
  @Input()
  channels: ChatChannelWithDetails[];
  @Input()
  selectedChannelSid: string | null;
  @Input()
  isChatOnline: boolean;

  @Output() selectChannel = new EventEmitter<ChatChannel>();

  public handleClick(index: number) {
    this.selectChannel.emit(this.channels[index]);
  }
}
