import { IAppointment, IPatientNote } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { differenceBy } from 'lodash';
import { map, take } from 'rxjs/operators';
import { PatientNotesDB } from 'src/app/common/repository/databases';
import { DashboardStateExtension } from 'src/app/common/state/dashboard-state-extension';
import { PatientNoteModelName } from 'src/app/shared/models/model-names';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable({ providedIn: 'root' })
export class PatientNoteStateExtension extends DashboardStateExtension<IPatientNote> {
  public get name(): string {
    return PatientNoteModelName;
  }

  constructor(private _queryService: QueryService) {
    super();
  }

  protected filter(appointments: IAppointment[], patientNote: IPatientNote): boolean {
    if (!appointments || !appointments.length || !patientNote) {
      return false;
    }
    const filteredAppointments = appointments.filter(appointment =>
      appointment.patientIds.includes(patientNote.patientId)
    );

    return filteredAppointments && !!filteredAppointments.length && !patientNote.archived;
  }

  protected onCreated(appointment: IAppointment): Promise<void> {
    return new Promise<void>(resolve => {
      this.stateRegistry
        .select<IPatientNote[], IPatientNote>(PatientNoteModelName, 'dashboard')
        .pipe(
          take(1),
          map(patientNotes =>
            patientNotes.filter(patientNote => appointment.patientIds.includes(patientNote.patientId))
          )
        )
        .subscribe(async patientNotesFromState => {
          const patientNotesFromDb = await this._queryService.search<IPatientNote>(
            { query: appointment.patientIds.map(patientId => `patientId:${patientId}`).join(' ') },
            PatientNotesDB,
            { isIn: true }
          );
          await this.afterCreate(differenceBy(patientNotesFromDb, patientNotesFromState, '_id'));
          resolve();
        });
    });
  }

  protected async afterCreateInternal(patientNote: IPatientNote): Promise<void> {
    await this.stateRegistry.createBySync(PatientNoteModelName, 'dashboard', patientNote);
  }
  protected async afterUpdateInternal(patientNote: IPatientNote): Promise<void> {
    await this.stateRegistry.createBySync(PatientNoteModelName, 'dashboard', patientNote);
    await this.stateRegistry.updateBySync(PatientNoteModelName, 'dashboard', [patientNote]);
  }
  protected async removeFromState(patientNote: IPatientNote): Promise<void> {
    await this.stateRegistry.removeBySync(PatientNoteModelName, 'dashboard', [patientNote]);
  }
}
