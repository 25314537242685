import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '@pia/pia.shared';
import { GroupComponentModel } from 'src/app/business/group/group.component.model';

@Pipe({
  name: 'userMainGroup',
})
export class UserMainGroupPipe implements PipeTransform {
  constructor(private groupModel: GroupComponentModel) {}

  async transform(user: IUser): Promise<string> {
    if (!user || !user.groups || !user.groups.length) {
      console.error('UserMainGroupPipe: IUser not provided!');
      return 'Keine';
    }
    const groupId = user.groups.first();
    const group = await this.groupModel.get(groupId).toPromise();
    if (!group) {
      console.error('UserMainGroupPipe: Could not load IGroup: ' + groupId);
      return 'Unbekannt';
    }
    return group.label;
  }
}
