import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService as OAuthService } from 'ionic-appauth';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private _authService: AuthService,
    private _oauthService: OAuthService,
    private _router: Router,
    private _platform: Platform
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this._platform.is('cordova')) {
      return this.checkForMobile(state);
    }

    return this.checkForWeb(state);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this._platform.is('cordova')) {
      return this.checkForMobile(state);
    }

    return this.checkForWeb(state);
  }

  private async checkForMobile(state: RouterStateSnapshot): Promise<boolean> {
    await this._authService.init;

    if (!this._oauthService.session.isAuthenticated || !this._authService.authentication.isAuth) {
      this._authService.redirectUrl = state.url;

      await this._router.navigate(['/login']);
    }

    return this._oauthService.session.isAuthenticated || this._authService.authentication.isAuth;
  }

  private async checkForWeb(state: RouterStateSnapshot): Promise<boolean> {
    await this._authService.init;

    if (this._authService.authentication.isAuth) {
      return true;
    }

    this._authService.redirectUrl = state.url;

    await this._router.navigate(['/login']);

    return false;
  }
}
