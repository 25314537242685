import { AggregateRoot } from '../aggregate-root';
import { IErpOrder, IErpOrderedArticleLine, IDeliveryAddress } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class ErpOrderDto extends AggregateRoot implements IErpOrder, ISearchable {
  prescriptionStatus: string;
  completed: boolean;
  patientId: string;
  regionId: string;
  therapyId: number;
  salesId: string;
  erpOrderedArticleLines: IErpOrderedArticleLine[];
  annotation: string;
  deliveryAddress: IDeliveryAddress;
  outboundDate: Date;
  _id: string;
  timestamp: Date;
  archived: boolean;
  trackingLink: string;

  constructor() {
    super();
    this.archived = false;
  }
  get displayText(): string {
    return this.salesId;
  }
}
