import { IModel } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { WorkItem } from 'src/app/business/work-item';

import { IWorkItem } from '../contracts/work-item/work-item';
import { IWorkItemProvider } from '../contracts/work-item/work-item-provider';
import { IWorkItem as WorkItem_T } from '../contracts/work-item/work-item_T';

@Injectable({ providedIn: 'root' })
export class WorkItemProvider implements IWorkItemProvider {
  constructor(@Inject(WorkItem) private workItems: IWorkItem[]) {}

  get<T extends IModel>(name: string): WorkItem_T<T> {
    const workItem = this.workItems.find(x => x.name === name);
    if (workItem === undefined) {
      throw Error(`No WorkItem of type ${name} found.`);
    }

    return workItem as WorkItem_T<T>;
  }
}
