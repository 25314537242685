import { IInstitutionAudit } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { InstitutionAuditChannel } from 'src/app/business/institution/contracts/institution-audit.channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { InstitutionAuditModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class InstitutionAuditSyncService extends SyncService<IInstitutionAudit> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IInstitutionAudit>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(InstitutionAuditModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }
  public canSync(channel: string): boolean {
    return (
      channel === InstitutionAuditChannel.CREATE ||
      channel === InstitutionAuditChannel.EDIT ||
      channel === InstitutionAuditChannel.DELETE
    );
  }
}
