import { IModel } from '@pia/pia.shared';
import { v4 } from 'uuid';

export abstract class AggregateRoot implements IModel {
  _id: string;
  archived: boolean;
  timestamp: Date;

  constructor() {
    this._id = v4();
  }
}
