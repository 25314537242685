export class CommandResult {
  public successful = true;
  public result: any;
  public error: Error;

  constructor(result: { successful: boolean; error?: Error; result?: any }) {
    this.successful = result.successful;
    this.result = result.result;
    this.error = result.error;
  }

  public get() {
    if (this.error) {
      throw this.error;
    }
    if (this.result) {
      return this.result;
    }
  }
}
