import { IIntegratedCare } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IntegratedCareChannel } from 'src/app/business/integrated-care/contracts/integrated-care-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { IntegratedCareModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class IntegratedCareSyncService extends SyncService<IIntegratedCare> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IIntegratedCare>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(IntegratedCareModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  // eslint-disable-next-line require-await
  public async afterPatch(entity: IIntegratedCare): Promise<void> {
    return this.onPatched(entity);
  }

  public canSync(channel: string): boolean {
    return (
      channel === IntegratedCareChannel.CREATE ||
      channel === IntegratedCareChannel.EDIT ||
      channel === IntegratedCareChannel.DELETE
    );
  }
}
