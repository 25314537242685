import { Inject, Injectable } from '@angular/core';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { GroupDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { GroupDto } from 'src/app/shared/models/user-management/group-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class GroupWorkItem extends WorkItem<GroupDto> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this.deletable = false;
  }
  get name(): string {
    return 'GroupWorkItem';
  }
  get database(): string {
    return GroupDB;
  }
}
