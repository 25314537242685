import { IProductGroup, ProductGroupType } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class ProductGroupDto extends AggregateRoot implements IProductGroup, ISearchable {
  attributeTemplateIds: string[];
  type: ProductGroupType;
  number: number;
  name: string;
  description: string;
  parentId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;

  get displayText(): string {
    return this.name;
  }
}
