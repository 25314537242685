import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class ConfigItem {
  constructor(
    public database: string,
    public dtoFactory: () => ISearchable,
    public listItem: any,
    public multiSearchListitem?: any
  ) {}
}
