import { Component, Inject } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { SyncStatus } from '@ionic-native/code-push/ngx';
import { Config, LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Offline } from '@sentry/integrations';
import * as Sentry from '@sentry/angular';
import * as markerjs2 from 'markerjs2';
import { take } from 'rxjs/operators';
import { CodePushService } from 'src/app/shared/services/code-push/code-push.service';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';
import { environment } from 'src/environments/environment';
import { IPipelineRegistry } from './shared/pipeline/contracts/start/pipeline-registry';
import { PipelineRegistry } from './shared/pipeline/start/pipeline-registry';
import { StatusBarService } from './shared/services/statusbar.service';
import { TrackerService } from './shared/services/tracker.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private config: Config,
    private splashScreen: SplashScreen,
    private codePushService: CodePushService,
    private environmentService: EnvironmentService,
    private readonly tracker: TrackerService,
    private _statusBarService: StatusBarService,
    private _loadingController: LoadingController,
    @Inject(PipelineRegistry) private _pipelineRegistry: IPipelineRegistry
  ) {
    this.initializeApp();
    markerjs2.Activator.addKey(environment.markerjs2Key);
  }

  async initializeApp() {
    this.initTranslate();
    await this.environmentService.init();
    await this.platform.ready();
    await this._pipelineRegistry.start();

    const branch = this.environmentService.branch;

    const release = `alberta-${this.platform.is('cordova') ? 'app' : 'web'}@${this.environmentService.version}`;

    const ignorableErrors = ['Connection to Indexed Database server lost', 'NotAuthenticated'];

    Sentry.init({
      dsn: environment.sentry.dsn,
      beforeSend(event, hint) {
        const error: any = hint.originalException;

        if (error && error.message && error.message) {
          if (ignorableErrors.some(ignErr => error.message.includes(ignErr))) {
            return null;
          }
        }
        return event;
      },
      integrations: [
        new Offline({
          maxStoredEvents: 100,
        }),
      ],
      environment: this.environmentService.branch,
      release,
      ignoreErrors: [
        'Twilsock: request timeout',
        'Timeout of 60000ms exceeded calling update on ping',
        'Timeout of 60000ms exceeded calling create on authentication',
        'Timeout of 60000ms exceeded',
        'src/vendor/mobiscroll/js/mobiscroll.custom-4.0.0',
        'NotAuthenticated: Not authenticated',
        'Connection to Indexed Database server lost',
        'NotAuthenticated',
        /Loading Chunk \d*/,
        // sentry
        'Error: disconnected Error: disconnected',
        'transition is invalid while previous transition is still in progress',
        'disconnected (status: 0, code: 0)',

        "Can't connect to twilsock",
      ],
    });

    if (!this.platform.is('cordova')) {
      return;
    }

    this._statusBarService.setColorFromHex('#ffffff');
    this.splashScreen.hide();
    this.tracker.setVersion(branch, release);
  }

  initTranslate() {
    this.translate.setDefaultLang('de');
    this.translate.use('de');

    this.translate
      .get(['BACK_BUTTON_TEXT'])
      .pipe(take(1))
      .subscribe(values => {
        if (this.platform.is('ios')) {
          this.config.set('backButtonText', values.BACK_BUTTON_TEXT);
        }
      });
  }

  async codePushSync() {
    const loading = await this._loadingController.create({
      message: '',
    });

    (await this.codePushService.sync())?.subscribe(async syncStatus => {
      switch (syncStatus) {
        case SyncStatus.CHECKING_FOR_UPDATE:
          await loading.present();
          loading.message = this.translate.instant('codePushStatus.checking');
          break;
        case SyncStatus.DOWNLOADING_PACKAGE:
          loading.message = this.translate.instant('codePushStatus.downloading');
          break;
        case SyncStatus.INSTALLING_UPDATE:
          loading.message = this.translate.instant('codePushStatus.installing');
          break;
        case SyncStatus.UPDATE_INSTALLED:
          loading.message = this.translate.instant('codePushStatus.installed');
          await this.codePushService.restartApplication();
          break;
        case SyncStatus.UP_TO_DATE:
          const upToDateTranslation = this.translate.instant('codePushStatus.updated');
          loading.message = upToDateTranslation;
          window.logger.info(upToDateTranslation);
          await loading.dismiss().catch(error => window.logger.error('Dismiss of loading window failed.', error));
          break;
        case SyncStatus.ERROR:
        case SyncStatus.UPDATE_IGNORED:
          await loading.dismiss().catch(error => window.logger.error('Dismiss of loading window failed.', error));
          break;
      }
    });
  }
}
