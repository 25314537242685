import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { LoginFlowStateDetector } from 'src/app/common/authentication/login-flow-state-detector';
import { CancellationTokenHandler } from 'src/app/common/cancellation/cancellationtoken-handler';
import { LoginFlowState } from 'src/app/common/contracts/authentication/login-flow-state';
import { ICancellationTokenHandler } from 'src/app/common/contracts/cancellation/cancellationtoken-handler';
import { IConnectionStatus } from 'src/app/common/contracts/connection/connection-status';
import { Deferred } from 'src/app/common/deferred/deferred';

import makeDebug from '../../../../makeDebug';
import { ConnectionStateService } from '../../services/connection-state.service';
import { IConnectionStateService } from '../../services/contracts/sync/connection-state-service';
import { IScheduler } from '../contracts/scheduler/scheduler';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { ISyncRegistry } from '../contracts/sync/sync-registry';

const debug = makeDebug('sync:sync-scheduler');

@Injectable({ providedIn: 'root' })
export class SyncScheduler implements IScheduler {
  private _isPaused = false;
  private _wasOfflineAtPause = false;
  private _isInLoginFlow = false;
  private _lostConnectionInPause = false;
  private _websocketSubscription: Subscription;
  private _ready = new Deferred<{ syncRegistry: ISyncRegistry; context: IPipelineContext }>();
  private _lastConnectionState: IConnectionStatus;
  public get ready(): Promise<any> {
    return this._ready.promise;
  }

  constructor(
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(CancellationTokenHandler) private _cancellationTokenHandler: ICancellationTokenHandler,
    private _platform: Platform,
    private _loginFlowStateDetector: LoginFlowStateDetector
  ) {
    this._loginFlowStateDetector.loginFlowState.subscribe(
      loginFlowState => (this._isInLoginFlow = loginFlowState === LoginFlowState.Started)
    );

    this._connectionStateService.connected.pipe(filter(isOnline => !isOnline)).subscribe(async () => {
      debug('cancelling all tokens.');
      this._lostConnectionInPause = this._isPaused;

      await this._cancellationTokenHandler.cancellAll();
    });

    this._platform.pause.subscribe(_ => {
      if (this._isInLoginFlow) {
        return;
      }
      this.unsubscribeFromWebsocket();
    });

    this._platform.resume.subscribe(async _ => {
      if (this._isInLoginFlow) {
        return;
      }
      await this.subscribeToWebsocket();
    });

    debug('subscribing to websocket.');
    this.subscribeToWebsocket();
  }

  public schedule(syncRegistry: ISyncRegistry, context: IPipelineContext): void {
    this._ready.resolve({ syncRegistry, context });
  }

  private async subscribeToWebsocket(): Promise<void> {
    this._isPaused = false;

    this._websocketSubscription = this._connectionStateService.connectionState
      .pipe(
        distinctUntilChanged(
          (previous, current) => (previous || this._lastConnectionState).isOnline === current.isOnline
        ),
        tap(connectionState => (this._lastConnectionState = connectionState))
      )
      .subscribe(async connectionStatus => {
        const { syncRegistry, context } = await this._ready.promise;
        const contextReset: IPipelineContext = { ...context, ...{ params: { isOnline: connectionStatus.isOnline } } };
        debug('calling start on syncRegistry.', connectionStatus.isOnline);
        await syncRegistry.start(contextReset);
      });

    if (this._wasOfflineAtPause || this._lostConnectionInPause) {
      const { syncRegistry, context } = await this._ready.promise;
      context.params.isOnline = this._connectionStateService.isConnected;

      debug(
        'calling start on syncRegistry when connection lost at pause',
        this._wasOfflineAtPause,
        this._lostConnectionInPause
      );
      syncRegistry.start(context);
    }
  }

  private unsubscribeFromWebsocket() {
    this._isPaused = true;
    this._wasOfflineAtPause = !this._connectionStateService.isConnected;

    if (this._websocketSubscription && !this._websocketSubscription.closed) {
      debug('unsubscribing from websocket subscription');
      this._websocketSubscription.unsubscribe();
    }
  }
}
