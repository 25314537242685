import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itl-chat-layout',
  templateUrl: './chat-layout.component.html',
  styleUrls: ['./chat-layout.component.scss'],
})
export class ChatLayoutComponent implements OnInit, OnDestroy {
  @Input() public channelList: any;
  @Input() public channel: any;
  @Input() public showChannelView = false;

  public isMobileView = false;
  private readonly _destroyed = new ReplaySubject<void>();

  constructor(private platform: Platform) {
    this.platform.resize.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this.isMobileView = this.platform.width() <= 800;
    });
  }

  ngOnInit(): void {
    this.isMobileView = this.platform.width() <= 800;
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
