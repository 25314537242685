import { IConfig } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ConfigWorkItem } from 'src/app/business/config/config-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ConfigCommandHandler extends EntityCommandHandler<IConfig> {
  protected workItem: IWorkItem<IConfig>;

  constructor(configWorkItem: ConfigWorkItem) {
    super();
    this.workItem = configWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ConfigDto';
  }
}
