import { Inject, Injectable } from '@angular/core';
import { FeathersError, Forbidden, Timeout } from '@feathersjs/errors';
import { Service } from '@feathersjs/feathers';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { cloneDeep, omit } from 'lodash';
import { interval } from 'rxjs';
import { map, mergeMap, switchMap, take, takeWhile } from 'rxjs/operators';
import { CommandQueue } from 'src/app/commands/command-queue';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

import { AuthService } from './auth.service';
import { ConnectionStateService } from './connection-state.service';
import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { IFeathersAppProvider } from './contracts/sync/feathers-app-provider';
import { FeathersService } from './feathers.service';

@Injectable({ providedIn: 'root' })
export class PingService {
  constructor(
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider,
    private _authService: AuthService,
    private _device: Device,
    private _platform: Platform,
    private _environmentService: EnvironmentService,
    private _commandQueue: CommandQueue
  ) {}

  public init(): void {
    interval(60000)
      .pipe(
        switchMap(() => this._connectionStateService.connected),
        mergeMap(isOnline =>
          this._authService.authenticatedEventPublisher.pipe(map(authEventData => ({ ...authEventData, isOnline })))
        ),
        mergeMap(data =>
          this._commandQueue.count.pipe(
            take(1),
            map(count => ({ ...data, count }))
          )
        ),
        takeWhile(data => data.authOnline && data.isAuthenticated && data.isOnline)
      )
      .subscribe(
        data => {
          if (!this._authService.authentication.account) {
            return;
          }
          const user = cloneDeep(this._authService.authentication.account);
          user.organization = user.organization._id;
          const pingService = this._feathersAppProvider.app.service('ping') as Service<{
            user: any;
            device: any;
            version: any;
            buildNumber: any;
            isBrowser: boolean;
            toSynchronize: number;
          }>;
          pingService
            .update(user._id, {
              user: omit(user, ['authorization', 'chatToken', 'icsToken']),
              device: this._platform.is('hybrid')
                ? {
                    cordova: this._device.cordova,
                    platform: this._device.platform,
                    version: this._device.version,
                    manufacturer: this._device.manufacturer,
                    model: this._device.model,
                    serial: this._device.serial,
                    uuid: this._device.uuid,
                  }
                : undefined,
              version: this._environmentService.version,
              buildNumber: '',
              isBrowser: !this._platform.is('hybrid'),
              toSynchronize: data.count,
            })
            .catch(async (err: FeathersError) => {
              switch (err.name) {
                case Timeout.name:
                  console.error('ping timeout', err);
                  break;
                case Forbidden.name:
                  await this._authService.logout();
                  window.location.reload();
                  break;
                default:
                  throw err;
              }
            });
        },
        console.error,
        () => this.init()
      );
  }
}
