import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Logger } from 'src/app/common/logging/logger';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';

import { AttachmentDatabaseService } from './attachment-database.service';
import { Database } from './Database';
import { arrayBufferToBlob } from './attachment-helper';
import { AttachmentTransferService } from './attachment-transfer.service';

@Injectable({ providedIn: 'root' })
export class AttachmentQueuesProcessorService {
  private _isOnline = false;
  constructor(
    private _attachmentDbProvider: AttachmentDatabaseService,
    private _attachmentTransferService: AttachmentTransferService,
    private _logger: Logger
  ) {}

  public set isOnline(isOnline: boolean) {
    this._isOnline = isOnline;
  }

  public async processQueues(
    deleteFn: (id: string) => Promise<any>,
    uploadFn: (key: string) => Promise<any>,
    updateFn: (attachment: AttachmentDto) => Promise<void>
  ) {
    this._logger.info('process local queues');
    await this.processAttachmentToRemoveQueue(deleteFn);
    await this.processAttachmentToSaveQueue();
    await this.processAttachmentToUpdateQueue(updateFn);
    await this.processLocalAttachmentsQueue(uploadFn);
    this._logger.info('finished processing local queues');
  }

  private async processAttachmentToRemoveQueue(deleteFn: (id: string) => Promise<void>) {
    await this._attachmentDbProvider.ready(Database.AttachmentToRemove);
    const attachmentsToRemove = await this._attachmentDbProvider.keys(Database.AttachmentToRemove);
    while (this._isOnline && attachmentsToRemove.length) {
      const id = attachmentsToRemove.pop();
      try {
        await this._attachmentDbProvider.remove(Database.AttachmentToRemove, id);
        if (id != null) {
          await deleteFn(id);
        }
      } catch (err) {
        await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, null);
        throw err;
      }
    }
  }

  private async processAttachmentToSaveQueue() {
    await this._attachmentDbProvider.ready(Database.AttachmentToSave);
    const attachmentsToSave = await this._attachmentDbProvider.keys(Database.AttachmentToSave);
    while (this._isOnline && attachmentsToSave.length) {
      const localId = attachmentsToSave.pop();
      const attachment = await this._attachmentDbProvider.get(Database.AttachmentToSave, localId);
      await this._attachmentDbProvider.remove(Database.AttachmentToSave, localId);
      if (attachment != null && attachment.data) {
        const attachmentWithBlob = cloneDeep(attachment);
        attachmentWithBlob.data.blob = arrayBufferToBlob(attachmentWithBlob.data.blob, attachmentWithBlob.data.mime);
        const uploadResult = await this._attachmentTransferService.upload(
          attachmentWithBlob.data,
          attachmentWithBlob.localId,
          this._isOnline,
          true
        );
        if (uploadResult.error && uploadResult.needRetry) {
          await this._attachmentDbProvider.set(Database.AttachmentToSave, localId, attachment);
        }
      }
    }
  }

  private async processAttachmentToUpdateQueue(updateFn: (attachmentDto: AttachmentDto) => Promise<void>) {
    await this._attachmentDbProvider.ready(Database.AttachmentToUpdate);
    const attachmentsToUpdate = await this._attachmentDbProvider.keys(Database.AttachmentToUpdate);
    while (this._isOnline && attachmentsToUpdate.length) {
      const attachmentId = attachmentsToUpdate.pop();
      const attachment = await this._attachmentDbProvider.get(Database.AttachmentToUpdate, attachmentId);
      if (attachment && attachment._id) {
        try {
          await updateFn(attachment);
        } catch (err) {
          await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
          throw err;
        }
      }
    }
  }

  private async processLocalAttachmentsQueue(uploadFn: (key: string) => Promise<void>) {
    const localAttachmentKeys = await this._attachmentDbProvider.getLocalAttachments();
    if (this._isOnline && localAttachmentKeys.length > 0) {
      for (const localAttachmentKey of localAttachmentKeys) {
        await uploadFn(localAttachmentKey);
      }
    }
  }
}
