import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { IDatabaseService } from 'src/app/shared/services/contracts/database/database-service';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { DatabaseService } from 'src/app/shared/services/database.service';

import { IStateRegistryPersister } from '../contracts/state/state-registry-persister';
import { Deferred } from '../deferred/deferred';

@Injectable({ providedIn: 'root' })
export class StateRegistryPersister extends Subject<{ key: string; value: any }> implements IStateRegistryPersister {
  private _database: Partial<IGenericStorage>;
  private _ready = new Deferred<void>();

  private _noopStorage = {
    set: async (_key, _value) => {},
    forEach: async (_iteratorCallback: (value: any, key: string, iterationNumber: number) => any) => {},
  };

  constructor(@Inject(DatabaseService) private _dbService: IDatabaseService, private _platform: Platform) {
    super();

    (async () => {
      await this._platform.ready();

      this._database = this._platform.is('hybrid')
        ? this._noopStorage
        : await this._dbService.getDatabase('statePersister.db');
      this._ready.resolve();
    })();
  }

  public async update(state: string, path: string, models: any): Promise<void> {
    await this._ready.promise;
    await this._database.set(`${state}:${path}`, { models });
  }

  public async rehydrate(): Promise<void> {
    const items = {};
    await this._ready.promise;

    await this._database.forEach((models, key) => {
      items[key] = models;
    });

    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        this.next({ key, value: items[key].models });
      }
    }
    this.complete();
  }
}
