import { IUser } from '@pia/pia.shared';

import { IAuthenticationAccount } from '../contracts/authentication/authentication-account';
import { Deferred } from '../deferred/deferred';

export class AuthenticationAccount implements IAuthenticationAccount {
  private _account: IUser;
  private _ready = new Deferred<void>();

  public get account(): IUser {
    return this._account;
  }

  public set account(user: IUser) {
    this._account = user;
    this._ready.resolve();
  }

  public get ready(): Promise<void> {
    return this._ready.promise;
  }
}

const AUTHENTICATION: IAuthenticationAccount = new AuthenticationAccount();

export default AUTHENTICATION;
