import { NgModule } from '@angular/core';

import { AuthorizationWithSelectionDirective } from './authorization-selection.directive';
import { AuthorizationDirective } from './authorization.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { FocusNextInputDirective } from './focus-next-input.directive';
import { HideWhenDirective } from './hideWhen.directive';
import { InputTextTrimmerDirective } from './input-trimmer.directive';
import { PhonePrefixDirective } from './phone-prefix.directive';
import { ShowWhenDirective } from './showWhen.directive';

@NgModule({
  declarations: [
    AuthorizationDirective,
    AuthorizationWithSelectionDirective,
    FocusNextInputDirective,
    HideWhenDirective,
    ShowWhenDirective,
    PhonePrefixDirective,
    InputTextTrimmerDirective,
    ClickOutsideDirective,
  ],
  imports: [],
  exports: [
    AuthorizationDirective,
    AuthorizationWithSelectionDirective,
    FocusNextInputDirective,
    HideWhenDirective,
    ShowWhenDirective,
    InputTextTrimmerDirective,
    PhonePrefixDirective,
    ClickOutsideDirective,
  ],
})
export class DirectivesModule {}
