import { IAudit } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { AuditDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { AuditDto } from 'src/app/shared/models/swodoc/audit-dto.model';

import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../../shared/services/contracts/sync/database-synchronizer_T';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class AuditWorkItem extends WorkItem<AuditDto> implements IDatabaseSynchronizer_T<IAudit> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  unitOfWork: IUnitOfWork;
  get name(): string {
    return 'AuditWorkItem';
  }
  get database(): string {
    return AuditDB;
  }

  get chunkSize(): number {
    return 25;
  }
}
