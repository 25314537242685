<div
  class="text-input-general-wrapper"
  [ngClass]="{
    'text-input-general-wrapper--invalid': control && control.status === 'INVALID',
    'text-input-general-wrapper--disabled': disabled
  }"
  tappable
  (click)="setFocus()"
>
  <div class="text-input-content-wrapper">
    <!-- <div
      class="icon-wrapper"
      [ngClass]="{
        'icon-wrapper--stdIcon': !icon,
        'icon-wrapper--disabled': disabled
      }"
      *ngIf="!noIcon"
    >
      <ion-icon [name]="icon ? icon : 'alberta-dot'"></ion-icon>
    </div> -->
    <ion-icon
      class="icon-wrapper"
      [ngClass]="{ 'icon-wrapper--stdIcon': !icon }"
      *ngIf="!noIcon"
      [name]="icon ? icon : 'alberta-dot'"
    ></ion-icon>
    <div class="text-input">
      <ng-content></ng-content>
      <div class="title-wrapper" [ngClass]="{ 'title-wrapper--disabled': disabled }">
        <div id="inputTitle">{{ title }} <span *ngIf="required" class="required-star">*</span></div>
        <div id="counter" *ngIf="maxlength">{{ value ? maxlength - value.length : maxlength }} / {{ maxlength }}</div>
      </div>
    </div>
  </div>
  <div class="description" [ngClass]="{ 'description--disabled': disabled }" *ngIf="description">
    {{ description }}
  </div>
  <itl-error-display *ngIf="control" [control]="control"></itl-error-display>
</div>
