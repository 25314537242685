import { IModel } from '@pia/pia.shared';
import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { BaseAuthorizationDirective } from './base-authorization.directive';

@Directive({
  selector: '[itl-authorizeWithSelection]',
})
export class AuthorizationWithSelectionDirective extends BaseAuthorizationDirective implements OnChanges {
  @Input('itl-authorizeWithSelection') selectedItem: IModel;

  constructor(elementRef: ElementRef, renderer: Renderer2, authService: AuthService) {
    super(authService, elementRef, renderer);
  }

  ngOnChanges() {
    if (this.selectedItem == null) {
      return;
    }

    super.setVisibility();
  }
}
