import { IErpTask, IErpTaskLine } from '@pia/pia.shared';
import { ErpTaskStatus } from '@pia/pia.shared/dist/general/enum/erp-task-status';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';

export class ErpTaskViewModel extends BaseViewModel implements IErpTask {
  entityId: string;
  asDocument: boolean;
  templateTag: string;
  templateOptional: boolean;
  attachmentType: number;
  changedInAlberta: boolean;
  resmedTenant: string;
  customerNumber: string;
  patientId: string;
  therapyId: number;
  therapyTypeId: number;
  startDate: string;
  endDate: string;
  description: string;
  deliveryLocked: boolean;
  billingLocked: boolean;
  dueDateAnswer: string;
  dueDate: string;
  assigneeId: string;
  assigneeEntityType: string;
  assigneeCustomerNumber: string;
  assigneeName: string;
  doctorId: string;
  doctorCustomerNumber: string;
  doctorName: string;
  expectedQuality: string;
  status: ErpTaskStatus;
  reminderLevel: number;
  originDocumentNumber: string;
  salesPersonCode: string;
  erpTaskLines: IErpTaskLine[];
  regionId: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}
