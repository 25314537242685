export const MaintenanceSynchroniser = 'MaintenanceSynchroniser';
export const Authenticator = 'Authenticator';
export const CommandQueueRunner = 'CommandQueueRunner';
export const SyncIndicatorPreCloser = 'SyncIndicatorPreCloser';
export const SyncIndicatorOpener = 'SyncIndicatorOpener';
export const SyncIndicatorCloser = 'SyncIndicatorCloser';
export const SyncStarter = 'SyncStarter';
export const WebSyncStarter = 'WebSyncStarter';
export const TokenHandler = 'TokenHandler';
export const RootHistorySetter = 'RootHistorySetter';
export const MaintenanceWatchStarter = 'MaintenanceWatchStarter';
export const ShutdownInfoListener = 'ShutdownInfoListener';
export const DatabaseCreator = 'DatabaseCreator';
export const CommandQueueCleaner = 'CommandQueueCleaner';
