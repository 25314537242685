import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { WizardNavPageComponent } from './wizard-nav-page/wizard-nav-page.component';
import { WizardPageFooterComponent } from './wizard-page-footer/wizard-page-footer.component';
import { WizardPageComponent } from './wizard-page.component';

@NgModule({
  declarations: [WizardPageComponent, WizardPageFooterComponent, WizardNavPageComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), FormsModule],
  exports: [WizardPageComponent, WizardPageFooterComponent],
})
export class WizardPageModule {}
