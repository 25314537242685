import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { forEach, groupBy, last, sortBy } from 'lodash';
import moment from 'moment';
import { of } from 'rxjs';
import { delay, takeWhile } from 'rxjs/operators';
import {
  MessageClickedEvent,
  userClickedEvent,
} from 'src/app/shared/components/chat/chat-message/chat-message.component';
import { ChatMember, ChatMessage } from 'src/app/shared/services/chat/data/db-schema';
import { ChatChannelDetail } from 'src/app/shared/services/chat/model/chat.model';

type EnrichedMessage = ChatMessage & { changedMemberSidSinceLastMessage: boolean };
export interface MessagesByDay {
  dayTimestamp: Date;
  messages: EnrichedMessage[];
}
@Component({
  selector: 'itl-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements AfterViewInit, OnChanges {
  @ViewChild('chatMessageList', { static: false }) public chatMessageListComponent: any;
  @Input() channel: ChatChannelDetail;
  @Input() showNames = false;
  @Input() isLoading: boolean;
  @Input() isLoadingMoreMessages = false;
  @Output() scrolledToBottom = new EventEmitter();
  @Output() readMessage = new EventEmitter();
  @Output() loadMoreMessages = new EventEmitter();
  @Output() messageClicked = new EventEmitter<MessageClickedEvent>();
  @Output() userClicked = new EventEmitter<userClickedEvent>();

  public messages: ChatMessage[];
  public members: ChatMember[];
  public messagesByDay: Array<MessagesByDay> = [];
  public showLoadingIndicator = false;
  public isScrolledToBottom = true;

  private infiniteLoadingCallback: Function = null;
  private sortedMessages: ChatMessage[] = [];

  ngAfterViewInit(): void {
    this.chatMessageListComponent.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['channel']) {
      const channel = changes['channel'].currentValue;
      const previousChannel = changes['channel'].previousValue;
      if (channel && previousChannel && channel.sid !== previousChannel.sid) {
        this.resetChannel();
      }
      this.messages = channel.messages;
      this.members = channel.members;
      this._handleMessagesUpdate(this.messages);
    }
    if (changes['isLoading'] && changes['isLoading'].currentValue === false) {
      this.showLoadingIndicator = false;
    }
    const changedToLoading =
      changes['isLoading'] && !changes['isLoading'].previousValue && changes['isLoading'].currentValue === true;
    if (changedToLoading) {
      of(true)
        .pipe(
          delay(1000),
          takeWhile(() => this.isLoading)
        )
        .subscribe(() => (this.showLoadingIndicator = true));
    }
    if (
      changes.isLoadingMoreMessages &&
      changes.isLoadingMoreMessages.previousValue === true &&
      changes.isLoadingMoreMessages.currentValue === false
    ) {
      if (this.infiniteLoadingCallback) {
        this.infiniteLoadingCallback();
      }
    }
  }

  private resetChannel() {
    this.messages = [];
    this.members = [];
    this.messagesByDay = [];
    this.sortedMessages = [];
    this.showLoadingIndicator = false;
    this.scrollToBottom();
    this.isScrolledToBottom = true;
    this.infiniteLoadingCallback = null;
  }

  private _handleMessagesUpdate(messages: ChatMessage[]) {
    this.sortedMessages = sortBy(messages, 'index');
    const groupedMessages = groupBy(this.sortedMessages, msg => moment(msg.timestamp).format('YYYY-MM-DD'));
    let newMessagesByDay: MessagesByDay[] = [];
    forEach(groupedMessages, (_messages, day) => {
      const enrichedMessages: EnrichedMessage[] = _messages.map((message, i) => ({
        ...message,
        changedMemberSidSinceLastMessage: i === 0 || _messages[i - 1].memberSid !== message.memberSid,
      }));
      newMessagesByDay.push({
        dayTimestamp: moment(day, 'YYYY-MM-DD').toDate(),
        messages: enrichedMessages,
      });
    });
    newMessagesByDay = sortBy(newMessagesByDay, 'dayTimestamp');
    this.messagesByDay = newMessagesByDay;
    if (!this.chatMessageListComponent) {
      return;
    }
    if (this.isScrolledToBottom) {
      // lock to bottom if user has not scrolled up
      this.scrollToBottom();
      // mark new messages as read after displaying messages
      // edge case: messages available after channel has shown on no scrolling necessary
      // egde case: only last message available and loading old message
      this.markLastMessageAsRead();
    } else {
      this.chatMessageListComponent.setScrollPosition();
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.chatMessageListComponent.scrollToBottom();
      this.isScrolledToBottom = true;
    }, 200);
  }

  public getMember(memberSid: string) {
    const foundMember = this.members.find(member => member.sid === memberSid);
    return foundMember;
  }

  public markLastMessageAsRead() {
    const lastMessage = last(this.sortedMessages);
    if (lastMessage) {
      this.readMessage.emit(lastMessage.index);
    }
  }

  public handleLoadMoreMessages() {
    this.isScrolledToBottom = false;
    this.loadMoreMessages.emit(this.messages[0] ? this.messages[0].index - 1 : undefined);
  }
}
