import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MbscModule } from '@mobiscroll/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BrMaskerModule } from 'br-mask';
import { AutosizeModule } from 'ngx-autosize';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ComponentsPipesModule } from 'src/app/common/pipes/components/components-pipes.module';
import { GeneralPipesModule } from 'src/app/common/pipes/general-pipes.module';
import { CurrencyComponent } from 'src/app/shared/components/inputs/currency/currency.component';

import { DirectivesModule } from '../../directives/directives.module';
import { ErrorDisplayComponent } from './_templates/error-display/error-display.component';
import { InputTemplatesModule } from './_templates/input-templates.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AutocompleteModule } from './autocomplete/autocomplete.module';
import { ButtonComponent } from './button/button.component';
import { CheckboxSelectComponent } from './checkbox-select/checkbox-select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { ImageComponent } from './image/image.component';
import { MultiAutocompleteComponent } from './multi-autocomplete/multi-autocomplete.component';
import { MultiAutocompleteModule } from './multi-autocomplete/multi-autocomplete.module';
import { NumberUpDownComponent } from './number-up-down/number-up-down.component';
import { QuestionComponent } from './question/question.component';
import { SelectionComponent } from './selection/selection.component';
import { TextComponent } from './text/text.component';
import { TextComponentModule } from './text/text.component.module';
import { TextareaComponent } from './textarea/textarea.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  imports: [
    DirectivesModule,
    CommonModule,
    IonicModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'always' }),
    TranslateModule.forChild(),
    GeneralPipesModule,
    ComponentsPipesModule,
    AutocompleteModule,
    MultiAutocompleteModule,
    InputTemplatesModule,
    FormsModule,
    MbscModule,
    BrMaskerModule,
    AutosizeModule,
    NgxMaterialTimepickerModule,
    TextComponentModule,
  ],
  declarations: [
    SelectionComponent,
    CheckboxSelectComponent,
    CurrencyComponent,
    TextareaComponent,
    DateTimeComponent,
    NumberUpDownComponent,
    ButtonComponent,
    QuestionComponent,
    CheckboxComponent,
    ToggleComponent,
    ImageComponent,
  ],
  exports: [
    SelectionComponent,
    ErrorDisplayComponent,
    CheckboxSelectComponent,
    TextComponent,
    CurrencyComponent,
    AutocompleteComponent,
    TextareaComponent,
    MultiAutocompleteComponent,
    DateTimeComponent,
    NumberUpDownComponent,
    ButtonComponent,
    QuestionComponent,
    CheckboxComponent,
    ToggleComponent,
    ImageComponent,
  ],
})
export class AlbertaInputsModule {}
