<div
  class="alberta-button-input-wrapper"
  [ngClass]="{
    'alberta-button-input-wrapper--alert': ngControl && ngControl.invalid,
    'alberta-button-input-wrapper--disabled': disabled
  }"
  tappable
>
  <div class="button-content-wrapper" (click)="type === 2 ? openTimePicker() : openMobiScroll()">
    <div
      class="icon-wrapper"
      *ngIf="!noIcon"
      [ngClass]="{
        'icon-wrapper--stdIcon': !icon
      }"
    >
      <ion-icon [name]="icon ? icon : 'alberta-dot'"></ion-icon>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <!-- <span *ngIf="!date" class="placeholder"> {{ placeholder }}</span> -->
        <!-- <span *ngIf="value" class="value"> {{ value }}</span> -->
        <div class="inputs-wrapper bold">
          <div
            class="date-wrapper"
            (click)="openMobiScroll()"
            tappable
            [style.display]="type !== 2 ? 'unset' : 'none'"
            [style.flex]="type === 1 ? 1 : 'unset'"
          >
            {{ date | date: dateFormatter }}
          </div>
          <div class="hyphen" *ngIf="type === 0">-</div>
          <div
            class="time-wrapper"
            [ngClass]="{ 'time-wrapper--only-time': type === 2 }"
            [style.display]="type !== 1 ? 'unset' : 'none'"
          >
            <input
              #timeInput
              [ngxTimepicker]="picker"
              [format]="24"
              [value]="date | date: 'HH:mm'"
              class="time-input"
              [min]="minTime"
              [max]="maxTime"
              readonly
            />
            <ngx-material-timepicker
              #picker
              (timeSet)="setTime($event)"
              [theme]="albertaTimePickerOptions"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
            ></ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button class="timepickerBtn">{{ 'CANCEL_BUTTON' | translate }}</button>
            </ng-template>
            <ng-template #confirmBtn>
              <button class="timepickerBtn">{{ 'DONE_BUTTON' | translate }}</button>
            </ng-template>
          </div>
        </div>
        <div
          class="deleteButton"
          (click)="removeInput($event)"
          *ngIf="date != undefined && !disabled && !noDeleteButton"
        >
          <ion-icon name="alberta-delete"></ion-icon>
        </div>
      </div>
      <div class="title">{{ title }} <span *ngIf="required" class="required-star">*</span></div>
    </div>
  </div>
  <div class="description" *ngIf="description">
    {{ description }}
  </div>
  <itl-error-display *ngIf="ngControl" [control]="ngControl"></itl-error-display>
</div>

<mbsc-calendar
  #mobiscrollDateInput
  style="display: none;"
  [ngModel]="date"
  [options]="calenderOptions"
  [defaultValue]="preSelection"
  (onCancel)="cancel()"
></mbsc-calendar>
