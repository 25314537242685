import { IGroup } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { BaseComponentModel } from 'src/app/common/component-model/base-component-model';
import { ComponentModelDependencies } from 'src/app/common/component-model/component-model-dependencies';
import { ComponentModelInfo } from 'src/app/common/component-model/component-model-info';
import { GroupModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class GroupComponentModel extends BaseComponentModel<IGroup> {
  /**
   * ComponentModel for all group relevant actions and dependencies
   */
  constructor(
    @Inject(ComponentModelDependencies) componentModelDependencies: ComponentModelDependencies,
    @Inject(ComponentModelInfo) componentModelInfo: ComponentModelInfo
  ) {
    super(GroupModelName, componentModelDependencies, componentModelInfo);
  }
}
