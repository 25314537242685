import { IPatientNote, NoteType } from '@pia/pia.shared';
import { ignore } from 'src/app/common/tracking/tracker';
import { BaseViewModel } from 'src/app/common/viewmodel/base-view-model';

export class PatientNoteViewModel extends BaseViewModel implements IPatientNote {
  updatedAt: Date;
  updatedBy: string;
  edited: boolean;
  regionId: string;
  timestamp: Date;
  archived: boolean;
  noteText: string;
  patientId: string;
  _id: string;
  createdAt: Date;
  createdBy: string;
  status: NoteType;
  @ignore() shortedNote: string;
}
