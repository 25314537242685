import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { BehaviorSubject, Subscription, from, of, timer } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { FeathersHttpService } from '../feathersHttpService';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class CheckAppVersionService implements OnDestroy {
  private pollingSubscription: Subscription;
  public isAppVersionOutdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private environmentService: EnvironmentService,
    private feathersService: FeathersHttpService,
    private storage: Storage
  ) {
    const twelveHours = 12 * 60 * 60 * 1000;
    // CheckAppVersion will be called once at start and then every twelve ours
    this.pollingSubscription = timer(5000, twelveHours)
      .pipe(
        concatMap(_ =>
          from(this.checkAppVersion()).pipe(
            catchError(e => {
              // If an error occures during the check or the call to the backend, the last known value is used
              console.error('Fehler beim Abrufen der aktuellen App-Version! ' + e);
              return of(this.isAppVersionOutdated$.getValue());
            })
          )
        )
      )
      .subscribe(result => {
        // After every check / call to the backend the BehaviorSubject gets the new value
        this.isAppVersionOutdated$.next(result);
      });
  }

  ngOnDestroy(): void {
    this.pollingSubscription.unsubscribe();
  }

  /**
   * Retrieves the newest app version from the backend and checks if the used app version is the newest app version
   *
   * @returns true if the newestAppVersion is the same as used app version
   */
  private async checkAppVersion(): Promise<boolean> {
    let newestAppVersion = this.environmentService.version;

    const currentUser = await this.storage.get('currentUser');

    // eslint-disable-next-line eqeqeq
    if (
      currentUser?.email !== 'apple@publicare-gmbh.de' &&
      currentUser?.email !== 'apple@pbc.de' &&
      currentUser?.email !== 'apitest@pia.publicare-gmbh.de'
    ) {
      console.log('connect to backend');
      newestAppVersion = await this.feathersService.app
        .service('version')
        .find({ query: { user: currentUser._id, version: this.environmentService.version } });
    }

    return !(this.isString(newestAppVersion) && newestAppVersion === this.environmentService.version);
  }

  private isString(data: unknown): data is string {
    return typeof data === 'string';
  }
}
