import { IAppointment, IPatient } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { AppointmentDB, PatientDB } from 'src/app/common/repository/databases';

import {
  ActivitiesListSortFilterType,
  ActivitiesListSortService,
} from '../components/activities/list/activities-list-sort.service';
import { PatientDto } from '../models/patient/patient-dto.model';
import { IQuerySearchOptions } from './contracts/query/query-search-options';
import { IQueryService } from './contracts/query/query-service';
import { QueryService } from './query/query.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    @Inject(QueryService) private _queryService: IQueryService,
    private _activitiesListSortService: ActivitiesListSortService
  ) {}

  // eslint-disable-next-line require-await
  public async getAll(): Promise<PatientDto[]> {
    return this._queryService.getAll<PatientDto>(PatientDB);
  }

  // eslint-disable-next-line require-await
  public async find(id: string): Promise<PatientDto> {
    return this._queryService.get<PatientDto>(id, PatientDB);
  }

  // eslint-disable-next-line require-await
  public async query(query: string, options?: IQuerySearchOptions): Promise<PatientDto[]> {
    return this._queryService.search<PatientDto>({ query }, PatientDB, options);
  }

  public async getNextAppointment(patient: IPatient, patientAppointments?: IAppointment[]) {
    if (patientAppointments && !patientAppointments.length) {
      return null;
    }

    const fetchedPatientAppointments = !patientAppointments
      ? await this._queryService.search<IAppointment>({ query: `patientIds:${patient._id}` }, AppointmentDB)
      : patientAppointments;

    const futureNursingHomeAppointments = patient.nursingHomeId
      ? this._activitiesListSortService.getFutureActivities(
          fetchedPatientAppointments.filter(
            appointment => appointment.nursingHomeId != null && appointment.nursingHomeId.length > 0
          ),
          patient.nursingHomeId,
          ActivitiesListSortFilterType.nursingHome
        )
      : {};
    const futurePatientAppointments = this._activitiesListSortService.getFutureActivities(
      fetchedPatientAppointments.filter(appointment => appointment.nursingHomeId == null || !appointment.nursingHomeId),
      patient._id,
      ActivitiesListSortFilterType.patient
    );

    // javascript objects are sorted by keys,
    // when iterated by Object.keys or when stdout by console.log(obj);
    const futureAppointments = { ...futureNursingHomeAppointments, ...futurePatientAppointments };
    if (futureAppointments && Object.keys(futureAppointments).length) {
      const futureAppointmentKeys = Object.keys(futureAppointments);
      const newestGroupedAppointments = futureAppointments[futureAppointmentKeys[0]];
      return newestGroupedAppointments[0];
    }
    return null;
  }

  // Static, da es sonst Probleme mit dem scope beim Aufruf in patient-edit.component.ts gab
  public static isChild(birthday: Date): boolean {
    if (!birthday) {
      return null;
    }

    const ageOfPatient = moment().diff(birthday, 'years');
    if (ageOfPatient < 16) {
      return true;
    }

    return false;
  }
}
