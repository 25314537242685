import { NgModule } from '@angular/core';

import { UsernamePipe } from './username.pipe';
import { UserMainGroupPipe } from './user-main-group.pipe';

@NgModule({
  declarations: [UsernamePipe, UserMainGroupPipe],
  exports: [UsernamePipe, UserMainGroupPipe],
})
export class UserPipesModule {}
