import { Pipe, PipeTransform } from '@angular/core';

import { UsersComponentModel } from '../../../business/user/users.component.model';

@Pipe({ name: 'username' })
export class UsernamePipe implements PipeTransform {
  constructor(private _userModel: UsersComponentModel) {}
  transform(userId: string) {
    if (!userId) {
      return;
    }
    return new Promise<string>(resolve => {
      this._userModel.get(userId).subscribe(user => {
        if (!user) {
          resolve('Unbekannter Benutzer');
          return;
        }
        resolve(`${user.firstName}  ${user.lastName}`);
      });
    });
  }
}
