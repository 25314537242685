import { ElementRef, Renderer2 } from '@angular/core';

import { AuthService } from '../services/auth.service';

export class BaseAuthorizationDirective {
  constructor(private _authService: AuthService, private _elementRef: ElementRef, private _renderer: Renderer2) {}

  protected setVisibility() {
    const domain = this._elementRef.nativeElement.getAttribute('domain');
    const permission = this._elementRef.nativeElement.getAttribute('permission');

    if (!domain || !permission) {
      return;
    }
    this._authService.init.then(() => {
      if (this._authService.userHasPermission(domain, permission)) {
        this._renderer.removeStyle(this._elementRef.nativeElement, 'display');
      } else {
        this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'none');
      }
    });
  }
}
