import { AggregateRoot } from '../aggregate-root';
import { IRegion } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

export class RegionDto extends AggregateRoot implements IRegion, ISearchable {
  label = '';
  parent: string;
  postalCodes: string[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;

  get displayText(): string {
    return this.label;
  }
}
