import { Component, Input, OnInit } from '@angular/core';

export enum InfoBoxType {
  Information,
  Warning,
  Error,
  Success,
}

@Component({
  selector: 'itl-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent implements OnInit {
  @Input() type: InfoBoxType = InfoBoxType.Information;

  public icon = 'alberta-info';
  public class = 'info';

  ngOnInit(): void {
    if (this.type === InfoBoxType.Information) {
      this.class = 'info';
      this.icon = 'alberta-info';
    } else if (this.type === InfoBoxType.Warning) {
      this.class = 'warning';
      this.icon = 'alberta-warning';
    } else if (this.type === InfoBoxType.Error) {
      this.class = 'error';
      this.icon = 'alberta-search-clear';
    } else if (this.type === InfoBoxType.Success) {
      this.class = 'success';
      this.icon = 'alberta-product-group-checkmark';
    }
  }
}
