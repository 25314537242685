import { INursingService } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class NursingServiceDto extends AggregateRoot implements INursingService, ISearchable {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  department: string;

  constructor() {
    super();
  }
  get displayText(): string {
    return this.name;
  }
}
