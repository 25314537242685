import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';

import makeDebug from '../../../../makeDebug';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { IPipelineElement } from '../contracts/start/pipeline-element';
import { LocalFilesystemCleaner as FileSystemCleaner, SyncRegistry } from '../contracts/start/pipeline-endpoints';

const debug = makeDebug('sync:local-file-system-cleaner');

@Injectable()
export class LocalFilesystemCleaner implements IPipelineElement {
  public get endpoint(): string {
    return FileSystemCleaner;
  }

  public get parent(): string {
    return SyncRegistry;
  }

  constructor(private _platform: Platform, private _file: File, private _androidPermissions: AndroidPermissions) {}

  public async execute(_context: IPipelineContext): Promise<void> {
    debug('cleaning local file system');

    await this._platform.ready();

    if (!this._platform.is('cordova') || this._platform.is('ios')) {
      return;
    }

    const androidPermissionResponse = await this._androidPermissions.checkPermission(
      this._androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
    );
    if (!androidPermissionResponse.hasPermission) {
      return;
    }

    const platformAppDirectory = this._platform.is('android')
      ? this._file.externalApplicationStorageDirectory
      : this._file.tempDirectory;

    await this.deletePdfFiles(platformAppDirectory);
  }

  private async deletePdfFiles(rootDirectory: string) {
    if (rootDirectory.endsWith('/')) {
      rootDirectory = rootDirectory.slice(0, -1);
    }
    const rootFolder = rootDirectory.substring(0, rootDirectory.lastIndexOf('/'));
    const appFolder = rootDirectory.substring(rootDirectory.lastIndexOf('/') + 1);
    const entries = await this._file.listDir(rootFolder, appFolder);
    for (const entry of entries) {
      if (entry.isFile && entry.name.includes('.pdf')) {
        entry.remove(
          () => {},
          error => window.logger.error(error ? error.message : '', null)
        );
      }
    }
  }
}
