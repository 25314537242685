import { TimePeriod } from '@pia/pia.shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { returnDurationText } from 'src/app/business/general/duration2text-factory';

@Pipe({
  name: 'timePeriodPipe',
  pure: false,
})
export class TimePeriodPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(timePeriod: TimePeriod, plural: boolean = false, dative: boolean = false): any {
    return returnDurationText(this._translate, +timePeriod, plural, dative);
  }
}
