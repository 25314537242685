import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Deferred } from 'src/app/common/deferred/deferred';
import { CodePushService } from 'src/app/shared/services/code-push/code-push.service';
import { environment } from 'src/environments/environment';
import makeDebug from '../../../../makeDebug';
import { version } from 'src/environments/build-number';

const debug = makeDebug('services:environment');

export const Branch = {
  dev: 'dev',
  staging: 'staging',
  production: 'master',
};

type IBranch = 'dev' | 'staging' | 'master';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _ready = new Deferred<void>();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  public get version() {
    return version;
  }

  public get branch(): IBranch {
    return environment.branch as IBranch;
  }

  private _swodocUrl: string;
  public get swodocUrl() {
    return this._swodocUrl;
  }

  private _isProduction = false;
  public get isProduction() {
    return this._isProduction;
  }

  private _alberta_baseurl: string;
  public get alberta_baseurl() {
    return this._alberta_baseurl;
  }

  constructor(private _platform: Platform, private _codePushService: CodePushService) {}

  public async init() {
    if (this._platform.is('cordova')) {
      await this.initCordova();
    }
    await this.initAlbertaUrls();
    this._ready.resolve();
  }

  private initCordova() {
    let deployment = this.branch;
    if (!deployment) {
      deployment = this._codePushService.currentDeployment
        ? (this._codePushService.currentDeployment as IBranch)
        : (Branch.production as IBranch);
      if (deployment) {
        debug('deployment from codepush', deployment);
      }
    }
  }

  private initAlbertaUrls() {
    this._alberta_baseurl = environment.environmentSettings.apiUrl;
    this._swodocUrl = environment.environmentSettings.swodocUrl;
    debug('_alberta_baseurl', this.alberta_baseurl);
    debug('_swodocUrl', this._swodocUrl);
    debug('_branch', this.branch);
  }
}
