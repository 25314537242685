import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntermediaryDto } from '../../../../../models/institution/intermediary-dto.model';
import { IListView } from '../../../contracts/list-view';

@Component({
  selector: 'intermediary-list-item',
  templateUrl: 'intermediary-list-item.html',
  styleUrls: ['./../autocomplete-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntermediaryListItem implements IListView {
  @Input() item: IntermediaryDto;
  @Output() clicked: EventEmitter<IntermediaryDto> = new EventEmitter<IntermediaryDto>();

  public triggerEvent() {
    this.clicked.emit(this.item);
  }
}
