import { IIntermediary } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { AggregateRoot } from '../aggregate-root';

export class IntermediaryDto extends AggregateRoot implements IIntermediary, ISearchable {
  role: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  attribute1: string;
  attribute2: string[];

  constructor() {
    super();
  }

  get displayText(): string {
    return `${this.name} (${this.city})`;
  }
}
