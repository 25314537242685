<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="mobile-buttons">
      <ion-button class="button back-button buttons button-clear header-item" (click)="this.handleClickBack()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <div class="content">
      <div class="content__avatar">
        <ngx-avatar *ngIf="isActive" initialsSize="3" size="28" [name]="channelName"></ngx-avatar>
        <ngx-avatar
          *ngIf="!isActive"
          initialsSize="3"
          size="28"
          [name]="this.channelName"
          [bgColor]="disalbedAvatarBackgroundColor"
          [fgColor]="disalbedAvatarTextColor"
          [style]="{
            border: disalbedAvatarBorder
          }"
        >
        </ngx-avatar>
      </div>
      <div class="content__titles">
        <h1>
          {{ channelName }}
        </h1>
        <h2 *ngIf="showSubtitle">{{ subtitle }}</h2>
      </div>
      <ng-content></ng-content>
    </div>
  </ion-toolbar>
</ion-header>
