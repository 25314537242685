import { inject, InjectionToken } from '@angular/core';
import * as TwilioConversation from '@twilio/conversations';
import { filter } from 'rxjs/operators';

import makeDebug from '../../../../../makeDebug';
import { ChatUserService } from '../chat-user.service';
import { CREATE_CLIENT_METHOD } from './twilio.factory';

const debug = makeDebug('services:chat:twilio');

export type twilioClientFactory = (
  token: string,
  options?: TwilioConversation.ClientOptions
) => Promise<TwilioConversation.Client>;

export const TWILIO_AGENT_TOKEN = new InjectionToken<Promise<TwilioConversation.Client>>(
  'Manually constructed Twilio Service',
  {
    providedIn: 'root',
    factory: () => _initAgentTwilio(inject(ChatUserService), inject(CREATE_CLIENT_METHOD)),
  }
);

export function _initAgentTwilio(
  _chatUserService: ChatUserService,
  createClient: twilioClientFactory
): Promise<TwilioConversation.Client> {
  let client: TwilioConversation.Client = null;
  return new Promise<TwilioConversation.Client>(resolve => {
    _chatUserService.localAgentChatToken$
      .pipe(filter(chatToken => chatToken && chatToken.length > 0))
      .subscribe(async (chatToken: string) => {
        debug('initialize twilio agent chat', chatToken);
        try {
          if (client) {
            await client.updateToken(chatToken);
            return;
          }

          if (chatToken === 'NO_AGENT') {
            resolve(undefined);
            return;
          }

          client = await createClient(chatToken, { logLevel: 'error' });
          debug('... initialized agent twilio!');
          resolve(client);
        } catch (err) {
          console.error('agent twilio init error', err);
        }
      });
  });
}
