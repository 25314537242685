<ion-header>
  <ion-toolbar>
    <ion-title>{{ currentIndex + 1 }} / {{ images.length }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="_modalCtrl.dismiss()">
        <span color="primary" *ngIf="isIos"> {{ 'CANCEL_BUTTON' | translate }} </span>
        <ion-icon name="close" *ngIf="!isIos"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scroll="false">
  <ion-slides #slides pager="true" (ionSlideDidChange)="slideChanged()">
    <ion-slide *ngFor="let image of images">
      <img [src]="image" [ngClass]="{ 'lightbox-image': !(isAndroid && version <= 4.4) }" />
    </ion-slide>
  </ion-slides>
</ion-content>
