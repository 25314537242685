import { IAudit } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { IStateExtension } from 'src/app/common/contracts/state/state-extension';
import { IStateRegistry } from 'src/app/common/contracts/state/state-registry';
import { Changes } from 'src/app/common/tracking';
import { AuditModelName } from 'src/app/shared/models/model-names';

@Injectable({ providedIn: 'root' })
export class AuditStateExtension implements IStateExtension<IAudit> {
  private _stateRegistry: IStateRegistry;

  public get name(): string {
    return AuditModelName;
  }

  setRegistry(stateRegistry: IStateRegistry): void {
    this._stateRegistry = stateRegistry;
  }

  // eslint-disable-next-line require-await
  public async canBeStored(item: IAudit): Promise<boolean> {
    return item.completed;
  }

  // eslint-disable-next-line require-await
  public async afterCreate(_items: IAudit[]): Promise<void> {
    return;
  }

  public async afterUpdate(items: IAudit[], metadata?: { [key: string]: Changes }): Promise<void> {
    if (!items || !metadata || !metadata['changes']) {
      return;
    }

    for (const audit of items) {
      const canBeDispatched =
        (await this.canBeStored(audit)) &&
        metadata.changes.changes.some(change => change.path === '#.completed' && change.newValue);
      if (!canBeDispatched) {
        continue;
      }

      await this._stateRegistry.createBySync(AuditModelName, 'completed', audit);
    }
  }
}
