<div class="alberta-button-input-wrapper" tappable>
  <div class="button-content-wrapper">
    <div class="icon-wrapper">
      <ion-icon [name]="icon"></ion-icon>
    </div>
    <div class="content-wrapper">
      <div class="title">{{ title }} <span *ngIf="required" class="required-star">*</span></div>
      <div class="description bold" *ngIf="description">
        {{ description }}
      </div>
      <div class="buttons">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
