import { Injectable } from '@angular/core';
import authentication from '@feathersjs/authentication-client';
import axios from 'axios';
import restClient from '@feathersjs/rest-client';
import { environment } from 'src/environments/environment';
import feathers, { Application, Service } from '@feathersjs/feathers';

@Injectable({ providedIn: 'root' })
export class FeathersHttpService {
  private _app: Application;

  public get app(): Application {
    if (!this._app) {
      this.setupFeathersApp();
    }
    return this._app;
  }

  private setupFeathersApp() {
    this._app = feathers();
    const rest = restClient(environment.environmentSettings.apiUrl);
    this._app.configure(rest.axios(axios));
    // TODO: Umstellung auf Azure-Ad-Login auch hier vollziehen
    this._app.configure(
      authentication({
        header: 'Authorization',
        path: '/authentication',
        jwtStrategy: 'fusionAuthJwt',
        entity: 'user',
        service: 'users',
        cookie: 'feathers-jwt',
        storageKey: 'authorizationData',
        storage: window.localStorage,
        timeout: 15000,
      } as any)
    );
  }
}
