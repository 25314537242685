import { IInstitutionAudit } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { InstitutionAuditWorkItem } from 'src/app/business/institution/institution-audit-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class InstitutionAuditCommandHandler extends EntityCommandHandler<IInstitutionAudit> {
  protected workItem: IWorkItem<IInstitutionAudit>;

  constructor(institutionAuditWorkItem: InstitutionAuditWorkItem) {
    super();
    this.workItem = institutionAuditWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'InstitutionAuditDto';
  }
}
