import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['../_templates/text-input-template/text-input-template-alb-class.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements IBaseInputContract, AfterViewInit, ControlValueAccessor {
  @Input() public title: string;
  @Input() public placeholder = '';
  @Input() public icon: string;
  // Zeigt einen roten Stern neben dem Titel an
  @Input() public required = false;
  // Zeigt einen Counter der die noch möglichen Zeichen anzeigt
  @Input() public maxlength: number;
  @Input() public description: string;

  @ViewChild('textInput', { static: true }) textInput: ElementRef;

  public value = '';
  private onChange = (_text: string) => {};
  private onTouched = () => {};

  constructor(@Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.textInput.nativeElement.style.height = 'auto';
      this.textInput.nativeElement.style.height = `${this.textInput.nativeElement.scrollHeight}px`;
    }, 100);
  }

  public setFocus() {
    this.textInput.nativeElement.focus();
    this.markAsTouched();
  }

  public markAsTouched() {
    this.onTouched();
  }

  public updateChanges() {
    this.onChange(this.value);
  }

  // ControlValueAccessor interface
  public writeValue(obj: any) {
    this.value = obj;
    this.updateChanges();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
