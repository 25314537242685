import moment from 'moment';

import { dateFormat, dateFormatWithoutTime } from '../date/format';
import { ignoreMetadataKey, removeTimeMetadataKey } from '../tracking/tracking-keys';
import { viewModelMetadataKey } from './viewmodel-instance';

function isObject(x: any): x is {} {
  return typeof x === 'object' ? x !== null : typeof x === 'function';
}

function executeTriggers<TSource, TTarget>(source: TSource, target: TTarget): void {
  if (
    Reflect.getMetadata(viewModelMetadataKey, source.constructor) &&
    isObject(source) &&
    Reflect.hasOwnMetadata('onSave', source.constructor)
  ) {
    const trigger = Reflect.getOwnMetadata('onSave', source.constructor);
    if (typeof trigger === 'function') {
      trigger(source);
    }
  }

  if (
    !Reflect.getMetadata(viewModelMetadataKey, source.constructor) &&
    isObject(source) &&
    Reflect.hasOwnMetadata('onCreate', target.constructor)
  ) {
    const trigger = Reflect.getOwnMetadata('onCreate', target.constructor);
    if (typeof trigger === 'function') {
      trigger(source, target);
    }
  }
}

type MomentInput = string | Date | number;

export function map<TSource, TTarget>(source: TSource, target: TTarget, firstLevel = true): TTarget {
  if (!source || !target) {
    if (firstLevel) {
      throw new Error('Source and target must be provided to map function.');
    }
    return;
  }

  executeTriggers(source, target);

  // eslint-disable-next-line guard-for-in
  for (const prop in source) {
    try {
      const ignoreMetadata = Reflect.getMetadata(ignoreMetadataKey, source, prop);
      if (
        !source.hasOwnProperty(prop) ||
        prop === 'changeTracker' ||
        prop === 'changes' ||
        (ignoreMetadata && !ignoreMetadata.save)
      ) {
        if (target.hasOwnProperty(prop)) {
          delete (target as any)[prop];
        }
        continue;
      }

      if (
        isObject(source[prop]) &&
        !moment(source[prop] as any as MomentInput, dateFormat, true).isValid() &&
        !moment(source[prop] as any as MomentInput, dateFormatWithoutTime, true).isValid()
      ) {
        if (!Array.isArray(source[prop])) {
          (target as any)[prop] = map((source as any)[prop], JSON.parse(JSON.stringify(source[prop])), false);
        } else if (Array.isArray(source[prop])) {
          (target as any)[prop] = [];
          for (const item of (source as any)[prop]) {
            if (isObject(item)) {
              const result = map(item, JSON.parse(JSON.stringify(item)), false);
              (target as any)[prop].push(result);
            } else {
              (target as any)[prop].push(item);
            }
          }
        }
      } else if (
        moment(source[prop] as any as MomentInput, dateFormat, true).isValid() ||
        moment(source[prop] as any as MomentInput, dateFormatWithoutTime, true).isValid()
      ) {
        (target as any)[prop] = new Date(source[prop] as any);
        const metadata = Reflect.getMetadata(removeTimeMetadataKey, source, prop);
        if (metadata) {
          Reflect.defineMetadata(removeTimeMetadataKey, {}, target, prop);
        }
      } else {
        (target as any)[prop] = source[prop];
      }
    } catch (error) {
      window.logger.error(
        `MAPPER MAP: Mapping or parsing of source: ${JSON.stringify(source)} and target ${JSON.stringify(
          target
        )} failed at property: ${prop}`,
        error
      );
      throw error;
    }
  }

  return target;
}
