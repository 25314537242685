import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsPipesModule } from 'src/app/common/pipes/components/components-pipes.module';

import { AlbertaCommonComponentsModule } from '../../common/common-components.module';
import { InputTemplatesModule } from '../_templates/input-templates.module';
import { AutocompleteListItemsModule } from '../autocomplete/list-items/autocomplete-list-items.module';
import { MulitAutocompleteDesktopSearchView } from './desktop-search-view/multi-autocomplete-desktop-search-view.component';
import { MulitAutocompleteAttributeTemplateListItem } from './list-items/multi-autocomplete-attribute-template-item';
import { MulitAutocompleteGroupListItem } from './list-items/multi-autocomplete-group-list-item';
import { MultiAutocompleteInsuranceContractListItem } from './list-items/multi-autocomplete-insurance-contract-list-item';
import { MultiAutocompletePayerListItem } from './list-items/multi-autocomplete-payer-list-item';
import { MultiAutocompleteProductGroupListItem } from './list-items/multi-autocomplete-product-group-list-item';
import { MulitAutocompleteStandardCareProposalpListItem } from './list-items/multi-autocomplete-std-care-proposal-list-item';
import { MultiAutocompleteComponent } from './multi-autocomplete.component';
import { SelectAllItemComponent } from './select-all-item/select-all-item.component';

@NgModule({
  declarations: [
    MulitAutocompleteDesktopSearchView,
    MultiAutocompleteProductGroupListItem,
    MulitAutocompleteGroupListItem,
    MultiAutocompleteInsuranceContractListItem,
    MultiAutocompletePayerListItem,
    MulitAutocompleteStandardCareProposalpListItem,
    MulitAutocompleteAttributeTemplateListItem,
    MultiAutocompleteComponent,
    SelectAllItemComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule.forChild(),
    AutocompleteListItemsModule,
    AlbertaCommonComponentsModule,
    InputTemplatesModule,
    ComponentsPipesModule,
  ],
  exports: [MultiAutocompleteComponent],
})
export class MultiAutocompleteModule {}
