import { Inject, Injectable, NgZone } from '@angular/core';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { ISyncStatus } from 'src/app/common/contracts/sync/sync-status';
import { SyncStatus } from 'src/app/common/sync/sync-status';

import { AuthService } from './auth.service';
import { IFeathersService } from './contracts/sync/feathers-service';
import { IWebSyncService } from './contracts/sync/service/web-sync-service';
import { FeathersService } from './feathers.service';
import { SyncTimeoutRepeater } from './sync/sync-timeout-repeater.service';
import { WebSyncService } from './sync/web-sync.service';

@Injectable({
  providedIn: 'root',
})
export class WebDataSyncService {
  constructor(
    @Inject(WebSyncService) private _syncServices: IWebSyncService[],
    @Inject(FeathersService) private _feathersService: IFeathersService,
    private _ngZone: NgZone,
    @Inject(SyncStatus) private _syncStatus: ISyncStatus,
    private _authService: AuthService
  ) {
    this._feathersService.connectionModeChange.subscribe(connectionMode => {
      if (connectionMode === ConnectionMode.default) {
        this._syncServices.forEach(service => service.setup(this._feathersService));
      }
    });
  }

  public async startSync(token: ICancellationToken): Promise<void> {
    for (const service of this._syncServices) {
      await service.setup(this._feathersService);
    }
    const promise = this.sync(token);

    token.promise = promise;

    return promise;
  }

  private async sync(cancellationToken: ICancellationToken) {
    this._syncStatus.completedData = false;
    this._syncStatus.completedSync = false;

    await Promise.all(
      this._syncServices.map(syncService =>
        new SyncTimeoutRepeater(syncService, this._ngZone)
          .sync(cancellationToken, this._authService.authentication.account)
          .catch(() => undefined)
      )
    );

    return new Promise<any>(resolve => this.waitUntilSyncCompleted(cancellationToken, resolve));
  }

  private waitUntilSyncCompleted(token: ICancellationToken, resolve: (value?: {} | PromiseLike<{}>) => void) {
    this._ngZone.runOutsideAngular(() => {
      const interval = global.setInterval(() => {
        if (!Object.keys(token.serviceRepeats).length) {
          this._syncStatus.completedData = Object.values(token.syncCompleted || {}).every(value => value);
          this._syncStatus.completedSync = true;
          resolve();
          global.clearInterval(interval);
          return;
        }
      }, 500);
    });
  }
}
