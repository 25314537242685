<div class="desktopautocompleteWrapper" [ngClass]="{ 'desktopautocompleteWrapper--disabled': disabled }">
  <div class="icon-wrapper">
    <ion-icon [name]="icon"></ion-icon>
  </div>
  <div
    id="searchAreaWrapper"
    #autocompleteWrapper
    [class]="searchViewOpen ? 'searchviewopen' : 'searchviewclosed'"
    [ngClass]="{ alignButton: directionBottom, alignTop: !directionBottom }"
  >
    <div class="searchbarWrapper" [ngClass]="{ closeView: fadingOut }">
      <button class="backButton" *ngIf="searchViewOpen" (click)="closeSearchArea()">
        <ion-icon name="alberta-arrow-left"></ion-icon>
      </button>
      <form id="searchInputForm" action="." (keydown)="keyDownFunction($event)">
        <input
          [placeholder]="placeholder"
          [(ngModel)]="searchValue"
          [ngModelOptions]="{ standalone: true }"
          type="search"
          (focus)="focus()"
          #searchbar
          class="searchInput"
          [ngClass]="{
            bold: searchValue && searchValue.length
          }"
        />
      </form>
    </div>

    <div id="titel">{{ titel }} <span *ngIf="required" class="required-star">*</span></div>
    <div
      #resultsArea
      [ngClass]="{ showResultsAreaAtBottom: directionBottom, showResultsAreaAtTop: !directionBottom }"
      class="resultsArea"
    >
      <itl-loading-indicator *ngIf="busy"></itl-loading-indicator>
      <itl-warning-item *ngIf="!busy && !hasAnyItems && !hasNotSearchYet && searchViewOpen">{{
        'validation.errors.autocompleteNoResults' | translate
      }}</itl-warning-item>
      <div class="listwrapper">
        <div class="resultItems">
          <div class="element-container" #pickContainer></div>
        </div>
        <div class="chipList" *ngIf="searchViewOpen && selectedItems.length && showChips && !fadingOut">
          <div class="chip" *ngFor="let item of selectedItems">
            {{ item.displayText }}
            <button (click)="removeChip(item)"><ion-icon name="alberta-cancel"></ion-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
