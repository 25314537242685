import { CareType } from '@pia/pia.shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'careTypePipe',
  pure: true,
})
export class CareTypePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}
  transform(careType: CareType): string {
    if (careType === undefined) {
      return '';
    }
    let careTypeName = '';
    switch (careType) {
      case CareType.Initial:
        careTypeName = this._translate.instant('careType.initial');
        break;
      case CareType.FollowUp:
        careTypeName = this._translate.instant('careType.followUp');
        break;
      default:
        careTypeName = this._translate.instant('careType.unknown');
        break;
    }
    return careTypeName;
  }
}
