import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'itl-button',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  // Set to "block" for a full-width with left and right borders.
  @Input() expand: 'block' | undefined;
  @Input() disabled = false;

  @HostBinding('class.displayBlock') displayBlock = false;
  @HostBinding('class.disabled') disabledStyle = false;

  ngOnChanges(_changes: SimpleChanges): void {
    this.displayBlock = this.expand === 'block';
    this.disabledStyle = this.disabled;
  }
}
