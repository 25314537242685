<ion-split-pane contentId="main">
  <ion-menu
    menuId="mainNav"
    class="nav"
    [ngClass]="{ open: isMenuExpanded, hide: !authService.authentication.isAuth }"
    [type]="'overlay'"
    (ionDidOpen)="onOpen()"
    (ionDidClose)="onClose()"
    contentId="main"
  >
    <ion-content>
      <div class="col-wrapper">
        <div class="first-col">
          <div>
            <div class="branch-name" *ngIf="!isMasterBranch">
              {{ branchName }}
            </div>
            <!-- Online-/Offline-Anzeige -->
            <ng-container *ngIf="online; else offline">
              <div *ngIf="!isDuringXmasHolidays" class="logo" (click)="goToHomepage()"></div>
              <div *ngIf="isDuringXmasHolidays" class="logoXmas" (click)="goToHomepage()"></div>
            </ng-container>
            <ng-template #offline>
              <div *ngIf="!isDuringXmasHolidays" class="logoWarning"></div>
              <div *ngIf="isDuringXmasHolidays" class="logoXmasWarning"></div>
              <div class="text-[color:var(--alberta-color-danger)] text-center font-bold text-lg">Offline</div>
            </ng-template>
            <!-- Warteschlange / Offene übertragungen -->
            <ng-container *ngIf="!unsendData || unsendData == 0; else hasUnsendData">
              <div title="Keine offenen Übertragungen" class="p-2 mx-auto text-center text-white">
                <ion-icon class="no-ion-icon-title" size="large" name="file-tray-outline"></ion-icon>
              </div>
            </ng-container>
            <ng-template #hasUnsendData>
              <div
                title="Offene Übertragungen: {{ unsendData }}"
                class="p-2 mx-auto text-center text-[color:var(--alberta-color-danger)]"
              >
                <ion-icon class="no-ion-icon-title" size="large" name="file-tray-full-outline"></ion-icon>
                <div class="text-lg font-bold">{{ unsendData }}</div>
              </div>
            </ng-template>
          </div>
          <div class="menu-items">
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              (click)="isMenuExpanded = !isMenuExpanded"
            >
              <ion-icon [name]="!isMenuExpanded ? 'alberta-burger-menu' : 'alberta-burger-close'" class="menu-icon">
              </ion-icon>
            </ion-button>
            <ion-button fill="clear" size="large" class="nav-button" color="light" (click)="openPage('/notifications')">
              <div class="icon-wrapper">
                <div class="count-disturber bold" *ngIf="showNewNotifications">{{ numberOfNewNotifications }}</div>
                <ion-icon name="alberta-notification" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
            <!-- ngIf is used to wait for the permissions -->
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              domain="chat"
              (click)="openPage('/chat')"
              *ngIf="userIsAuthenticated"
              itl-authorize
              permission="8"
            >
              <div class="icon-wrapper">
                <div class="count-disturber count-disturber--chat bold" *ngIf="unreadMessages > 0">
                  {{ unreadMessages }}
                </div>
                <ion-icon name="alberta-message" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              color="light"
              domain="chat"
              (click)="openPage('/agent-chat')"
              *ngIf="userIsAuthenticated && userIsChatAgent"
            >
              <div class="icon-wrapper">
                <div class="count-disturber count-disturber--chat bold" *ngIf="unreadAgentMessages > 0">
                  {{ unreadAgentMessages }}
                </div>
                <ion-icon name="alberta-support" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
            <ion-button
              fill="clear"
              size="large"
              class="nav-button"
              class="nav-help"
              color="light"
              (click)="openPage('/help')"
            >
              <div class="icon-wrapper">
                <ion-icon name="alberta-help" class="menu-icon"></ion-icon>
              </div>
            </ion-button>
          </div>
          <div class="menu-footer">
            <ion-button
              [disabled]="isLoggingOut"
              menuClose
              fill="clear"
              size="large"
              class="nav-button"
              class="nav-button"
              color="light"
              (click)="logout()"
            >
              <ion-icon name="alberta-logout" class="menu-icon"></ion-icon>
            </ion-button>
            <div class="p-2 text-sm text-white" *ngIf="(isAppVersionOutdated$ | async) === false">
              Version: {{ version }}
            </div>
            <div class="p-2 text-sm text-white bg-red-800" *ngIf="(isAppVersionOutdated$ | async) === true">
              Version: {{ version }}
            </div>
          </div>
        </div>

        <div class="second-col">
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: menuItems }"></ng-container>
        </div>
      </div>
    </ion-content>
  </ion-menu>

  <ng-template #menuItem let-items>
    <div
      *ngFor="let item of items; let i = index"
      [ngClass]="{
        'active-nav': selectedCategory == -1 || selectedCategory == i
      }"
    >
      <!-- without the children -->
      <ion-button
        *ngIf="!item.children"
        fill="clear"
        menuClose
        strong="true"
        class="nav-button ion-text-capitalize"
        (click)="openPage(item.page)"
      >
        <ion-icon name="{{ item.icon }}" slot="start"></ion-icon>
        {{ item.name }}
      </ion-button>

      <!-- with children -->
      <div *ngIf="item.children">
        <!-- parent -->
        <ion-button
          strong="true"
          fill="clear"
          class="nav-button ion-text-capitalize"
          (click)="this.selectedCategory == i ? (this.selectedCategory = -1) : (this.selectedCategory = i)"
        >
          <ion-icon name="{{ item.icon }}" slot="start"></ion-icon>
          {{ item.name }}
        </ion-button>
        <!-- children -->
        <!-- *ngIf selected -->
        <ion-list [ngClass]="{ dropped: selectedCategory == i }">
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item.children }"></ng-container>
        </ion-list>
      </div>
    </div>
  </ng-template>
  <ion-grid id="main" class="ion-no-padding col-wrapper">
    <ion-row class="bg-gray-400">
      <ion-col>
        <div *ngIf="commandError && !isDuplicateError(commandError)" class="flex items-center justify-center m-1">
          <div class="p-2 m-2 text-red-600 bg-white border-4 border-red-600 rounded-md">
            <p class="text-lg font-bold">Übertragungsfehler</p>
            <p>{{ commandError.name }} - {{ commandError.message }}</p>
          </div>
        </div>
        <div *ngIf="commandError && isDuplicateError(commandError)" class="flex items-center justify-center m-1">
          <div class="p-2 m-2 text-red-600 bg-white border-4 border-red-600 rounded-md">
            <div class="text-lg font-bold">Übertragungsfehler</div>
            <div>{{ commandError.message }}</div>
            <div>Anlage nicht möglich, sämtliche eventuell angelegten Dokumente, Vesorgungen etc. gehen verloren.</div>
            <div>
              <div
                class="w-40 p-2 mt-2 font-bold text-center bg-gray-400 border-2 border-red-600 rounded-md"
                (click)="deleteDuplicate()"
              >
                Duplikat löschen
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row style="height: 100%">
      <ion-col>
        <ion-router-outlet></ion-router-outlet>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-split-pane>
