import { Permission } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { Push } from '@ionic-native/push/ngx';
import { Platform } from '@ionic/angular';
import * as TwilioConversation from '@twilio/conversations';
import { filter, map, take } from 'rxjs/operators';
import { TWILIO_TOKEN } from 'src/app/shared/services/chat/twillio/twilio.factory';
import { getRawPushForAPN, getRawPushForFCM } from 'src/app/shared/services/push/phonegap-push-reparser';
import { environment } from 'src/environments/environment';
import makeDebug from 'src/makeDebug';

import { AuthService } from '../auth.service';
import { Chat } from '../chat/model/chat-instance';
import { TWILIO_AGENT_TOKEN } from '../chat/twillio/agent-twilio.factory';

const debug = makeDebug('services:push');
@Injectable({ providedIn: 'root' })
export class PushService {
  private _serviceWorkerChannel = new BroadcastChannel('worker-communication');
  private pushAlreadyRegistered = false;

  constructor(
    private platform: Platform,
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioConversation.Client>,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioConversation.Client>,
    private messaging: AngularFireMessaging,
    private push: Push,
    private _authService: AuthService,
    private readonly router: Router
  ) {}

  public init() {
    this._authService.authenticatedEventPublisher
      .pipe(
        filter(auth => auth.isAuthenticated),
        map(_auth => this._authService.userHasPermission('chat', Permission.DeleteArchive)),
        filter(hasPermission => hasPermission),
        take(1)
      )
      .subscribe(async () => {
        await this.registerPushNotification(this.platform.is('ios') ? 'apn' : 'fcm');
      });
  }

  private async registerPushNotification(pushChannel: 'apn' | 'fcm') {
    if (!this.pushAlreadyRegistered) {
      debug('init push with pushChannel:', pushChannel);
      if (this.platform.is('cordova')) {
        await this.registerCordova(pushChannel);
      } else {
        if (await this.requestMessagingPermission()) {
          await this.registerWeb();
        }
      }
    }
  }

  private async registerWeb(chat = Chat.Alberta) {
    debug('registerWeb', chat);
    if (chat === Chat.PatientApp && !this._authService.authentication.account.isAgent) {
      debug('agent push registration cancelled because user is not an chat agent');
      return;
    }

    const client = chat === Chat.Alberta ? await this._twilioChatClient : await this._twilioAgentChatClient;
    if (!client) {
      return;
    }

    try {
      const fcmToken = await this.messaging.getToken.toPromise();
      this._serviceWorkerChannel.postMessage({ userId: this._authService.getCurrentUserId() });
      await client.setPushRegistrationId('fcm', fcmToken);
      debug(`push notification for ${chat === Chat.Alberta ? 'alberta' : 'patient-app'} registered`);
      this.messaging.messages.subscribe(client.handlePushNotification.bind(client));
      this.pushAlreadyRegistered = true;
    } catch (e) {
      console.error(e);
    }
  }

  private async registerCordova(pushChannel: 'apn' | 'fcm') {
    debug('registerCordova', pushChannel);
    await this.platform.ready();
    const client = await this._twilioChatClient;
    if (!client) {
      return;
    }

    debug('init push');
    const push = this.push.init({
      android: {
        senderID: environment.firebase.messagingSenderId,
      },
      ios: {
        sound: true,
        badge: true,
      },
    });
    debug('push object', push);

    push.on('registration').subscribe(async data => {
      debug('handle push registration');
      const oldRegId = localStorage.getItem('registrationId');
      if (oldRegId !== data.registrationId) {
        localStorage.setItem('registrationId', data.registrationId);
        // Post registrationId to your app server as the value has changed
      }
      debug('setPushRegistrationId', pushChannel, data.registrationId);
      try {
        await client.setPushRegistrationId(pushChannel, data.registrationId);
        this.pushAlreadyRegistered = true;
      } catch (error) {
        console.warn('push error', error.message);
      }
    });

    push.on('error').subscribe(e => {
      debug('handle push error', e);
      console.error('push error', e.message);
    });

    push.on('notification').subscribe(async data => {
      debug('handle push notification', data);

      let rawData = null;

      if (pushChannel === 'fcm') {
        rawData = getRawPushForFCM(data);
      } else if (pushChannel === 'apn') {
        rawData = getRawPushForAPN(data);
      }
      debug('raw push data', rawData);
      await client.handlePushNotification(rawData);

      if (!data || !data.additionalData) {
        return;
      }
      const { foreground, coldstart, channel_id } = data.additionalData;
      if (foreground === undefined || coldstart === undefined || channel_id === undefined) {
        return;
      }

      if (!foreground || coldstart) {
        await this.router.navigateByUrl(`/chat/${channel_id}`);
      }
    });
  }

  private async requestMessagingPermission() {
    try {
      await this.messaging.requestPermission.toPromise();
    } catch (e) {
      return false;
    }

    return true;
  }
}
