export class StorageInformation {
  constructor(
    public name: string,
    public displayName: string,
    public isDeletable: boolean,
    public canRepairIndex: boolean,
    public dbCount: number,
    public documentCount: number = 0,
    public indexCount: number = null
  ) {}
}
