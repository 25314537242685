import { IUser } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthActions, AuthObserver, AuthService as MobileOAuthService } from 'ionic-appauth';
import { omit, pick } from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthServiceWrapper } from 'src/app/auth/factories';
import { IAuthEventData } from 'src/app/common/contracts/authentication/auth-event-data';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { Deferred } from 'src/app/common/deferred/deferred';
import { isTokenAboutToExpire } from 'src/app/common/jwt/verifyJWT';
import { isRelevantDoctor } from 'src/app/notifications/config/web-doctor-config';
import { environment } from 'src/environments/environment';

import makeDebug from '../../../makeDebug';
import { ConnectionStateService } from './connection-state.service';
import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { IFeathersAppProvider } from './contracts/sync/feathers-app-provider';
import { FeathersService } from './feathers.service';
import { TrackerService } from './tracker.service';

const debug = makeDebug('auth:auth');

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _oauthObserver: AuthObserver;
  private _tokenExpiredSubscription: Subscription;
  private _init = new Deferred<void>();
  public init = this._init.promise;
  public redirectUrl: string;

  private _authenticationEventSubject = new BehaviorSubject<IAuthEventData>({
    isAuthenticated: false,
  });
  public authenticatedEventPublisher = this._authenticationEventSubject.asObservable();

  private _accountChanged$: Subject<void> = new Subject();

  public authentication = {
    isAuth: false,
    account: undefined,
  };

  private isFetchingAccountData = false;

  public get accountChanged(): Observable<void> {
    return this._accountChanged$.asObservable();
  }

  public get userIsItLabs(): boolean {
    if (!this.authentication.account) {
      return false;
    }

    const userIsItLabs =
      environment.validITLabsUser.some(mailOrSuffixToCheck =>
        this.authentication.account.email.includes(mailOrSuffixToCheck)
      ) ||
      (this.authentication.account.canChangeChannel != null && Boolean(this.authentication.account.canChangeChannel));

    return userIsItLabs;
  }

  public get canCreateTenant(): boolean {
    if (!this.authentication.account) {
      return false;
    }

    const userIsItLabs = environment.validITLabsUser.some(mailOrSuffixToCheck =>
      this.authentication.account.email.includes(mailOrSuffixToCheck)
    );

    return userIsItLabs;
  }

  public get userIsRelevantDoctor(): boolean {
    return isRelevantDoctor(this.getCurrentUserId());
  }

  private _authConfigured = new Deferred<void>();

  private _mobileAccessToken: Deferred<string>;

  private get isCordova(): boolean {
    return this._platform.is('cordova');
  }

  constructor(
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    private storage: Storage,
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    private _tracker: TrackerService,
    private _platform: Platform,
    private _oauthService: OAuthService,
    @Inject(MobileOAuthService) private _mobileOAuthService: AuthServiceWrapper,
    private _ngZone: NgZone
  ) {
    this._connectionStateService.connected.pipe(filter(isOnline => !isOnline)).subscribe(() => {
      this._authenticationEventSubject.next({ isAuthenticated: false });
    });
  }

  public async authenticate(isOnline: boolean): Promise<boolean> {
    debug('authenticate');
    window.logger.addBreadcrumb({
      category: 'auth',
      message: 'call authenticate',
      data: {
        isOnline,
      },
    });
    if (!isOnline) {
      return this.authenticateOffline();
    } else if (isOnline && !this.isCordova) {
      await this._oauthService.tryLogin();
    }
    debug('has valid access token', this._oauthService.hasValidAccessToken());
    // XXX this could be used to start the login flow to prevent the display of the login page
    // if (!this._oauthService.getRefreshToken) {
    //   this._oauthService.initLoginFlow();
    //   return;
    // }
    const accessToken = this.isCordova ? await this._mobileAccessToken.promise : this._oauthService.getAccessToken();
    if (!accessToken) {
      return false;
    }
    return this.loginFeathersApp(accessToken);
  }

  private async loginFeathersApp(accessToken: string): Promise<boolean> {
    debug(`login with access token`, accessToken);

    try {
      const authenticationResult = await this.tryAccessTokenOrByRefreshToken(accessToken);
      debug('got auth result', authenticationResult);
      const currentUserFromStorage = await this.storage.get('currentUser');
      window.logger.addBreadcrumb({
        category: 'auth',
        message: 'currentUser from storage',
        data: currentUserFromStorage,
      });

      if (currentUserFromStorage) {
        this.authentication.account = currentUserFromStorage;
      } else {
        await this.fillAccountData(authenticationResult.user);
        this.authentication.account = authenticationResult.user;
        window.logger.captureErrorWithExtras(
          'ionic storage returned unexpected null for currentUser',
          null,
          new Map([['service', 'auth']]),
          'error'
        );
      }
      this._authenticationAccount.account = this.authentication.account;
      debug('updated authentication account', this.authentication.account);
      this.initializeTracker(this.authentication.account);
      this.observeTokenExpiration(authenticationResult.accessToken);
      return true;
    } catch (error) {
      const isFeathersError = error.type === 'FeathersError';
      const isCriticalError = ['timeout', 'not-authenticated'].includes(error.className) === false;
      if (!isFeathersError || isCriticalError) {
        window.logger.error('AUTHSERVICE AUTHENTICATE', error);
      } else {
        console.error('expected feathers error happened', error);
      }
      return false;
    }
  }

  public async refreshToken(): Promise<boolean> {
    debug('refresh token');
    if (this.isCordova) {
      const oldAccessToken = await this._mobileAccessToken.promise;

      if (!oldAccessToken || oldAccessToken === 'dummy-accessToken') {
        debug(`refreshToken failed with old accessToken - ${oldAccessToken}`);
        return false;
      }

      const refreshTokenPromise = new Promise<boolean>(resolve => {
        const refreshTokenObserver = this._mobileOAuthService.addActionListener(async action => {
          if (action.action === AuthActions.RefreshSuccess) {
            this._mobileOAuthService.removeActionObserver(refreshTokenObserver);
            debug(`refreshToken succeeded with new accessToken - ${action.tokenResponse.accessToken}`);
            resolve(true);
          }
          if (action.action === AuthActions.RefreshFailed) {
            this._mobileOAuthService.removeActionObserver(refreshTokenObserver);
            await this.logout();
            window.location.reload();
          }
        });
      });

      await this._mobileOAuthService.refreshToken();

      return refreshTokenPromise;
    }

    return false;
  }

  public configure(): Promise<void> {
    return this.isCordova ? this.configureMobile() : this.configureBrowser();
  }

  public async login(email: string): Promise<void> {
    debug('login', email);
    if (email) {
      email = email.trim().toLowerCase();
    }

    if (this.isCordova) {
      await this._mobileOAuthService.signIn({ login_hint: email });
    } else {
      // TODO: renenable if code flow works with login_hint
      // PR https://github.com/manfredsteyer/angular-oauth2-oidc/pull/938 is merged
      // this._oauthService.initLoginFlow(this.redirectUrl, email);
      this._oauthService.initLoginFlow(this.redirectUrl);
    }
  }

  public async logout() {
    debug('logout');
    await this._feathersAppProvider.app.authentication.removeAccessToken();

    if (this._connectionStateService.getSocketConnectionState()) {
      try {
        this._tracker.trackLogout();
        await this._feathersAppProvider.app.logout();
      } catch (error) {
        window.logger.error('logout request at backend failed', error);
      }
    }
    await this.storage.remove('currentUser');

    this.authentication.account = undefined;
    this._authenticationAccount.account = undefined;
    this.authentication.isAuth = false;

    this._authenticationEventSubject.next({
      isAuthenticated: false,
    });
    this._tracker.clearIdentify();

    if (this._connectionStateService.getSocketConnectionState()) {
      // eslint-disable-next-line no-unused-expressions
      this.isCordova ? await this._mobileOAuthService.signOut() : this._oauthService.logOut();
    } else if (!this._connectionStateService.getSocketConnectionState() && this.isCordova) {
      await this._mobileOAuthService.removeToken();
      window.location.reload();
    }
  }

  private async tryAccessTokenOrByRefreshToken(accessToken: string) {
    try {
      return await this._feathersAppProvider.app.authenticate({
        strategy: 'fusionAuthJwt',
        accessToken,
        applicationId: environment.authCodeFlowConfigWeb.clientId,
      });
    } catch (error) {
      if (error.code === 401) {
        const newAccessToken = await this.getAccessTokenByRefreshToken();

        return this._feathersAppProvider.app.authenticate({
          strategy: 'fusionAuthJwt',
          accessToken: newAccessToken,
          applicationId: environment.authCodeFlowConfigWeb.clientId,
        });
      }
      throw error;
    }
  }

  public setAuthenticated(isAuthenticated: boolean, isOnline?: boolean) {
    this.authentication.isAuth = isAuthenticated;
    this._authenticationEventSubject.next({ isAuthenticated, authOnline: isOnline });
    this._init.resolve();
    this._tracker.isAuthOnline = isOnline;
  }

  public getCurrentUserId(): string {
    if (this.authentication && this.authentication.account && this.authentication.account._id) {
      return this.authentication.account._id;
    }
    return '';
  }

  public async resetRights(rights) {
    if (!rights) {
      return;
    }

    this.authentication.account.authorization.rightSets = rights;
    await this.storage.set('currentUser', this.authentication.account);
  }

  public async resetCurrentUser(user: IUser, regions: any) {
    this.authentication.account = { ...this.authentication.account, ...omit(user, ['organization']) };
    this.authentication.account.authorization.regions = regions;
    await this.storage.set('currentUser', this.authentication.account);
  }

  public async resetOrganisationMembers(user: IUser) {
    if (
      user &&
      this.authentication &&
      this.authentication.account &&
      this.authentication.account.organization &&
      this.authentication.account.organization.members
    ) {
      if (this.authentication.account.organization.members.some(member => member._id === user._id)) {
        this.authentication.account.organization.members = [
          ...this.authentication.account.organization.members.map(member =>
            member._id === user._id
              ? {
                  ...member,
                  ...pick(user, ['_id', 'firstName', 'lastName', 'roles', 'email', 'archived']),
                }
              : member
          ),
        ];
      } else {
        this.authentication.account.organization.members = [...this.authentication.account.organization.members, user];
      }
      await this.storage.set('currentUser', this.authentication.account);
      this._accountChanged$.next();
    }
  }

  public getUserPermission(domain: string): number {
    if (
      !this.authentication ||
      !this.authentication.account ||
      !this.authentication.account.authorization ||
      !this.authentication.account.authorization.rightSets
    ) {
      return 0;
    }
    const rightSet = this.authentication.account.authorization.rightSets.find(auth => auth.domain === domain);
    return rightSet ? rightSet.permission : 0;
  }

  public userHasPermission(domain: string, permission: number): boolean {
    if (
      !this.authentication ||
      !this.authentication.account ||
      !this.authentication.account.authorization ||
      !this.authentication.account.authorization.rightSets
    ) {
      return false;
    }

    return this.authentication.account.authorization.rightSets.some(
      rightSet => rightSet.permission >= permission && rightSet.domain === domain
    );
  }

  private async authenticateOffline(): Promise<boolean> {
    const currentUser = await this.storage.get('currentUser');
    try {
      const accessToken = this.isCordova ? await this._mobileAccessToken.promise : this._oauthService.getAccessToken();

      if (accessToken != null && accessToken !== 'dummy-accessToken') {
        this.observeTokenExpiration(accessToken);
        this.authentication.account = currentUser;
        this._authenticationAccount.account = currentUser;

        this.initializeTracker(currentUser);
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return currentUser != null;
    }
  }

  private async fillAccountData(user) {
    if (this.isFetchingAccountData) {
      return;
    }

    this.isFetchingAccountData = true;
    if (user.organization && typeof user.organization === 'string') {
      user.organization = await this._feathersAppProvider.app.service('organization').get(user.organization);
    }
    await this.storage.set('currentUser', user);
    this.isFetchingAccountData = false;
  }

  private initializeTracker(account: any) {
    try {
      this._tracker.identify(account._id, account.email, account.organization);
    } catch (error) {
      window.logger.error('tracker initialization failed', error);
    }
  }

  private observeTokenExpiration(accessToken) {
    debug('setup token expiration observer');
    if (this._tokenExpiredSubscription && !this._tokenExpiredSubscription.closed) {
      this._tokenExpiredSubscription.unsubscribe();
    }

    if (this.isCordova) {
      this._tokenExpiredSubscription = isTokenAboutToExpire(accessToken, this._ngZone).subscribe({
        complete: async () => {
          if (this._connectionStateService.getSocketConnectionState()) {
            debug('token will expire... refresh');
            await this.refreshToken();
            debug('accessToken refreshed');
            await this.configure();
            await this.authenticate(this._connectionStateService.isConnected);
            debug('authenticate called with new accessToken');
          }
        },
        error: err => console.error(err),
      });
    } else {
      this._oauthService.events
        .pipe(
          filter(e => e.type === 'token_received'),
          take(1)
        )
        .subscribe(async () => {
          await this.authenticate(this._connectionStateService.isConnected);
        });
    }
  }

  private async getAccessTokenByRefreshToken() {
    if (this._platform.is('cordova')) {
      return new Promise<string>(async resolve => {
        await this.refreshToken();
        await this.configureMobile();
        const accessToken = await this._mobileAccessToken.promise;

        resolve(accessToken);
      });
    } else {
      const tokenResponse = await this._oauthService.refreshToken();
      return tokenResponse.access_token;
    }
  }

  private async configureBrowser() {
    debug('configure browser');
    if (!this._oauthService.tokenEndpoint) {
      // ! prevent reconfigure!
      this._oauthService.configure(environment.authCodeFlowConfigWeb);
      await this._oauthService.loadDiscoveryDocument();
      this._oauthService.setupAutomaticSilentRefresh();
      this.redirectUrl = decodeURIComponent(this._oauthService.state);
    }
  }

  private async configureMobile(): Promise<void> {
    this._mobileAccessToken = new Deferred<string>();
    this._authConfigured = new Deferred<void>();

    this._oauthObserver = this._mobileOAuthService.addActionListener(action => {
      this._mobileOAuthService.removeActionObserver(this._oauthObserver);

      if (action.action === AuthActions.LoadTokenFromStorageSuccess) {
        this._mobileAccessToken.resolve(action.tokenResponse.accessToken);
      }
      if (action.action === AuthActions.LoadTokenFromStorageFailed) {
        this._mobileAccessToken.resolve('dummy-accessToken');
      }

      this._authConfigured.resolve();
    });

    await this._mobileOAuthService.loadTokenFromStorage();

    return this._authConfigured.promise;
  }
}
