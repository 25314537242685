import { PLATFORM_ID } from '@angular/core';
import { Storage } from '@ionic/storage';
import { openDB } from 'idb';
import { remove as _remove } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IIndexMetaDataInfo } from 'src/app/shared/services/contracts/database/index-metadata-info';

export class AlbertaStorage extends Storage implements IGenericStorage {
  private _config;
  constructor(config, public isDeletable: boolean, public canRepairIndex = true) {
    super(config, PLATFORM_ID);

    this._config = config;
  }

  public getItem(key: string, callback?: (error?: Error, result?: string) => void) {
    return this.get(key)
      .then(result => {
        if (callback) {
          callback(undefined, result);
        }
      })
      .catch(err => {
        if (callback) {
          callback(err);
        }
      });
  }

  // eslint-disable-next-line require-await
  public async getItems(keys: string[]): Promise<any[]> {
    if (!keys || !keys.length) {
      return [];
    }
    return (this as any)._dbPromise.then(async db => {
      const values = await db.getItems(keys);
      return Object.keys(values).map(key => values[key]);
    });
  }

  public setItem(key, value, callback?: (error?: Error) => void) {
    return this.set(key, value).catch(err => {
      if (callback) {
        callback(err);
      }
    });
  }

  search(_query: string, _params?: any[]): Promise<any> {
    return;
  }

  executeBatch(_batch: any[]): Promise<any> {
    return;
  }
  repairIndex(): void {
    return;
  }

  readIndexFieldMetaInfo(_item): IIndexMetaDataInfo {
    return null;
  }

  public async setItems(itemsForDb: { items: any[]; deletable: boolean }): Promise<any> {
    const archived = _remove(itemsForDb.items, item => item.archived || (item.metadata && item.metadata.archived));
    if (archived && archived.length) {
      if (itemsForDb.deletable) {
        await Promise.all(archived.map(item => this.removeItem(item._id)));
      } else {
        itemsForDb.items.push(...archived);
      }
    }
    if (itemsForDb.items.length) {
      return (this as any)._dbPromise.then(db =>
        db.setItems(itemsForDb.items.map(item => ({ key: item._id, value: item })))
      );
    }
  }

  public removeItem(key, callback?: (error?: Error) => void): Promise<any> {
    return this.remove(key).catch(err => {
      if (callback) {
        callback(err);
      }
    });
  }

  public removeItems(key): Promise<any> {
    this.getAllKeys((err, keys) => {
      if (err) {
        return;
      }
      keys.forEach(existingKey => {
        if (existingKey.includes(key)) {
          this.removeItem(existingKey);
        }
      });
    });
    return;
  }
  public getAll(): Observable<any> {
    const subject = new Subject();
    (async () => {
      await this.forEach(value => {
        subject.next(value);
      });
      subject.complete();
    })();

    return subject.asObservable();
  }

  public getAllKeys(callback?: (error?: Error, keys?: string[]) => void) {
    return openDB(this._config.name, 2)
      .then(db => db.getAllKeys(this._config.storeName))
      .then(keys => {
        if (callback) {
          callback(
            undefined,
            keys.map(key => key.toString())
          );
        }
      })
      .catch(error => {
        if (callback) {
          callback(error);
        }
      });
  }
}
