<itl-chat-input
  (submit)="this.handleSubmit()"
  (openEmojiPicker)="this.presentPopover($event)"
  [formControl]="formControl"
  [showSendButton]="this.formControl.valid"
  [placeholder]="placeholder"
  [showEmojiPicker]="showEmojiPicker"
  [sendOnEnter]="sendOnEnter"
  [isActive]="isActive"
></itl-chat-input>
