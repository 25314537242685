<div class="channel-list-entry" [ngClass]="{ selected: this.isSelected }">
  <ion-avatar slot="start">
    <ion-icon
      class="selected-badge"
      name="alberta-chat-selected-badge"
      *ngIf="this.isSelected && this.showIsSelectedBadge"
    ></ion-icon>
    <ngx-avatar *ngIf="isActive" initialsSize="3" textSizeRatio="2.6" size="35" [name]="this.channelName"> </ngx-avatar>
    <ngx-avatar
      *ngIf="!isActive"
      initialsSize="3"
      textSizeRatio="2.6"
      size="35"
      [name]="this.channelName"
      [bgColor]="disalbedAvatarBackgroundColor"
      [fgColor]="disalbedAvatarTextColor"
      [style]="{
        border: disalbedAvatarBorder
      }"
    >
    </ngx-avatar>
  </ion-avatar>
  <div class="content" [ngClass]="{ 'content--disabled': !isActive }">
    <h2>{{ this.channelName }}</h2>
    <p class="last-message-subtitle" *ngIf="showLastMessage">
      <ion-icon class="status-icon" name="alberta-sync" *ngIf="this.lastMessageStatus == 'pending'"></ion-icon>
      <ion-icon class="status-icon" name="checkmark-circle-outline" *ngIf="this.lastMessageStatus == 'sent'"></ion-icon>
      <span *ngIf="this.lastMessageSenderName">{{ this.lastMessageSenderName }}:&nbsp;</span>
      <span class="last-message-text">{{ this.lastMessageText }}</span>
    </p>
    <itl-chip
      [showDismiss]="false"
      *ngIf="showLabel"
      [icon]="'alberta-person-chip'"
      [ngClass]="{ notLabel: !labelText, labelMarked: labelMarked }"
      >{{ labelText }}</itl-chip
    >
  </div>
  <div class="secondary-information" slot="end">
    <span [ngClass]="{ 'updated-text': 1, 'updated-text--unread': unreadMessages > 0 }">{{
      this.lastActivityDate
    }}</span>
    <div class="spacer"></div>
    <div class="unread-wrapper" *ngIf="channelStatus == null">
      <ion-badge color="primary" *ngIf="!this.isSelected && unreadMessages > 0">{{ this.unreadMessages }}</ion-badge>
    </div>
    <itl-label *ngIf="channelStatus != null" [baseColor]="labelBaseColorConfig[channelStatus]">
      {{ channelStatus | channelstatus }}
      <div class="channelStatusDisturber" *ngIf="!this.isSelected && unreadMessages > 0">{{ this.unreadMessages }}</div>
    </itl-label>
  </div>
</div>
