import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'itl-chat-emoji-picker',
  templateUrl: './chat-emoji-picker.component.html',
})
export class ChatEmojiPickerComponent {
  constructor(private _popOverController: PopoverController) {}

  public async addEmoji(event: any) {
    await this._popOverController.dismiss(event.emoji.native).catch(error => console.log(error));
  }
}
