import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'itl-chat-channel-header',
  templateUrl: './chat-channel-header.component.html',
  styleUrls: ['./chat-channel-header.component.scss'],
})
export class ChatChannelHeaderComponent {
  @Input() channelName: string;
  @Input() subtitle: string;
  @Input() showSubtitle: boolean;
  @Input() isActive = true;
  @Output() leaveChannel = new EventEmitter<void>();

  public disalbedAvatarBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disalbed-background'
  );

  public disalbedAvatarTextColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disalbed-text'
  );

  public disalbedAvatarBorder = getComputedStyle(document.documentElement).getPropertyValue(
    '--alberta-chat-avatar-disabled-border'
  );

  public handleClickBack() {
    this.leaveChannel.emit();
  }
}
