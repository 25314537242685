import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { AttributeTemplateDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { AttributeTemplateDto } from 'src/app/shared/models/general/attribute-template-dto.model';

import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class AttributeTemplateWorkItem extends WorkItem<AttributeTemplateDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
    this.deletable = false;
  }
  get name(): string {
    return 'AttributeTemplateWorkItem';
  }
  get database(): string {
    return AttributeTemplateDB;
  }
}
