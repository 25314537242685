import { IArticle } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ArticleWorkItem } from 'src/app/business/article/article-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ArticleCommandHandler extends EntityCommandHandler<IArticle> {
  protected workItem: IWorkItem<IArticle>;

  constructor(regionWorkItem: ArticleWorkItem) {
    super();
    this.workItem = regionWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ArticleDto';
  }
}
