import { Inject, Injectable } from '@angular/core';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';

import makeDebug from '../../../../makeDebug';
import { AppReadyEvent } from '../../models/app-ready-event';
import { EventService } from '../../services/event.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { SyncIndicatorCloser as Closer } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:sync-indicator-closer');

@Injectable()
export class SyncIndicatorCloser implements ISyncElement {
  public get name(): string {
    return Closer;
  }

  constructor(
    private _appReadyService: EventService<AppReadyEvent>,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync
  ) {}

  public async execute(context: ISyncContext): Promise<void> {
    await this._platformSync.ready;

    if (!this._platformSync.canBeSynced) {
      return;
    }

    debug('entered execute function', context);

    await this.closeSyncIndicator(context).catch(error => window.logger.error('closing sync-indicator crashed', error));

    context.params.isStartUpSync = false;
  }

  public async closeSyncIndicator(context: ISyncContext) {
    debug('closing sync modal again', context);
    if (context.syncModal) {
      await context.syncModal.dismiss().catch(console.error);
      context.syncModal = null;
    }
    this._appReadyService.dispatchEvent(new AppReadyEvent(true));
  }
}
