import { IPatientHospitalRelation } from '@pia/pia.shared';

import { removeTime } from '../../../common/tracking/tracker';

export class PatientHospitalRelationViewModel implements IPatientHospitalRelation {
  surgeryDate: Date;
  subunitId: string;
  hospitalId: string;
  contactId: string;

  @removeTime()
  dischargeDate: Date;
  _id: string;
  timestamp: Date;
  archived: boolean;
}
