import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ChatModule } from '@pia/pia.shared-components-chat';
import { AvatarModule } from 'ngx-avatar';
import { ChannelListEntryComponent } from 'src/app/shared/components/chat/channel-list-entry/channel-list-entry.component';
import { ChannelListMessage } from 'src/app/shared/components/chat/channel-list-message/channel-list-message.component';
import { AlbertaCommonComponentsModule } from '../common/common-components.module';
import { AlbertaInputsModule } from '../inputs/inputs-components.module';
import { ChannelListEntryStatusPipe } from './channel-list-entry/channel-list-entry-status.pipe';
import { ChannelListComponent } from './channel-list/channel-list.component';
import { ChannelComponent } from './channel/channel.component';
import { ChatChannelHeaderComponent } from './chat-channel-header/chat-channel-header.component';
import { ChatEmojiPickerComponent } from './chat-emoji-picker/chat-emoji-picker.component';
import { ChatEmptyStateComponent } from './chat-empty-state/chat-empty-state.component';
import { ChatLayoutComponent } from './chat-layout/chat-layout.component';
import { ChatListHeaderComponent } from './chat-list-header/chat-list-header.component';
import { ChatLoadingComponent } from './chat-loading/chat-loading.component';
import { ChatMessageInputPlaceholderPipe } from './chat-message-input-placeholder.pipe';
import { ChatMessageInputComponent } from './chat-message-input/chat-message-input.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { LastChannelActivityModule } from './last-channel-activity/last-channel-activity.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AvatarModule,
    HttpClientModule,
    AlbertaCommonComponentsModule,
    TranslateModule,
    PickerModule,
    AlbertaInputsModule,
    ChatModule,
    FormsModule,
    ReactiveFormsModule,
    LastChannelActivityModule,
  ],
  declarations: [
    ChannelListEntryComponent,
    ChannelListMessage,
    ChatLoadingComponent,
    ChatListHeaderComponent,
    ChatChannelHeaderComponent,
    ChannelListComponent,
    ChannelComponent,
    ChatMessageInputComponent,
    ChatEmojiPickerComponent,
    ChatMessageComponent,
    ChatLayoutComponent,
    ChatEmptyStateComponent,
    ChannelListEntryStatusPipe,
    ChatMessageInputPlaceholderPipe,
  ],
  exports: [
    ChannelListEntryComponent,
    ChannelListMessage,
    ChatLoadingComponent,
    ChatListHeaderComponent,
    ChatChannelHeaderComponent,
    ChannelListComponent,
    ChannelComponent,
    ChatMessageInputComponent,
    ChatEmojiPickerComponent,
    ChatMessageComponent,
    ChatLayoutComponent,
    ChatEmptyStateComponent,
    LastChannelActivityModule,
    ChatMessageInputPlaceholderPipe,
  ],
})
export class SharedChatModule {}
