<div
  class="nav-page"
  [ngClass]="{
    'nav-page--editView': isEditView
  }"
>
  <div class="nav-page__header">
    <div tappable id="navbariconbutton" (click)="leaveView()">
      <ion-icon name="alberta-cancel"></ion-icon>
    </div>
    <div class="navbartitle">
      <ion-icon
        *ngIf="visibleWizardSteps[pageID] && visibleWizardSteps[pageID].pathToIcon"
        [name]="visibleWizardSteps[pageID].pathToIcon"
        class="titleIcon"
      >
      </ion-icon>
      <div class="title">
        {{ visibleWizardSteps[pageID].nameTransPath | translate }}
      </div>
    </div>
  </div>
  <div class="nav-page__content">
    <div
      class="section"
      *ngFor="let step of visibleWizardSteps; let i = index"
      (click)="isDisabled(i) ? false : changePage(i)"
      [ngClass]="{
        'section--selected': i === pageID && !isEditView,
        'section--selected-editView': i === pageID && isEditView,
        'section--disabled': isDisabled(i)
      }"
    >
      <ion-icon class="statusDot" [name]="i === pageID ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
      <div class="navBarTitle-wrapper">
        <ion-icon *ngIf="step.pathToIcon" [name]="step.pathToIcon" class="section-icon"></ion-icon>
        <ion-icon *ngIf="step.isInvalid" class="invalidSectionIcon" name="alberta-invalid-section"></ion-icon>
      </div>

      <div class="section_text">
        {{ step.nameTransPath | translate }}
      </div>
    </div>
  </div>
</div>
