import { Injectable, NgZone } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import makeDebug from '../../../../makeDebug';
import { AuthService } from '../../services/auth.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { RootHistorySetter as History } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:root-history-setter');

@Injectable({ providedIn: 'root' })
export class RootHistorySetter implements ISyncElement {
  public get name(): string {
    return History;
  }

  constructor(
    private _navController: NavController,
    private _authService: AuthService,
    private _ngZone: NgZone,
    private _platform: Platform
  ) {}

  public async execute(context: ISyncContext): Promise<void> {
    debug('entered RootHistorySetter execute function');

    if (this._platform.is('cordova') || window.location.pathname.includes('dashboard')) {
      await this._ngZone.run(() => this._navController.navigateRoot(this._authService.redirectUrl || '/dashboard'));
      context.executeOnlyOnce.set(this.name, true);
    }
  }
}
