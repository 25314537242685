import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'itl-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() title: string;
  @Input() rightSide = true;

  public value = '';
  private onChange = (_text: string) => {};
  private onTouched = () => {};

  constructor(@Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public updateChanges() {
    this.onChange(this.value);
  }

  // ControlValueAccessor interface
  public writeValue(obj: any) {
    this.value = obj;
    this.updateChanges();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
