import { IPatientNote } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { PatientNoteWorkItem } from 'src/app/business/patientNotes/patient-notes-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class PatientNotesCommandHandler extends EntityCommandHandler<IPatientNote> {
  protected workItem: IWorkItem<IPatientNote>;

  constructor(notesWorkItem: PatientNoteWorkItem) {
    super();
    this.workItem = notesWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'PatientNoteDto';
  }
}
