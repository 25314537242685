import { Injectable } from '@angular/core';
import { ISingleOrder } from '@pia/pia.shared';
import { BaseStateExtension } from 'src/app/common/state/state-extension';
import { SingleOrderModelName } from 'src/app/shared/models/model-names';
import { SingleOrderViewModel } from './single-order-view-model';

@Injectable({ providedIn: 'root' })
export class SingleOrderStateExtension extends BaseStateExtension<ISingleOrder> {
  public get name(): string {
    return SingleOrderModelName;
  }

  // eslint-disable-next-line require-await
  async canBeStored(_item: ISingleOrder): Promise<boolean> {
    return true;
  }

  // eslint-disable-next-line
  protected afterCreateInternal(_item: ISingleOrder): void {}

  protected async afterUpdateInternal(item: ISingleOrder): Promise<void> {
    if (
      this.stateRegistry.get<ISingleOrder, SingleOrderViewModel>(SingleOrderModelName, 'selectedItem')?._id ===
      item?._id
    ) {
      await this.stateRegistry.update(SingleOrderModelName, 'selectedItem', item);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected removeFromState(_item: ISingleOrder): void {}
}
