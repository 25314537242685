import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'itl-chat-empty-state',
  templateUrl: './chat-empty-state.component.html',
  styleUrls: ['./chat-empty-state.component.scss'],
})
export class ChatEmptyStateComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonText: string | null;
  @Input() icon: string | null;
  @Output() ctaClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  public handleClick() {
    this.ctaClick.emit();
  }
}
