import { inject, ModuleWithProviders, NgModule, NgZone } from '@angular/core';
import { IMaintenance } from '@pia/pia.shared';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { MaintenanceService } from '../maintenance/maintenance.service';
import { AppointmentSyncService } from './appointment-sync.service';
import { ArticleSyncService } from './article-sync.service';
import { AttachmentSyncService } from './attachment-sync.service';
import { AuditSyncService } from './audit-sync.service';
import { ConfigSyncService } from './config-sync.service';
import { DeviceSyncService } from './device-sync.service';
import { DoctorSyncService } from './doctor-sync.service';
import { ErpOrderSyncService } from './erp-order-sync.service';
import { ErpTaskSyncService } from './erp-task-sync.service';
import { FaxSyncService } from './fax-sync.service';
import { GeneralSyncService } from './general-sync.service';
import { GroupSyncService } from './group-sync.service';
import { HospitalSyncService } from './hosptial-sync.service';
import { InstitutionAuditSyncService } from './institution-audit-sync.service';
import { InstitutionContactSyncService } from './institution-contact-sync.service';
import { InstitutionNoteSyncService } from './institution-note-sync.service';
import { InsuranceContractSyncService } from './insurance-contract-sync.service';
import { IntegratedCareSyncService } from './integrated-care-sync.service';
import { IntermediarySyncService } from './intermediary-sync.service';
import { LoggingSyncService } from './logging-sync.service';
import { MaintenanceSyncService } from './maintenance-sync.service';
import { NursingHomeSyncService } from './nursing-home.service';
import { NursingServiceSyncService } from './nursing-service-sync.service';
import { PatientAppUserSyncService } from './patient-app-user-sync.service';
import { PatientSyncService } from './patient-sync.service';
import { PatientNoteSyncService } from './patientNotes-sync.service';
import { PharmacySyncService } from './pharmacy-sync.service';
import { ProductGroupSyncService } from './product-group-sync.service';
import { RegionSyncService } from './region-sync.service';
import { ReminderSyncService } from './reminder-sync.service';
import { SingleOrderSyncService } from './single-order-sync.service';
import { StandardCareProposalSyncService } from './standard-care-proposal-sync.service';
import { SubunitSyncService } from './subunit-sync.service';
import { SyncService } from './sync.service';
import { UsersSyncService } from './users-sync.service';
import { WebAttachmentSyncService } from './web-attachment-sync.service';
import { WebSyncService } from './web-sync.service';
import { WebUserSyncService } from './web-user-sync.service';

let maintenanceSyncService: MaintenanceSyncService = null;

export function maintenanceSyncServiceFactory(
  appController: IAppController,
  dispatcher: IDispatcher<IMaintenance>,
  eventService: EventService<SyncProgressEvent>,
  maintenanceService: IMaintenanceService,
  ngZone: NgZone
) {
  if (maintenanceSyncService) {
    return maintenanceSyncService;
  }

  maintenanceSyncService = new MaintenanceSyncService(
    appController,
    dispatcher,
    eventService,
    maintenanceService,
    ngZone,
    inject<IPlatformSync>(PlatformSyncToken)
  );

  return maintenanceSyncService;
}
@NgModule({
  providers: [],
})
export class SyncModule {
  static forChild(): ModuleWithProviders<SyncModule> {
    return { ngModule: SyncModule, providers: [] };
  }

  static forRoot(): ModuleWithProviders<SyncModule> {
    return {
      ngModule: SyncModule,
      providers: [
        { provide: SyncService, useClass: ArticleSyncService, multi: true },
        { provide: SyncService, useClass: PatientSyncService, multi: true },
        { provide: SyncService, useClass: AuditSyncService, multi: true },
        { provide: SyncService, useClass: GeneralSyncService, multi: true },
        { provide: SyncService, useClass: UsersSyncService, multi: true },
        { provide: SyncService, useClass: GroupSyncService, multi: true },
        { provide: SyncService, useClass: RegionSyncService, multi: true },
        { provide: SyncService, useClass: ProductGroupSyncService, multi: true },
        { provide: SyncService, useClass: StandardCareProposalSyncService, multi: true },
        { provide: SyncService, useClass: InsuranceContractSyncService, multi: true },
        { provide: SyncService, useClass: SingleOrderSyncService, multi: true },
        { provide: SyncService, useClass: PatientNoteSyncService, multi: true },
        { provide: SyncService, useClass: FaxSyncService, multi: true },
        { provide: SyncService, useClass: LoggingSyncService, multi: true },
        { provide: SyncService, useClass: ErpOrderSyncService, multi: true },
        { provide: SyncService, useClass: DeviceSyncService, multi: true },
        { provide: SyncService, useClass: AttachmentSyncService, multi: true },
        { provide: SyncService, useClass: DoctorSyncService, multi: true },
        { provide: SyncService, useClass: NursingHomeSyncService, multi: true },
        { provide: SyncService, useClass: HospitalSyncService, multi: true },
        { provide: SyncService, useClass: PharmacySyncService, multi: true },
        { provide: SyncService, useClass: NursingServiceSyncService, multi: true },
        {
          provide: SyncService,
          useFactory: maintenanceSyncServiceFactory,
          deps: [AppController, Dispatcher, EventService, MaintenanceService, NgZone],
          multi: true,
        },
        { provide: SyncService, useClass: SubunitSyncService, multi: true },
        { provide: SyncService, useClass: InstitutionContactSyncService, multi: true },
        {
          provide: MaintenanceSyncService,
          useFactory: maintenanceSyncServiceFactory,
          deps: [AppController, Dispatcher, EventService, MaintenanceService, NgZone],
        },
        { provide: SyncService, useClass: InstitutionNoteSyncService, multi: true },
        { provide: SyncService, useClass: InstitutionAuditSyncService, multi: true },
        { provide: SyncService, useClass: ConfigSyncService, multi: true },
        { provide: SyncService, useClass: AppointmentSyncService, multi: true },
        { provide: SyncService, useClass: PatientAppUserSyncService, multi: true },
        { provide: SyncService, useClass: ErpTaskSyncService, multi: true },
        { provide: SyncService, useClass: IntegratedCareSyncService, multi: true },
        { provide: SyncService, useClass: IntermediarySyncService, multi: true },
        { provide: SyncService, useClass: ReminderSyncService, multi: true },
        { provide: WebSyncService, useClass: WebAttachmentSyncService, multi: true },
        { provide: WebSyncService, useClass: WebUserSyncService, multi: true },
      ],
    };
  }
}
