import { Injectable } from '@angular/core';
import { AttachmentWorkItem } from 'src/app/business/attachment/attachment-work-item';
import { Deferred } from 'src/app/common/deferred/deferred';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';

import { IGenericStorage } from '../contracts/database/generic-storage';
import { DatabaseService } from '../database.service';
import { BlobFileService } from './blob-file.service';
import { Database } from './Database';

export type EnhancedAttachmentDto = Partial<AttachmentDto> & { mime?: string };

@Injectable({ providedIn: 'root' })
export class AttachmentDatabaseService {
  public readonly LOCAL_PREFIX = 'local|';

  private _blobDb: BlobFileService;
  private _attachmentDb: IGenericStorage;
  private _attachmentsToSaveDb: BlobFileService;
  private _attachmentsToUpdateDb: IGenericStorage;
  private _attachmentsToRemoveDb: IGenericStorage;
  private _dbReady = new Deferred<void>();

  constructor(
    private _databaseService: DatabaseService,
    private _attachmentWorkItem: AttachmentWorkItem,
    private _blobFileService: BlobFileService
  ) {
    this.init();
  }

  private async init() {
    this._blobDb = await this._blobFileService.create('attachmentChunks', Database.Blob, this._databaseService);
    this._attachmentsToSaveDb = await this._blobFileService.create(
      'attachmentsToSave',
      Database.AttachmentToSave,
      this._databaseService
    );
    this._attachmentDb = await this._databaseService.getDatabase('attachment.db');
    this._attachmentsToUpdateDb = await this._databaseService.getDatabase('attachmentsToUpdate');
    this._attachmentsToRemoveDb = await this._databaseService.getDatabase('attachmentsToRemove');
    this._dbReady.resolve();
  }

  public async get(database: Database, id: string): Promise<any> {
    await this._dbReady.promise;
    switch (database) {
      case Database.Blob:
        return this._blobDb.get(id);
      case Database.Attachment:
        return this._attachmentDb.get(id);
      case Database.AttachmentToSave:
        return this._attachmentsToSaveDb.get(id);
      case Database.AttachmentToUpdate:
        return this._attachmentsToUpdateDb.get(id);
      case Database.AttachmentToRemove:
        return this._attachmentsToRemoveDb.get(id);
    }
  }

  public async set(database: Database, id: string, data: any): Promise<any> {
    await this._dbReady.promise;
    switch (database) {
      case Database.Blob:
        return this._blobDb.set(id, data);
      case Database.Attachment:
        return this._attachmentDb.set(id, data);
      case Database.AttachmentToSave:
        return this._attachmentsToSaveDb.set(id, data);
      case Database.AttachmentToUpdate:
        return this._attachmentsToUpdateDb.set(id, data);
      case Database.AttachmentToRemove:
        return this._attachmentsToRemoveDb.set(id, data);
    }
  }

  public async remove(database: Database, id: string): Promise<any> {
    await this._dbReady.promise;
    switch (database) {
      case Database.Blob:
        return this._blobDb.remove(id);
      case Database.Attachment:
        return this._attachmentDb.remove(id);
      case Database.AttachmentToSave:
        return this._attachmentsToSaveDb.remove(id);
      case Database.AttachmentToUpdate:
        return this._attachmentsToUpdateDb.remove(id);
      case Database.AttachmentToRemove:
        return this._attachmentsToRemoveDb.remove(id);
    }
  }

  public async keys(database: Database): Promise<string[]> {
    await this._dbReady.promise;
    switch (database) {
      case Database.Blob:
        return this._blobDb.keys();
      case Database.Attachment:
        return this._attachmentDb.keys();
      case Database.AttachmentToSave:
        return this._attachmentsToSaveDb.keys();
      case Database.AttachmentToUpdate:
        return this._attachmentsToUpdateDb.keys();
      case Database.AttachmentToRemove:
        return this._attachmentsToRemoveDb.keys();
    }
  }

  public async ready(database: Database): Promise<boolean> {
    await this._dbReady.promise;
    switch (database) {
      case Database.Blob:
        return Boolean(this._blobDb.ready());
      case Database.Attachment:
        return Boolean(this._attachmentDb.ready());
      case Database.AttachmentToSave:
        return Boolean(this._attachmentsToSaveDb.ready());
      case Database.AttachmentToUpdate:
        return Boolean(this._attachmentsToUpdateDb.ready());
      case Database.AttachmentToRemove:
        return Boolean(this._attachmentsToRemoveDb.ready());
    }
  }

  public async getLocalAttachments(): Promise<string[]> {
    await this._dbReady.promise;
    await this._attachmentDb.ready();
    const keys = await this._attachmentDb.keys();
    return keys.filter(key => key.includes(this.LOCAL_PREFIX));
  }

  public writeAttachmentToStorage(data: EnhancedAttachmentDto) {
    return this._attachmentWorkItem.create(data as AttachmentDto);
  }

  public async writeBlobToStorage(id: string, data: Blob): Promise<void> {
    await this._dbReady.promise;
    await this._blobDb.set(id, data);
  }

  public async replaceLocalData(localId: string, newId: string, newData: EnhancedAttachmentDto): Promise<void> {
    await this.replaceLocalBlob(localId, newId);
    await this.replaceLocalAttachment(localId, newData);
  }

  public async replaceLocalUpdate(localId: string, newId: string): Promise<void> {
    const localUpdate = await this.get(Database.AttachmentToUpdate, localId);
    if (!localUpdate) {
      return;
    }

    await this.remove(Database.AttachmentToUpdate, localId);

    localUpdate._id = newId;
    await this.set(Database.AttachmentToUpdate, newId, localUpdate);
  }

  private async replaceLocalBlob(localId: string, newId: string): Promise<void> {
    await this._dbReady.promise;
    const imageData = await this._blobDb.get(localId);
    await this._blobDb.set(newId, imageData);
    await this._blobDb.remove(localId);
  }

  private async replaceLocalAttachment(localId: string, newData: EnhancedAttachmentDto): Promise<void> {
    await this._dbReady.promise;
    await this._attachmentWorkItem.create(newData as any);
    await this._attachmentDb.remove(localId);
  }

  public async getAttachmentDto(id: string): Promise<AttachmentDto> {
    await this._dbReady.promise;
    return this._attachmentDb.get(id);
  }

  public async isAttachmentBlobAvailable(id: string): Promise<boolean> {
    await this._dbReady.promise;
    return (await this._blobDb.keys()).includes(id);
  }

  public async purge(id: string): Promise<void> {
    await this._blobDb.remove(id);
    await this._attachmentDb.remove(id);
    await this._attachmentsToSaveDb.remove(id);
  }
}
