import { Inject, Injectable } from '@angular/core';
import { UsersDB } from 'src/app/common/repository/databases';

import { UserDto } from '../models/user-management/user-dto.model';
import { IQueryService } from './contracts/query/query-service';
import { QueryService } from './query/query.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(@Inject(QueryService) private _queryService: IQueryService) {}

  public getAll(): Promise<UserDto[]> {
    return this._queryService.getAll<UserDto>(UsersDB);
  }

  public find(id: string): Promise<UserDto> {
    return this._queryService.get<UserDto>(id, UsersDB);
  }

  public query(query: string): Promise<UserDto[]> {
    return this._queryService.search<UserDto>({ query }, UsersDB);
  }
}
