import { Injectable } from '@angular/core';

import { BodyPart } from '../models/body-part.model';

@Injectable({
  providedIn: 'root',
})
export class BodyPartService {
  private _bodyParts: BodyPart[] = [
    new BodyPart(1, 'rechts', '', 'kleine Zehe'),
    new BodyPart(2, 'rechts', '', '4. Zehe'),
    new BodyPart(3, 'rechts', '', '3. Zehe'),
    new BodyPart(4, 'rechts', '', '2. Zehe'),
    new BodyPart(5, 'rechts', '', 'Großzehe'),
    new BodyPart(6, 'links', '', 'kleine Zehe'),
    new BodyPart(7, 'links', '', '4. Zehe'),
    new BodyPart(8, 'links', '', '3. Zehe'),
    new BodyPart(9, 'links', '', '2. Zehe'),
    new BodyPart(10, 'links', '', 'Großzehe'),
    new BodyPart(11, 'rechts', 'unten', 'vorderer Fußballen'),
    new BodyPart(12, 'rechts', 'unten', 'Fußsohle'),
    new BodyPart(13, 'rechts', 'unten', 'Ferse'),
    new BodyPart(14, 'links', 'unten', 'vorderer Fußballen'),
    new BodyPart(15, 'links', 'unten', 'Fußsohle'),
    new BodyPart(16, 'links', 'unten', 'Ferse'),
    new BodyPart(17, 'rechts', 'oben', 'Vorderfuß'),
    new BodyPart(18, 'rechts', 'oben', 'Fußspann'),
    new BodyPart(19, 'links', 'oben', 'Vorderfuß'),
    new BodyPart(20, 'links', 'oben', 'Fußspann'),
    new BodyPart(21, 'rechts', 'außen', 'Knöchel'),
    new BodyPart(22, 'rechts', 'innen', 'Knöchel'),
    new BodyPart(23, 'rechts', 'außen', 'Unterschenkel'),
    new BodyPart(24, 'rechts', 'innen', 'Unterschenkel'),
    new BodyPart(25, 'rechts', 'außen', 'Oberschenkel'),
    new BodyPart(26, 'rechts', 'innen', 'Oberschenkel'),
    new BodyPart(27, 'rechts', '', 'Trochanter'),
    new BodyPart(28, 'links', 'außen', 'Knöchel'),
    new BodyPart(29, 'links', 'innen', 'Knöchel'),
    new BodyPart(30, 'links', 'außen', 'Unterschenkel'),
    new BodyPart(31, 'links', 'innen', 'Unterschenkel'),
    new BodyPart(32, 'links', 'außen', 'Oberschenkel'),
    new BodyPart(33, 'links', 'innen', 'Oberschenkel'),
    new BodyPart(34, 'links', '', 'Trochanter'),
    new BodyPart(35, 'rechts', '', 'Gesäß'),
    new BodyPart(36, 'links', '', 'Gesäß'),
    new BodyPart(37, '', '', 'Kreuzbeinregion'),
    new BodyPart(38, '', '', 'Sakralregion'),
    new BodyPart(39, 'rechts', '', 'unterer Rücken'),
    new BodyPart(40, 'links', '', 'unterer Rücken'),
    new BodyPart(41, '', '', 'Wirbelsäule'),
    new BodyPart(42, 'rechts', '', 'Schulterblatt/Schulter'),
    new BodyPart(43, 'links', '', 'Schulterblatt/Schulter'),
    new BodyPart(44, 'rechts', 'außen', 'Oberarm'),
    new BodyPart(45, 'rechts', '', 'Ellenbogen'),
    new BodyPart(46, 'rechts', 'außen', 'Unterarm'),
    new BodyPart(47, 'rechts', '', 'Handrücken'),
    new BodyPart(48, 'links', 'außen', 'Oberarm'),
    new BodyPart(49, 'links', '', 'Ellenbogen'),
    new BodyPart(50, 'links', 'außen', 'Unterarm'),
    new BodyPart(51, 'links', '', 'Handrücken'),
    new BodyPart(52, '', '', 'Nacken'),
    new BodyPart(53, '', 'hinten', 'Kopf'),
    new BodyPart(54, '', '', 'Genitalien/Leiste'),
    new BodyPart(55, '', '', 'Unterbauch'),
    new BodyPart(56, '', '', 'Oberbauch'),
    new BodyPart(57, 'rechts', '', 'Thorax'),
    new BodyPart(58, 'links', '', 'Thorax'),
    new BodyPart(59, 'rechts', 'innen', 'Oberarm'),
    new BodyPart(60, 'rechts', 'innen', 'Unterarm'),
    new BodyPart(61, 'rechts', '', 'Handinnenfläche'),
    new BodyPart(62, 'links', 'innen', 'Oberarm'),
    new BodyPart(63, 'links', 'innen', 'Unterarm'),
    new BodyPart(64, 'links', '', 'Handinnenfläche'),
    new BodyPart(65, '', '', 'Hals'),
    new BodyPart(66, 'rechts', '', 'Ohr'),
    new BodyPart(67, 'links', '', 'Ohr'),
    new BodyPart(68, '', 'vorne', 'Kopf'),
    new BodyPart(69, 'innen', 'rechts', 'Knie'),
    new BodyPart(70, 'mitte', 'rechts', 'Knie'),
    new BodyPart(71, 'außen', 'rechts', 'Knie'),
    new BodyPart(72, 'innen', 'links', 'Knie'),
    new BodyPart(73, 'mitte', 'links', 'Knie'),
    new BodyPart(74, 'außen', 'links', 'Knie'),
    new BodyPart(75, 'rechts', '', 'Auge'),
    new BodyPart(76, 'links', '', 'Auge'),
    new BodyPart(77, '', '', 'Mund'),
    new BodyPart(78, '', '', 'Nase'),
  ];

  public get bodyParts() {
    return this._bodyParts;
  }

  constructor() {}

  public getFullBodyPartName(bodyPartId: number) {
    const bodyPart = this.bodyParts.find(f => f.areaId === bodyPartId);
    if (!bodyPart) {
      return;
    }
    return ` ${bodyPart.part} ${bodyPart.half ? bodyPart.half : ''} ${bodyPart.view ? bodyPart.view : ''}`.trim();
  }
}
