import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { ChatChannel } from 'src/app/shared/services/chat/data/db-schema';

import { calendarConfig } from '../../../../chat/chat-calendar-config';

@Pipe({ name: 'lastChannelActivity' })
export class LastChannelActivityPipe implements PipeTransform {
  transform(channel: ChatChannel): string {
    if (!channel) {
      return '';
    }
    return moment(channel.lastMessageTimestamp).calendar(null, calendarConfig);
  }
}
