import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelected',
  pure: false,
})
export class IsSelectedPipe implements PipeTransform {
  constructor() {}
  transform(item: any, values: any[]) {
    const ret = values.find(value => item._id === value._id);
    return ret !== undefined ? true : false;
  }
}
