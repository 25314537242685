import { ISubunit } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { InstitutionContactChannel } from 'src/app/business/institution/contracts/institution-contact.channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { InstitutionContactModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class InstitutionContactSyncService extends SyncService<ISubunit> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<ISubunit>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(InstitutionContactModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public canSync(channel: string): boolean {
    return (
      channel === InstitutionContactChannel.CREATE ||
      channel === InstitutionContactChannel.EDIT ||
      channel === InstitutionContactChannel.DELETE
    );
  }
}
