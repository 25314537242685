import { ErpTaskStatus, IAudit, IErpTask } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { ErpTaskComponentModel } from 'src/app/business/erpTask/erp-task-component-model';
import { ErpTaskViewModel } from 'src/app/business/erpTask/erp-task-view-model';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { map } from 'src/app/common/mapper/mapper';
import { ErpTaskDB } from 'src/app/common/repository/databases';
import { observe } from 'src/app/common/tracking';
import { AuditComponentModel } from 'src/app/swodoc/_models/audit-component-model';
import { AuditModelName } from '../../models/model-names';
import { AuditDto } from '../../models/swodoc/audit-dto.model';
import { QueryService } from '../query/query.service';

@Injectable({ providedIn: 'root' })
export class ErpTaskStatusService {
  constructor(
    private _auditComponentModel: AuditComponentModel,
    private _erpTaskComponentModel: ErpTaskComponentModel,
    private _queryService: QueryService,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<AuditDto>
  ) {}

  public init() {
    this._auditComponentModel.select<IAudit[]>('completed').subscribe(async audits => {
      if (!audits || !audits.length) {
        return;
      }

      // suche alle erpTask mit der audit._id
      const erpTasks = await this._queryService.search<IErpTask>(
        { query: audits.map(audit => `patientId:${audit.patientId}`).join() },
        ErpTaskDB,
        {
          isIn: true,
        }
      );
      // setze status der erpTask auf "erledigt"
      const erpTasksAssociatedToAudits = erpTasks.filter(erpTask =>
        audits.some(audit => audit._id === erpTask.entityId)
      );

      for (const erpTask of erpTasksAssociatedToAudits) {
        const erpTaskViewModel: ErpTaskViewModel = observe(map(erpTask, new ErpTaskViewModel()));
        erpTaskViewModel.status = erpTask.status = ErpTaskStatus.AnswerReceived;
        erpTaskViewModel.changedInAlberta = erpTask.changedInAlberta = true;
        await this._erpTaskComponentModel.edit(erpTaskViewModel, erpTask);
      }

      // lösche alle completed audits im state
      await this._dispatcher.removeAllFromState(AuditModelName, 'completed');
    });
  }
}
