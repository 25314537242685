import { Inject, Injectable } from '@angular/core';
import { CancellationTokenHandler } from 'src/app/common/cancellation/cancellationtoken-handler';
import { ICancellationTokenHandler } from 'src/app/common/contracts/cancellation/cancellationtoken-handler';

import makeDebug from '../../../../makeDebug';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { TokenHandler as Token } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:token-handler');

@Injectable()
export class TokenHandler implements ISyncElement {
  public get name(): string {
    return Token;
  }

  constructor(@Inject(CancellationTokenHandler) private _cancellationTokenHandler: ICancellationTokenHandler) {}

  public async execute(context: ISyncContext): Promise<void> {
    debug('configuring queue token');
    await this.configureQueueToken(context);
    debug('configuring app/login token');
    await this.configureAppLoginToken(context);
    debug('configuring maintenance token');
    await this.configureMaintenanceToken(context);
  }

  private async configureMaintenanceToken(context: ISyncContext) {
    const token = this._cancellationTokenHandler.getToken('maintenance');
    await token.cancel();
    context.params.maintenanceToken = this._cancellationTokenHandler.setupToken('maintenance');
  }

  private async configureAppLoginToken(context: ISyncContext) {
    const token = this._cancellationTokenHandler.getToken(context.params.isLogin ? 'login' : 'app');
    await token.cancel();
    context.params.token = this._cancellationTokenHandler.setupToken(context.params.isLogin ? 'login' : 'app');
  }

  private async configureQueueToken(context: ISyncContext) {
    const token = this._cancellationTokenHandler.getToken('commandQueue');
    await token.cancel();
    context.params.queueToken = this._cancellationTokenHandler.setupToken('commandQueue');
  }
}
