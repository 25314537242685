import { InjectionToken } from '@angular/core';
import { get } from 'lodash';

import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AttributeTemplateDB,
  AuditDB,
  ContractArticleGroupDB,
  DeviceDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  IntermediaryDB,
  NursingHomeDB,
  NursingServiceDB,
  PatientDB,
  PatientNotesDB,
  PayerDB,
  PayerInfoDB,
  PharmacyDB,
  PostalCodeDB,
  ProductGroupDB,
  RegionDB,
  ReminderDB,
  RightsetDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  TemplateDB,
  UsersDB,
} from './databases';

function hyphensExtractor(doc, idField) {
  const field = get(doc, idField);
  if (field == null || field === '' || typeof field !== 'string') {
    return undefined;
  }

  return field.replace(/[^a-z0-9]/gi, '');
}

const patientPlugin: DBPlugin = {
  fields: [
    '_id',
    'lastName',
    'firstName',
    'customerId',
    'nursingHomeId',
    'nursingServiceId',
    'fieldNurseId',
    'city',
    'postalCode',
    'additionalAddress',
    'birthday',
    'nursingHome.subunitId',
    'additionalUserIds',
    'email',
  ],
  // TODO @albert kann das weg?
  modifier: { birthday: item => hyphensExtractor(item, 'birthday') },
};

const doctorPlugin: DBPlugin = {
  fields: ['lastName', 'firstName', 'city', 'postalCode', 'institution.description'],
};

const hospitalPlugin: DBPlugin = {
  fields: ['company', 'name', 'city', 'postalCode'],
};

const nursingHomePlugin: DBPlugin = {
  fields: ['name', 'city', 'postalCode'],
};

const nursingServicePlugin: DBPlugin = {
  fields: ['name', 'city', 'postalCode'],
};

const payerPlugin: DBPlugin = {
  fields: ['_id', 'name', 'ikNumber'],
};

const payerInfoPlugin: DBPlugin = {
  fields: ['payerId', 'header'],
};

const postalCodePlugin: DBPlugin = {
  fields: ['_id', 'city', 'postalCode'],
};

const appointmentPlugin: DBPlugin = {
  fields: ['patientIds', 'nursingHomeId', 'userId', 'title'],
};

const usersPlugin: DBPlugin = {
  fields: ['firstName', 'lastName', 'email'],
};

const groupPlugin: DBPlugin = {
  fields: ['_id', 'label'],
};

const regionPlugin: DBPlugin = {
  fields: ['_id', 'parent', 'label'],
};

const rightsetPlugin: DBPlugin = {
  fields: ['_id', 'description'],
};

const productGroupPlugin: DBPlugin = {
  fields: ['_id', 'number', 'name', 'description', 'parentId'],
};

const articlePlugin: DBPlugin = {
  fields: [
    '_id',
    { name: 'name', searchable: true },
    { name: 'pcn', searchable: true },
    { name: 'customerNumber', searchable: true },
    { name: 'manufacturerNumber', searchable: true },
    'productGroupId',
    { name: 'manufacturer', searchable: true },
    { name: 'additionalDescription', searchable: true },
    { name: 'description', searchable: true },
  ],
};

const standardCareProposalPlugin: DBPlugin = {
  fields: ['name'],
};

const insuranceContractPlugin: DBPlugin = {
  fields: ['_id', 'name', 'therapyId'],
};

const pharmacyPlugin: DBPlugin = {
  fields: ['name', 'city', 'postalCode'],
};

const integratedCarePlugin: DBPlugin = {
  fields: ['patientId'],
};

const erpTaskPlugin: DBPlugin = {
  fields: ['patientId', 'entityId', 'assigneeId', 'status', 'therapyId', 'therapyTypeId', 'archived'],
};

const singleOrderPlugin: DBPlugin = {
  fields: ['patientId', 'integratedCareId'],
};

const auditPlugin: DBPlugin = {
  fields: ['patientId'],
};

const faxPlugin: DBPlugin = {
  fields: ['title', 'createdBy'],
};

const patientNotesPlugin: DBPlugin = {
  fields: ['patientId'],
};

const erpOrderPlugin: DBPlugin = {
  fields: ['salesId', 'patientId'],
};

const devicePlugin: DBPlugin = {
  fields: ['articleId', 'patientId'],
};

const attributeTemplatePlugin: DBPlugin = {
  fields: ['name'],
};

const subunitPlugin: DBPlugin = {
  fields: ['institutionId'],
};

const institutionContactPlugin: DBPlugin = {
  fields: ['institutionId'],
};

const institutionNotePlugin: DBPlugin = {
  fields: ['institutionId'],
};

const institutionAuditPlugin: DBPlugin = {
  fields: ['institutionId'],
};

const attachmentPlugin: DBPlugin = {
  fields: [
    'metadata.patientId',
    'metadata.auditNodeId',
    'metadata.templateNodeId',
    'metadata.auditId',
    'metadata.templateId',
  ],
};

const contractArticleGroupPlugin: DBPlugin = {
  fields: ['_id', 'name', 'customerNumber'],
};

const templatePlugin: DBPlugin = {
  fields: ['templateTag'],
};

const intermediaryPlugin: DBPlugin = {
  fields: ['name', 'city', 'postalCode', 'role'],
};

const reminderPlugin: DBPlugin = {
  fields: ['patientId', 'entityId', 'date', 'userId'],
};

export interface DBPlugin {
  fields: (string | { name: string; searchable: boolean })[];
  modifier?: Record<string, (item: Record<string, unknown>) => string>;
}

export const PluginsToken = new InjectionToken('Plugins', { providedIn: 'root', factory: () => DB_PLUGINS });

const DB_PLUGINS: Record<string, DBPlugin> = {};

DB_PLUGINS[PatientDB] = patientPlugin;
DB_PLUGINS[NursingHomeDB] = nursingHomePlugin;
DB_PLUGINS[NursingServiceDB] = nursingServicePlugin;
DB_PLUGINS[HospitalDB] = hospitalPlugin;
DB_PLUGINS[InsuranceContractDB] = insuranceContractPlugin;
DB_PLUGINS[DoctorDB] = doctorPlugin;
DB_PLUGINS[PayerDB] = payerPlugin;
DB_PLUGINS[PayerInfoDB] = payerInfoPlugin;
DB_PLUGINS[PostalCodeDB] = postalCodePlugin;
DB_PLUGINS[UsersDB] = usersPlugin;
DB_PLUGINS[GroupDB] = groupPlugin;
DB_PLUGINS[RegionDB] = regionPlugin;
DB_PLUGINS[RightsetDB] = rightsetPlugin;
DB_PLUGINS[StandardCareProposalDB] = standardCareProposalPlugin;
DB_PLUGINS[IntegratedCareDB] = integratedCarePlugin;
DB_PLUGINS[SingleOrderDB] = singleOrderPlugin;
DB_PLUGINS[ProductGroupDB] = productGroupPlugin;
DB_PLUGINS[ArticleDB] = articlePlugin;
DB_PLUGINS[PharmacyDB] = pharmacyPlugin;
DB_PLUGINS[AuditDB] = auditPlugin;
DB_PLUGINS[PatientNotesDB] = patientNotesPlugin;
DB_PLUGINS[FaxDB] = faxPlugin;
DB_PLUGINS[ErpOrderDB] = erpOrderPlugin;
DB_PLUGINS[DeviceDB] = devicePlugin;
DB_PLUGINS[AttachmentDB] = attachmentPlugin;
DB_PLUGINS[AttributeTemplateDB] = attributeTemplatePlugin;
DB_PLUGINS[SubunitDB] = subunitPlugin;
DB_PLUGINS[InstitutionContactDB] = institutionContactPlugin;
DB_PLUGINS[InstitutionNoteDB] = institutionNotePlugin;
DB_PLUGINS[InstitutionAuditDB] = institutionAuditPlugin;
DB_PLUGINS[AppointmentDB] = appointmentPlugin;
DB_PLUGINS[TemplateDB] = templatePlugin;
DB_PLUGINS[ErpTaskDB] = erpTaskPlugin;
DB_PLUGINS[ContractArticleGroupDB] = contractArticleGroupPlugin;
DB_PLUGINS[IntermediaryDB] = intermediaryPlugin;
DB_PLUGINS[ReminderDB] = reminderPlugin;

export default DB_PLUGINS;
