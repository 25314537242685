import { IFax } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { FaxWorkItem } from 'src/app/business/fax/fax-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class FaxCommandHandler extends EntityCommandHandler<IFax> {
  protected workItem: IWorkItem<IFax>;

  constructor(auditWorkItem: FaxWorkItem) {
    super();
    this.workItem = auditWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'FaxDto';
  }
}
