import { Inject, Injectable, NgZone } from '@angular/core';
import {
  Client as TwilioClient,
  Conversation,
  ConversationUpdatedEventArgs,
  ConversationUpdateReason,
  Message,
  Participant,
} from '@twilio/conversations';
import makeDebug from 'src/makeDebug';

import { ChatConnectionStateService } from '../chat-connection-state.service';
import { ChatEventService } from '../chat-event.service';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twilio.factory';

const debug = makeDebug('services:chat:twilioSync');

@Injectable({ providedIn: 'root' })
export class TwilioChatEventSourceService {
  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioClient>,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioClient>,
    private readonly _twilioToChatDtoHelpers: TwilioToChatDtoHelpersService,
    private readonly _chatEventService: ChatEventService,
    private readonly _chatConnectionStateService: ChatConnectionStateService,
    private readonly _ngZone: NgZone
  ) {
    this.initialize();
  }

  private initialize() {
    debug('init');
    this._ngZone.runOutsideAngular(() => {
      const timeout = setTimeout(async () => {
        clearTimeout(timeout);
        await this.setupChannelEvents();
        await this.setupMessageEvents();
        await this.setupTokenEvents();
        await this.setupConnectionStateEvents();
      });
    });
  }

  private async setupChannelEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup channel events');
    twilioClient.on('conversationAdded', channel => this.addConversation(channel));
    twilioClient.on('conversationUpdated', ({ conversation, updateReasons }: ConversationUpdatedEventArgs) =>
      this.updateConversation(conversation, updateReasons)
    );
    twilioClient.on('conversationRemoved', (conversation: Conversation) => this.removeConversation(conversation));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }

    debug('setup agent channel events');

    twilioAgentClient.on('conversationAdded', channel => this.addConversation(channel, Chat.PatientApp));
    twilioAgentClient.on('conversationUpdated', ({ conversation, updateReasons }: ConversationUpdatedEventArgs) =>
      this.updateConversation(conversation, updateReasons, Chat.PatientApp)
    );
    twilioAgentClient.on('conversationRemoved', (conversation: Conversation) => this.removeConversation(conversation));
  }

  private async setupMessageEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup message events');
    twilioClient.on('messageAdded', (message: Message) => this.addMessage(message));
    twilioClient.on('messageRemoved', (message: Message) => this.removeMessage(message));
    twilioClient.on('participantJoined', (member: Participant) => this.addParticipantToConversation(member));
    twilioClient.on('participantLeft', (member: Participant) => this.removeParticipantFromConversation(member));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    debug('setup message events for agents');
    twilioAgentClient.on('messageAdded', (message: Message) => this.addMessage(message, Chat.PatientApp));
    twilioAgentClient.on('messageRemoved', (message: Message) => this.removeMessage(message));
    twilioAgentClient.on('participantJoined', (member: Participant) => this.addParticipantToConversation(member));
    twilioAgentClient.on('participantLeft', (member: Participant) => this.removeParticipantFromConversation(member));
  }

  private async setupTokenEvents() {
    const twilioClient = await this._twilioChatClient;

    debug('setup token events');
    twilioClient.on('tokenAboutToExpire', () => this.reactToTokenAboutToExpire(Chat.Alberta));
    twilioClient.on('tokenExpired', () => this.reactToTokenExpired(Chat.PatientApp));

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    debug('setup token events');
    twilioAgentClient.on('tokenAboutToExpire', () => this.reactToTokenAboutToExpire(Chat.Alberta));
    twilioAgentClient.on('tokenExpired', () => this.reactToTokenExpired(Chat.PatientApp));
  }

  private async addConversation(conversation: Conversation, chat = Chat.Alberta) {
    debug('twilio: conversation added', conversation);
    const chatConversation = this._twilioToChatDtoHelpers.convertToChatChannel(conversation);
    chatConversation.isAgent = chat === Chat.Alberta ? undefined : true;
    await this._chatEventService.addChatChannel(chatConversation);

    const participants = await conversation.getParticipants();
    const chatMembers = participants.map(member => this._twilioToChatDtoHelpers.convertToChatMember(member));

    return this._chatEventService.updateChannelMembers(conversation.sid, chatMembers);
  }

  private async updateConversation(
    conversation: Conversation,
    updateReasons: ConversationUpdateReason[],
    chat = Chat.Alberta
  ) {
    debug('twilio: conversation updated', conversation, updateReasons);
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(
      conversation,
      chat === Chat.PatientApp ? updateReasons.join('#') : undefined
    );
    chatChannel.isAgent = chat === Chat.Alberta ? undefined : true;

    return this._chatEventService.updateChatChannel(chatChannel);
  }

  private async removeConversation(conversation: Conversation) {
    debug('twilio: conversation removed', conversation);
    return this._chatEventService.removeChatChannelById(conversation.sid);
  }

  private async addParticipantToConversation(participant: Participant) {
    debug('twilio: participant joined', participant);
    const conversationParticipant = this._twilioToChatDtoHelpers.convertToChatMember(participant);
    return this._chatEventService.addMemberToChannel(conversationParticipant);
  }

  private async removeParticipantFromConversation(participant: Participant) {
    debug('twilio: participant left', participant);
    return this._chatEventService.removeMemberFromChannel(participant.conversation.sid, participant.sid);
  }

  private async addMessage(message: Message, chat = Chat.Alberta) {
    debug('twilio: message added', message);
    const chatMessage = await this._twilioToChatDtoHelpers.convertToChatMessage(message);
    const attributes = chatMessage.attributes || {};
    chatMessage.attributes = chat === Chat.PatientApp ? { ...attributes, isAgent: true } : chatMessage.attributes;

    return this._chatEventService.addMessage(chatMessage);
  }

  private async removeMessage(message: Message) {
    debug('twilio: message removed', message);
    return this._chatEventService.removeMessageById(message.sid);
  }

  private async reactToTokenAboutToExpire(chat: Chat) {
    debug('twilio: token about to expire');
    await this._chatEventService.reactToTokenAboutToExpire(chat);
  }

  private async reactToTokenExpired(chat: Chat) {
    debug('twilio: token expired');
    await this._chatEventService.reactToTokenExpired(chat);
  }

  private async setupConnectionStateEvents() {
    const twilioClient = await this._twilioChatClient;

    twilioClient.on('connectionStateChanged', state => this.handleConnectionStateChanged(state));
    if (twilioClient.connectionState === 'connected') {
      this._chatConnectionStateService.setConnectionState('connected');
    }

    const twilioAgentClient = await this._twilioAgentChatClient;
    if (!twilioAgentClient) {
      return;
    }
    twilioAgentClient.on('connectionStateChanged', state => this.handleConnectionStateChanged(state, Chat.PatientApp));
    if (twilioAgentClient.connectionState === 'connected') {
      this._chatConnectionStateService.setConnectionState('connected', Chat.PatientApp);
    }
  }

  private handleConnectionStateChanged(connectionState, chat = Chat.Alberta) {
    debug('connection state changed', connectionState);
    this._chatConnectionStateService.setConnectionState(connectionState, chat);
  }
}
