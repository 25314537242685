import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LastChannelActivityPipe } from 'src/app/shared/components/chat/last-channel-activity/last-channel-activity.pipe';
import { SharedChatModule } from 'src/app/shared/components/chat/shared-chat.module';
import { AlbertaInputsModule } from 'src/app/shared/components/inputs/inputs-components.module';
import { LastChannelActivityModule } from '../chat/last-channel-activity/last-channel-activity.module';

import { AlbertaCommonComponentsModule } from '../common/common-components.module';
import { WizardPageModule } from '../wizard-page/wizard-page.module';
import { SelectUserToShareDialogComponent } from './select-user-to-share-dialog.component';

@NgModule({
  declarations: [SelectUserToShareDialogComponent],
  exports: [SelectUserToShareDialogComponent],
  imports: [
    CommonModule,
    WizardPageModule,
    AlbertaCommonComponentsModule,
    SharedChatModule,
    IonicModule,
    TranslateModule,
    AlbertaInputsModule,
    LastChannelActivityModule,
  ],
  providers: [LastChannelActivityPipe],
})
export class SelectUserToShareDialogModule {}
