import { IUser } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ISyncState } from 'src/app/common/contracts/sync/sync-state';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { Changes } from 'src/app/common/tracking';
import { UserDto } from 'src/app/shared/models/user-management/user-dto.model';
import { AuthorizationResolver } from 'src/app/shared/services/authorization-resolver.service';
import { IDatabaseSynchronizer } from 'src/app/shared/services/contracts/sync/database-synchronizer_T';

import { ICancellationToken } from '../../common/contracts/cancellation/cancellation-token';
import { WorkItem } from '../work-item';
import { UsersWorkItem as DecoratedUserWorkItem } from './user-work-item';

@Injectable({ providedIn: 'root' })
export class UsersWorkItem extends WorkItem<IUser> implements IWorkItem<IUser>, IDatabaseSynchronizer<IUser> {
  constructor(private _userWorkItem: DecoratedUserWorkItem, private _authorizationResolver: AuthorizationResolver) {
    super(null);
  }
  get name(): string {
    return 'UsersWorkItem';
  }

  getAll(): Promise<IUser[]> {
    return this._userWorkItem.getAll();
  }

  async sync(state: {}, token: ICancellationToken): Promise<ISyncState<IUser>> {
    const result = await this._userWorkItem.sync(state, token);

    await Promise.all(result.updated.map(user => this._authorizationResolver.resolve(user)));
    await Promise.all(result.deleted.map(user => this._authorizationResolver.resolve(user)));

    return result;
  }

  get database(): string {
    return this._userWorkItem.database;
  }

  // eslint-disable-next-line require-await
  public async beforeUpdate(item: IUser, changes: Changes): Promise<IUser> {
    return this._userWorkItem.beforeUpdate(item, changes);
  }

  async create(model: IUser): Promise<void> {
    await this._userWorkItem.create(model);
    await this._authorizationResolver.resolve(model);

    return;
  }

  update(changes: Changes, userDto: UserDto): Promise<void> {
    return this._userWorkItem.update(changes, userDto);
  }

  async delete(model: IUser): Promise<void> {
    await this._userWorkItem.delete(model);
    await this._authorizationResolver.resolve(model);

    return;
  }
}
