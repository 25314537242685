import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title = '';
  @Input() public required = false;
  @Input() public description: string;
  // icon not supported at Checkbox
  public icon: string;

  public value;
  private onChange = (_text: string) => {};
  private onTouched = () => {};

  constructor(@Optional() public changeDetectorRef: ChangeDetectorRef, @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  // ControlValueAccessor interface
  public writeValue(obj: any) {
    this.value = obj;
    this.changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public toggleCheckbox($event) {
    $event.stopPropagation();
    this.value = !this.value;
    this.onChange(this.value);
  }
}
