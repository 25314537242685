import { IDoctor, IInstitution, IPatient } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public options$$ = new BehaviorSubject<{ mail: boolean; fax: boolean; mailSubject: string; mailBody: string }>(
    undefined
  );
  public patient$$ = new BehaviorSubject<IPatient>(undefined);
  public institution$$ = new BehaviorSubject<IInstitution>(undefined);
  public doctor$$ = new BehaviorSubject<IDoctor>(undefined);
  public data$$ = new BehaviorSubject(undefined);
  public reportData$$ = new BehaviorSubject(undefined);
  public type$$ = new BehaviorSubject<string>(undefined);

  constructor() {}
}
