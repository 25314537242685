import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-channel-list-message',
  templateUrl: './channel-list-message.component.html',
  styleUrls: ['./channel-list-message.component.scss'],
})
export class ChannelListMessage {
  @Input()
  public icon: string;
  @Input()
  public header: string;
  @Input()
  public message: string;
}
