import { IModel } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CommandResult } from 'src/app/commands/command-result';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { ConnectionMode } from 'src/app/common/contracts/connection/connection-mode';
import { ISyncStatus } from 'src/app/common/contracts/sync/sync-status';
import { SyncStatus } from 'src/app/common/sync/sync-status';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

import { AuthService } from './auth.service';
import { ConnectionStateService } from './connection-state.service';
import { IAdditionalSyncData } from './contracts/sync/additional-sync-data';
import { IClientDataSyncService } from './contracts/sync/client-data-sync';
import { IConnectionStateService } from './contracts/sync/connection-state-service';
import { IFeathersService } from './contracts/sync/feathers-service';
import { IFullSyncService } from './contracts/sync/service/full-sync-service';
import { ISyncService } from './contracts/sync/service/sync-service';
import { FeathersService } from './feathers.service';
import { SyncTimeoutRepeater } from './sync/sync-timeout-repeater.service';
import { SyncService } from './sync/sync.service';
import { TrackerService } from './tracker.service';

@Injectable({
  providedIn: 'root',
})
export class ClientDataSyncService implements IClientDataSyncService {
  constructor(
    private _storage: Storage,
    @Inject(SyncService) private _syncServices: ISyncService[],
    @Inject(ConnectionStateService) private _connectionStateService: IConnectionStateService,
    @Inject(FeathersService) private _feathersService: IFeathersService,
    @Inject(SyncStatus) private _syncStatus: ISyncStatus,
    private _authService: AuthService,
    private _ngZone: NgZone,
    private _environmnetService: EnvironmentService,
    private _tracker: TrackerService
  ) {
    const copyOfSyncServices = [..._syncServices];
    this._syncServices.length = 0;
    for (const service of copyOfSyncServices) {
      this._syncServices.push(...service.resolve());
    }

    this._feathersService.connectionModeChange.subscribe(connectionMode => {
      if (connectionMode === ConnectionMode.default) {
        this._syncServices.forEach(service => service.setup(this._feathersService));
      } else {
        this._syncServices.forEach(service => service.removeAllListeners(connectionMode));
      }
    });
  }

  public async startSync(token: ICancellationToken): Promise<void> {
    for (const service of this._syncServices) {
      await service.setup(this._feathersService);
    }
    const promise = this.sync(token);

    token.promise = promise;

    return promise;
  }

  public async write(
    channel: string = '',
    payload: IModel = { _id: '', archived: false, timestamp: new Date() },
    additionalData: IAdditionalSyncData
  ): Promise<CommandResult> {
    if (!this._connectionStateService.isConnected) {
      return new CommandResult({ successful: false });
    }

    for (const service of this._syncServices) {
      if (this.isFullSyncService(service) && service.canSync(channel)) {
        await service.setup(this._feathersService);
        const result = await service.write(payload, additionalData, channel, this._authService.authentication.account);

        return new CommandResult({ successful: true, result });
      }
    }
    return new CommandResult({ successful: false });
  }

  private async sync(token: ICancellationToken): Promise<any> {
    this._syncStatus.completedData = false;
    this._syncStatus.completedSync = false;
    const userSync = await this._storage.get(
      `sync_${this._environmnetService.branch}_${this._authService.authentication.account._id}`
    );

    await Promise.all(
      this._syncServices.map(syncService =>
        new SyncTimeoutRepeater(syncService, this._ngZone, userSync)
          .sync(token, this._authService.authentication.account)
          .catch(() => undefined)
      )
    );

    await this._storage.set(
      `sync_${this._environmnetService.branch}_${this._authService.authentication.account._id}`,
      await this.userSyncToSave(userSync)
    );

    return new Promise<any>(resolve => this.waitUntilSyncCompleted(token, resolve));
  }

  private async userSyncToSave(runningUserSync) {
    const currentUserSync = await this._storage.get(
      `sync_${this._environmnetService.branch}_${this._authService.authentication.account._id}`
    );

    return (!runningUserSync && currentUserSync) ||
      (runningUserSync && currentUserSync && runningUserSync.version !== currentUserSync.version)
      ? currentUserSync.status.push(...(runningUserSync || { status: [] }).status) && currentUserSync
      : runningUserSync;
  }

  private waitUntilSyncCompleted(token: ICancellationToken, resolve: (value?: {} | PromiseLike<{}>) => void) {
    this._ngZone.runOutsideAngular(() => {
      const interval = global.setInterval(() => {
        if (!Object.keys(token.serviceRepeats).length) {
          this._syncStatus.completedData = Object.values(token.syncCompleted || {}).every(value => value);
          this._syncStatus.completedSync = true;
          resolve();
          global.clearInterval(interval);
          this._tracker.trackSyncFinished();
          return;
        }
      }, 500);
    });
  }

  private isFullSyncService(syncService: any): syncService is IFullSyncService {
    return syncService && syncService.write && syncService.canSync;
  }
}
