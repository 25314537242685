import { IDoctor } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { DoctorWorkItem } from 'src/app/business/institution/doctor-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class DoctorCommandHandler extends EntityCommandHandler<IDoctor> {
  protected workItem: IWorkItem<IDoctor>;

  constructor(doctorWorkItem: DoctorWorkItem) {
    super();
    this.workItem = doctorWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'DoctorDto';
  }
}
