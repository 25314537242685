import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['../_templates/text-input-template/text-input-template-alb-class.scss'],
})
export class CurrencyComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public placeholder = '';
  @Input() public icon: string;
  // zeigt einen Counter an wie viele Zeichen noch möglich sind und sperrt weitere eingaben
  @Input() public maxlength: number;
  // Zeigt einen roten Stern neben dem Titel an
  @Input() public required = false;
  // Es wird kein Icon angezeigt
  @Input() public noIcon = false;
  // Graut die Komponente aus und keine Interaktion mehr möglich - stetzt auch die readonly property auf den Text Input
  @Input() public disabled = false;
  @Input() public description: string;
  @Input() public trimEnabled = false;

  @ViewChild('input', { static: true }) public input: ElementRef;

  public value = '';
  public onChange = (_text: string) => {};
  public onTouched = () => {};

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public setFocus() {
    this.input.nativeElement.focus();
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public writeValue(obj: any) {
    this.input.nativeElement.value = obj;
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
