import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { LightboxModal } from './lightbox.modal';

@NgModule({
  declarations: [LightboxModal],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
  exports: [],
})
export class LightboxModule {}
