import { Inject, Injectable } from '@angular/core';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { SQLite, SQLiteDatabaseConfig, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SqlCipherConfigService } from 'src/app/shared/services/sql-cipher-config.service';
import DB_PLUGINS, { PluginsToken } from '../repository/index-plugins';
import { ExternalStorageService } from './external-storage.service';
import { IndexField } from './index-field';
import { SqliteStorage } from './sqlite-storage';

@Injectable({ providedIn: 'root' })
export class SqliteStorageFactory {
  private _dbPromise: Promise<SQLiteObject>;
  private _deserialize;
  private _serialize;

  constructor(
    private _sqlite: SQLite,
    @Inject(PluginsToken) private _plugins: typeof DB_PLUGINS,
    private _sqlCipherConfig: SqlCipherConfigService,
    private _platform: Platform,
    private _alertController: AlertController,
    private _translateService: TranslateService,
    private _batteryStatus: BatteryStatus,
    private _externalStorageService: ExternalStorageService
  ) {}

  public async configure(
    databaseName: string,
    serialize = item => JSON.stringify(item),
    deserialize = item => JSON.parse(item)
  ): Promise<void> {
    this._serialize = serialize;
    this._deserialize = deserialize;

    await this._platform.ready();

    if (!this._platform.is('cordova')) {
      return;
    }

    this._dbPromise = new Promise(async (resolve, reject) => {
      try {
        const key = (await this._sqlCipherConfig.mustEncryptStorage()) && (await this._sqlCipherConfig.getKey());

        const db: SQLiteObject = await this._sqlite.create({
          name: databaseName,
          key: key || undefined,
          location: 'default',
        } as SQLiteDatabaseConfig);
        // }

        resolve(db);
      } catch (error) {
        reject(error);
      }
    });
  }

  private async isBatteryOk() {
    return await new Promise<boolean>(resolveBattery => {
      this._batteryStatus.onChange().subscribe(async value => {
        if (value.level < 30) {
          const alert = await this._alertController.create({
            header: 'Warnung',
            message:
              'Der Kopiervorgang der Datenbank kann nicht fortgeführt werden, da die Batterie weniger als 30% Ladung hat.\nBitte laden Sie zunächst Ihr Gerät auf.',
            buttons: [
              {
                text: this._translateService.instant('DONE_BUTTON'),
              },
            ],
          });
          await alert.present();
          await alert.onDidDismiss();
          resolveBattery(value.level >= 30);
        }
      });
    });
  }

  public async create(storageName: string, isDeletable = true, canRepairIndex = true): Promise<SqliteStorage> {
    const table = storageName.substring(0, storageName.length - 3);

    const sqlStatement = `CREATE TABLE IF NOT EXISTS [${table}] (id INTEGER PRIMARY KEY, key unique, value)`;
    await this._dbPromise.then(db => db.executeSql(sqlStatement, []));
    const indexStatement = `CREATE UNIQUE INDEX IF NOT EXISTS idx_${table}_key ON [${table}] (key);`;
    await this._dbPromise.then(db => db.executeSql(indexStatement, []));

    const plugin = this._plugins[storageName];
    const fields = plugin
      ? plugin.fields.map(field => (typeof field === 'string' ? new IndexField(field) : new IndexField(field.name)))
      : [];
    const fieldNames = fields.map(field => field.name).join(', ');
    if (fields.length > 0) {
      const searchTableStatement = `CREATE VIRTUAL TABLE IF NOT EXISTS ${table}_fts USING fts4(id, ${fieldNames})`;
      await this._dbPromise.then(db => db.executeSql(searchTableStatement, []));
    }
    return new SqliteStorage(
      this._dbPromise,
      table,
      fields,
      this._serialize,
      this._deserialize,
      isDeletable,
      canRepairIndex
    );
  }
}
