import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportService } from '../shared/services/report.service';

@Injectable({
  providedIn: 'root',
})
export class ReportGuard implements CanActivate {
  constructor(private _reportService: ReportService) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._reportService.reportData$$.pipe(map(data => data !== undefined));
  }
}
