import { IFax } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Params } from '@feathersjs/feathers';
import { FaxChannel } from 'src/app/business/fax/contracts/fax-channel';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { FaxModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { AuthService } from '../auth.service';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class FaxSyncService extends SyncService<IFax> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IFax>,
    eventService: EventService<SyncProgressEvent>,
    private _auth: AuthService,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(FaxModelName, appController, dispatcher, eventService, ngZone, platformSync);
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._service = feathersAppProvider.app.service(FaxModelName);

    this._service.removeAllListeners('created');
    this._service.removeAllListeners('patched');
    this._service.removeAllListeners('removed');
    this._service.removeAllListeners('status');

    this._service.on('created', item => {
      if (item.createdBy !== this._auth.authentication.account._id) {
        return;
      }
      this.onCreated(item);
    });

    this._service.on('patched', item => {
      if (item.createdBy !== this._auth.authentication.account._id) {
        return;
      }
      this.onPatched(item);
    });
    this._service.on('removed', item => this.onRemoved(item));
    this._service.on('status', item => {
      if (item.fax.createdBy !== this._auth.authentication.account._id) {
        return;
      }
      this.onPatched(item.fax);
    });
  }

  protected patchParams(params: Params): Params {
    params.query = { ...params.query, createdBy: this._auth.authentication.account._id };

    return params;
  }

  public canSync(channel: string): boolean {
    return channel === FaxChannel.CREATE || channel === FaxChannel.EDIT || channel === FaxChannel.DELETE;
  }
}
