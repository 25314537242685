export const BACKGROUND = [
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/1.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/1.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/2.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/2.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/3.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/3.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/4.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/4.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/5.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/5.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/6.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/6.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/7.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/7.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/8.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/8.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/9.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/9.png',
  },
  {
    desktop: '../../assets/imgs/syncIndicatorBackgrounds/desktop/10.png',
    mobile: '../../assets/imgs/syncIndicatorBackgrounds/mobile/10.png',
  },
];
