import { Injectable } from '@angular/core';
import { IReminder } from '@pia/pia.shared';
import { ReminderWorkItem } from 'src/app/business/reminder/reminder-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';
import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class ReminderCommandHandler extends EntityCommandHandler<IReminder> {
  protected workItem: IWorkItem<IReminder>;

  constructor(reminderWorkItem: ReminderWorkItem) {
    super();
    this.workItem = reminderWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'ReminderDto';
  }
}
