import { INursingHome } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class NursingHomeDto extends AggregateRoot implements INursingHome, ISearchable {
  timestamp: Date;
  archived: boolean;
  name: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  treatmentType: string;
  website: string;
  department: string;

  constructor() {
    super();
  }
  get displayText(): string {
    return `${this.name} (${this.city})`;
  }
}
