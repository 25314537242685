import { Gender, IAssignedInstitution, IDoctor } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class DoctorDto extends AggregateRoot implements IDoctor, ISearchable {
  gender: Gender;
  titleShort: string;
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
  fax: string;
  institution: IAssignedInstitution;
  ldn: string;
  en: string;
  department: string;

  constructor() {
    super();
  }

  get displayText(): string {
    return `${this.titleShort ? this.titleShort + ' ' : ''}${
      this.firstName && this.firstName.length ? this.firstName + ' ' : ''
    }${this.lastName} ${this.city}`;
  }
}
