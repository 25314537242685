import { IMatch } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { MatchDB } from 'src/app/common/repository/databases';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { MatchDto } from 'src/app/shared/models/swodoc/match-dto.model';

import { IDatabaseSynchronizer as IDatabaseSynchronizer_T } from '../../shared/services/contracts/sync/database-synchronizer_T';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class MatchWorkItem extends WorkItem<MatchDto> implements IDatabaseSynchronizer_T<IMatch> {
  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }

  unitOfWork: IUnitOfWork;
  get name(): string {
    return 'MatchWorkItem';
  }
  get database(): string {
    return MatchDB;
  }
}
