import { Injectable } from '@angular/core';
import { AttachmentDB } from 'src/app/common/repository/databases';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { MessagePayloadAttachment } from 'src/app/shared/models/share/message-payload-attachment';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

import { IRouteParser } from '../contracts/route/route-parser';
import { QueryService } from '../query/query.service';

@Injectable()
export class AttachmentRouteParser implements IRouteParser {
  type = MessagePayloadType.Attachment;

  constructor(private _queryService: QueryService) {}
  async parse(payload: MessagePayloadAttachment): Promise<string> {
    const attachment: AttachmentDto = await this._queryService.get(payload.attachmentId, AttachmentDB);

    if (!attachment) {
      throw new Error(`No attachment found for id ${payload.attachmentId}`);
    }

    return `/patient/${attachment.metadata.patientId}/attachments/${attachment._id}`;
  }
}
