import { Inject, Injectable } from '@angular/core';

import makeDebug from '../../makeDebug';
import { ClientDataSyncService } from '../shared/services/client-data-sync.service';
import { IClientDataSyncService } from '../shared/services/contracts/sync/client-data-sync';
import { NetworkInfoService } from '../shared/services/network/network-info.service';
import { CommandResult } from './command-result';
import { ICommand } from './contracts/command';

const debug = makeDebug('command:command-sender');

@Injectable({ providedIn: 'root' })
export class CommandSender {
  constructor(
    @Inject(ClientDataSyncService) private _clientDataSyncService: IClientDataSyncService,
    private readonly _networkInfoService: NetworkInfoService
  ) {}

  public async send(command: ICommand): Promise<CommandResult> {
    if (this._networkInfoService.isConnectionSlow) {
      return new CommandResult({ successful: false, error: new Error('Connection is too slow.') });
    }

    try {
      delete (command.entity as any).changeTracker;

      debug('syncing command with server', command);

      return await this._clientDataSyncService.write(command.channel, command.entity, {
        changes: command.changes,
        additionalSyncInfo: command.additionalSyncInfo,
      });
    } catch (error) {
      window.logger.error('COMMANDSENDER / SEND', error);
      return new CommandResult({ successful: error.code && error.code === 409, error });
    }
  }
}
