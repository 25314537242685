import { IErpOrder } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';

import { ErpOrderModelName } from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { EventService } from '../event.service';
import { SyncService } from './sync.service';

@Injectable()
export class ErpOrderSyncService extends SyncService<IErpOrder> {
  constructor(
    @Inject(AppController) appController: IAppController,
    @Inject(Dispatcher) dispatcher: IDispatcher<IErpOrder>,
    eventService: EventService<SyncProgressEvent>,
    ngZone: NgZone,
    @Inject(PlatformSyncToken) platformSync: IPlatformSync
  ) {
    super(ErpOrderModelName, appController, dispatcher, eventService, ngZone, platformSync);
    this.ignoreState = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canSync(_channel: string): boolean {
    return false;
  }
}
