import { Injectable, NgZone } from '@angular/core';
import { UsersWorkItem } from 'src/app/business/user/user-work-item';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { AppController } from 'src/app/common/controller/app-controller';

import { UsersModelName } from '../../models/model-names';
import { WebSyncService } from './web-sync.service';

@Injectable()
export class WebUserSyncService extends WebSyncService {
  constructor(private _userWorkItem: UsersWorkItem, appController: AppController, ngZone: NgZone) {
    super(UsersModelName, ngZone, appController);
  }

  protected async handleData(data: any, token: ICancellationToken): Promise<any> {
    const syncState = await this._userWorkItem.sync(data, token);

    return [...syncState.created, ...syncState.deleted, ...syncState.updated];
  }
}
