import { IDevice, IDeviceInformationLine, IDeviceMaintenanceLine } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { AggregateRoot } from '../aggregate-root';

export class DeviceDto extends AggregateRoot implements IDevice, ISearchable {
  patientId: string;
  articleId: string;
  articleNumber: string;
  serialNumber: string;
  description: string;
  additionalDescription: string;
  locked: boolean;
  commissioningDate: string;
  repairEstimateRequired: boolean;
  repairEstimateLimit: string;
  lastBookingCode: string;
  lastLocationCode: string;
  ownerName: string;
  deviceNumber: string;
  maintenances: IDeviceMaintenanceLine[];
  deviceInformationLines: IDeviceInformationLine[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  _id: string;
  timestamp: Date;
  archived: boolean;
  regionId: string;
  resmedTenant: string;
  registerNumber: string;

  constructor() {
    super();
    this.archived = false;
  }

  get displayText(): string {
    return this.description;
  }
}
