import { IPatientNursingServiceRelation } from '@pia/pia.shared';

import { removeTime } from '../../../common/tracking/tracker';

export class PatientNursingServiceRelationViewModel implements IPatientNursingServiceRelation {
  nursingServiceId: string;

  subunitId: string;

  contactId: string;

  @removeTime()
  dischargeDate: Date;

  _id: string;

  timestamp: Date;

  archived: boolean;
}
