import { TimePeriod } from '@pia/pia.shared';
import { TranslateService } from '@ngx-translate/core';

export function returnDurationText(
  translate: TranslateService,
  timePeriod: TimePeriod,
  plural?: boolean,
  dative?: boolean
) {
  const pluralSuffix = plural ? 's' : '';
  const dativeSuffix = dative ? 'Dative' : '';
  let type = '';
  if (timePeriod != null) {
    switch (timePeriod) {
      case TimePeriod.Minute:
        type = translate.instant(`timePeriod.unknown`);
        break;
      case TimePeriod.Hour:
        type = translate.instant(`timePeriod.hour${pluralSuffix}`);
        break;
      case TimePeriod.Day:
        type = translate.instant(`timePeriod.day${pluralSuffix}${dativeSuffix}`);
        break;
      case TimePeriod.Week:
        type = translate.instant(`timePeriod.week${pluralSuffix}${dativeSuffix}`);
        break;
      case TimePeriod.Month:
        type = translate.instant(`timePeriod.month${pluralSuffix}${dativeSuffix}`);
        break;
      case TimePeriod.Quarter:
        type = translate.instant(`timePeriod.quarter${pluralSuffix}`);
        break;
      case TimePeriod.HalfYear:
        type = translate.instant(`timePeriod.halfYear${pluralSuffix}`);
        break;
      case TimePeriod.Year:
        type = translate.instant(`timePeriod.year${pluralSuffix}${dativeSuffix}`);
        break;
    }
  }
  return type;
}
