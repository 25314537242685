import { AttributeDataType, IAttributeTemplate } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class AttributeTemplateDto extends AggregateRoot implements IAttributeTemplate, ISearchable {
  name: string;
  dataType: AttributeDataType;
  get displayText(): string {
    return `${this.name}`;
  }
}
