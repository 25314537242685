import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Platform } from '@ionic/angular';

import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-text',
  templateUrl: './text.component.html',
  styleUrls: ['../_templates/text-input-template/text-input-template-alb-class.scss', './text.component.scss'],
})
export class TextComponent implements OnInit, IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public placeholder: string;
  @Input() public icon: string;
  @Input() public type: 'tel' | 'text' | 'email' | 'number' | 'password' | 'url' = 'text';
  // zeigt einen Counter an wie viele Zeichen noch möglich sind und sperrt weitere eingaben
  @Input() public maxlength: number;
  // Automatisch jeden ersten Buchstaben eines Wortes groß schreiben
  @Input() public capatalizeFirstLetter = false;
  // Zeigt einen roten Stern neben dem Titel an
  @Input() public required = false;
  // Es wird kein Icon angezeigt
  @Input() public noIcon = false;
  // Graut die Komponente aus und keine Interaktion mehr möglich - stetzt auch die readonly property auf den Text Input
  @Input() public disabled = false;
  @Input() public description: string;
  @Input() public trimEnabled = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data-testid') public dataTestId: string;

  @ViewChild('input', { static: true }) public input: ElementRef;

  public value = '';
  public isIos: boolean;
  public onChange = (_text: string) => {};
  public onTouched = () => {};

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private _changeDetectorRef: ChangeDetectorRef,
    platform: Platform
  ) {
    this.isIos = platform.is('ios');
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.placeholder = this.type === 'tel' ? '0....' : this.placeholder || '';
  }

  @HostListener('input', ['$event']) onInputChange($event) {
    if (this.capatalizeFirstLetter) {
      this.input.nativeElement.value = this.toTitleCase($event.target.value);
    }
  }

  public setFocus() {
    this.input.nativeElement.focus();
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public writeValue(obj: any) {
    this.input.nativeElement.value = obj;
    this._changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  private toTitleCase(text: string): string {
    const spaceSplit = text.toLowerCase().split(' ');
    for (let i = 0; i < spaceSplit.length; i++) {
      spaceSplit[i] = spaceSplit[i].charAt(0).toUpperCase() + spaceSplit[i].slice(1);

      // Handle names with hyphens
      const hyphenSplit = spaceSplit[i].split('-');
      for (let j = 1; j < hyphenSplit.length; j++) {
        hyphenSplit[j] = `${hyphenSplit[j].charAt(0).toLocaleUpperCase()}${hyphenSplit[j].slice(1)}`;
      }

      spaceSplit[i] = hyphenSplit.join('-');
    }
    return spaceSplit.join(' ');
  }
}
