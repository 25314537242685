import { IDoctor, IInstitution, IPatient } from '@pia/pia.shared';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

export function getMessagePayloadSubtitle(patient: IPatient): string {
  if (!patient) {
    return '';
  }
  return `${patient.firstName} ${patient.lastName}${patient.customerId ? ` (${patient.customerId})` : ''}`;
}

export function getMessagePayloadInstitutionSubtitle(institution: IInstitution | IDoctor): string {
  if (!institution) {
    return '';
  }
  if ('name' in institution) {
    if (institution.postalCode && institution.city) {
      return `${institution.name}, ${institution.postalCode} ${institution.city}`;
    } else {
      return institution.name;
    }
  } else if ('firstName' in institution || 'lastName' in institution) {
    const combinedName = [institution.firstName, institution.lastName].filter(name => !!name).join(' ');
    if (institution.postalCode && institution.city) {
      return `${combinedName}, ${institution.postalCode} ${institution.city}`;
    } else {
      return combinedName;
    }
  } else {
    return '';
  }
}

export function getIconFromMessagePayloadType(payloadType: MessagePayloadType) {
  switch (payloadType) {
    case MessagePayloadType.Attachment:
      return 'alberta-attachment';
    case MessagePayloadType.Document:
    case MessagePayloadType.InstitutionDocument:
      return 'alberta-document';
    case MessagePayloadType.Note:
      return 'alberta-note';
    case MessagePayloadType.SingleOrder:
      return 'alberta-shoppingcart';
    case MessagePayloadType.Patient:
      // gets overridden by MessagePayloadPatient
      return 'alberta-person';
  }
}
