import { IModel } from '@pia/pia.shared';
import { Inject, Injectable } from '@angular/core';
import { IDatabaseSynchronizer } from 'src/app/shared/services/contracts/sync/database-synchronizer_T';

import { IAppController } from '../contracts/controller/app-controller';
import { IWorkItemProvider } from '../contracts/work-item/work-item-provider';
import { IWorkItem } from '../contracts/work-item/work-item_T';
import { WorkItemProvider } from '../work-item/work-item-provider';

@Injectable({ providedIn: 'root' })
export class AppController implements IAppController {
  constructor(@Inject(WorkItemProvider) private workItemProvider: IWorkItemProvider) {}

  getWorkItemByCtor<T extends IModel>(name: string): IWorkItem<T> | IDatabaseSynchronizer<T> {
    return this.workItemProvider.get(name);
  }
}
