<!-- MobilView -->
<ng-container *ngIf="isMobileView">
  <ng-container
    [ngTemplateOutlet]="channelList"
    [ngTemplateOutletContext]="{ isMobileView: isMobileView }"
  ></ng-container>
  <div class="chat-mobile-container alberta-background" [ngClass]="{ showChannel: showChannelView }">
    <ng-container
      [ngTemplateOutlet]="channel"
      [ngTemplateOutletContext]="{ isMobileView: isMobileView }"
    ></ng-container>
  </div>
</ng-container>

<!-- Desktop View -->
<ng-container *ngIf="!isMobileView">
  <div class="chat-desktop-container">
    <div class="chat-desktop-list">
      <ng-container
        [ngTemplateOutlet]="channelList"
        [ngTemplateOutletContext]="{ isMobileView: isMobileView }"
      ></ng-container>
    </div>
    <div class="chat-desktop-channel alberta-background">
      <ng-container
        [ngTemplateOutlet]="channel"
        [ngTemplateOutletContext]="{ isMobileView: isMobileView }"
      ></ng-container>
    </div>
  </div>
</ng-container>
