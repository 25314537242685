import { INursingService } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { NursingServiceWorkItem } from 'src/app/business/institution/nursing-service-work-item';
import { IWorkItem } from 'src/app/common/contracts/work-item/work-item_T';

import { Command } from '../command';
import { EntityCommandHandler } from '../entity-command-handler';

@Injectable()
export class NursingServiceCommandHandler extends EntityCommandHandler<INursingService> {
  protected workItem: IWorkItem<INursingService>;

  constructor(nursingServiceWorkItem: NursingServiceWorkItem) {
    super();
    this.workItem = nursingServiceWorkItem;
  }

  canHandle(command: Command): boolean {
    return command.dtoName === 'NursingServiceDto';
  }
}
