import { IMaintenance } from '@pia/pia.shared';
import { isEqual } from 'lodash';

import { AggregateRoot } from '../aggregate-root';

export class MaintenanceDto extends AggregateRoot implements IMaintenance {
  start: Date;
  end: Date;
  stopped: boolean;
  createdBy: string;
  createdAt: Date;
  _id: string;
  timestamp: Date;
  archived: boolean;
  rev: number;

  isEqual(other: IMaintenance) {
    return this._id === other._id && isEqual(this.start, other.start) && isEqual(this.end, other.end);
  }
}
