import { IChangeTrack } from '../contracts/tracking/change-track';
import { IChangeTracker } from '../contracts/tracking/change-tracker';
import { ChangeTrackCollection } from './change-track-collection';
import { proxyToRaw, rawToProxy } from './internals';

function ownKeys(obj) {
  return Reflect.ownKeys(obj);
}

function deleteProperty(obj, key) {
  if (key in obj) {
    return Reflect.deleteProperty(obj, key);
  }
  return false;
}

function createGet(trackedProps: string[]) {
  return function get(target, key, receiver) {
    const result = Reflect.get(target, key, receiver);

    if (!trackedProps.some(prop => prop === key) || typeof key === 'symbol' || typeof result === 'function') {
      return result;
    }

    return rawToProxy.get(result) || result;
  };
}
function createSet(
  changeTracker: IChangeTracker,
  trackInternal: (target: any, changeTracker: IChangeTracker, path: string, atRuntime?: boolean) => any
) {
  return function set(obj, key, value, receiver) {
    if (typeof value === 'object' && value !== null) {
      value = proxyToRaw.get(value) || value;
    }
    const valueChanged = value !== obj[key];

    const result = Reflect.set(obj, key, value, receiver);

    let changeTrack: IChangeTrack;

    let forceChange = false;
    if (!changeTracker.get(`${changeTracker.getTrackId(obj)}.${key}`)) {
      forceChange = true;
      const path = fixIndexPath(changeTracker.getPathByHierarchy(obj, key, proxyToRaw));
      trackInternal(obj, changeTracker, path, true);
    }

    if (
      !changeTracker.isSuspended &&
      valueChanged &&
      (changeTrack = changeTracker.get(`${changeTracker.getTrackId(obj)}.${key}`))
    ) {
      changeTrack.setValue(value instanceof Date ? value.toISOString() : value, forceChange);
      if (changeTrack instanceof ChangeTrackCollection) {
        obj[key] = changeTrack.proxy;
      }
      changeTracker.listeners.forEach(listener =>
        listener.next({
          currentValue: changeTrack.newValue,
          origValue: changeTrack.oldValue,
          path: changeTracker.getPathByHierarchy(obj, key, proxyToRaw),
          key,
        })
      );
    }

    return result;
  };
}

function fixIndexPath(path: string): string {
  return path.split('.').reduce((resolvedPath, part) => {
    const indexPart = isNaN(Number(part)) ? (resolvedPath.length > 0 ? `.${part}` : part) : `[${part}]`;

    return `${resolvedPath}${indexPart}`;
  }, '');
}

export function createHandler(
  changeTracker: IChangeTracker,
  trackedProps: string[],
  trackInternal: (target: any, changeTracker: IChangeTracker, path: string, atRuntime?: boolean) => any
) {
  return {
    get: createGet(trackedProps),
    set: createSet(changeTracker, trackInternal),
    ownKeys,
    deleteProperty,
  };
}
