import { Injectable } from '@angular/core';
import { Conversation, Message, Participant } from '@twilio/conversations';
import { isNumber } from 'lodash';

import { ChatChannel, ChatMember, ChatMessage } from '../data/db-schema';

@Injectable({
  providedIn: 'root',
})
export class TwilioToChatDtoHelpersService {
  constructor() {}

  public convertToChatChannel(conversation: Conversation, updateReasons?: string): ChatChannel {
    return {
      sid: conversation.sid,
      dateUpdated: this._convertToIsoString(conversation.dateUpdated),
      dateCreated: this._convertToIsoString(conversation.dateCreated),
      friendlyName: conversation.friendlyName || '',
      uniqueName: conversation.uniqueName || '',
      lastMessageIndex: conversation.lastMessage ? conversation.lastMessage.index : null,
      lastMessageTimestamp: conversation.lastMessage
        ? conversation.lastMessage.dateCreated?.toISOString()
        : conversation.dateCreated.toISOString(),
      lastLocalUpdateAt: undefined,
      lastConsumedMessageIndex: conversation.lastReadMessageIndex || null,
      removed: false,
      attributes: conversation.attributes,
      updateReasons,
    };
  }

  public async convertToChatMessage(message: Message): Promise<ChatMessage> {
    let memberIdentity: string;
    let memberSid: string;

    const attributes = message.attributes;

    if (message.author === 'system') {
      memberIdentity = 'System';
      memberSid = 'System';

      attributes['_id'] = message.sid;
    } else {
      memberIdentity = (await message.getParticipant()).identity;
      memberSid = message.participantSid;
    }
    return {
      _id: attributes['_id'],
      isLocal: undefined,
      channelSid: message.conversation.sid,
      sid: message.sid,
      dateUpdated: message.dateUpdated.toISOString(),
      timestamp: message.dateCreated.toISOString(),
      index: message.index,
      body: message.body,
      memberSid: memberSid,
      memberIdentity: memberIdentity,
      status: 'sent',
      retries: 0,
      attributes: attributes,
    };
  }

  public convertToChatMember(participant: Participant): ChatMember {
    return {
      sid: participant.sid,
      channelSid: participant.conversation.sid,
      identity: participant.identity,
      lastConsumedMessageIndex: isNumber(participant.lastReadMessageIndex) ? participant.lastReadMessageIndex : 0,
      dateUpdated: participant.dateUpdated.toISOString(),
      isLocal: undefined,
    };
  }

  private _convertToIsoString(date: any) {
    try {
      if (date) {
        return date instanceof Date ? date.toISOString() : new Date(date).toISOString();
      }
    } catch (error) {
      // this does not seem to be a valid date
    }

    return null;
  }
}
