import {
  IDuration,
  IInsuranceContract,
  IProductGroupQuantity,
  IRequiredDocument,
  ISalesContract,
  IVisitInterval,
  PricingModelType,
} from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class InsuranceContractDto extends AggregateRoot implements IInsuranceContract, ISearchable {
  ageLimit: number;
  ageLimitLower: number;
  approvalObligation: boolean;
  billingType: number;
  contractArticleGroupIds: string[];
  costEstimateRequired: boolean;
  createdAt: Date;
  createdBy: string;
  customers: string[];
  dayRelatedStartDate: boolean;
  description: string;
  flatChargeTextAllowed: boolean;
  generalContract: boolean;
  group: string;
  insuranceContractName: string;
  isSelfPayer: boolean;
  maxPermanentPrescriptionPeriod: IDuration;
  maximumAmountForProductGroups: IProductGroupQuantity[];
  name: string;
  notPayingPayerIds: string[];
  payerIds: string[];
  periodOnPrescriptionNecessary: boolean;
  pricingBasis: string;
  pricingModelType: PricingModelType;
  productGroupIds: string[];
  remarks: string[];
  requiredDocuments: IRequiredDocument[];
  salesContracts: ISalesContract[];
  tenant: string;
  therapyId: number;
  therapyTypeIds: number[];
  updatedAt: Date;
  updatedBy: string;
  validFrom: Date;
  validTo: Date;
  version: string;
  visitInterval: IVisitInterval;

  constructor() {
    super();

    this.salesContracts = [];
    this.requiredDocuments = [];
    this.maximumAmountForProductGroups = [];
    this.productGroupIds = [];
    this.payerIds = [];
  }

  get displayText(): string {
    return this.name;
  }
}
