import { Inject, Injectable } from '@angular/core';
import { Deferred } from 'src/app/common/deferred/deferred';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

import makeDebug from '../../../../makeDebug';
import { PipelineToken } from '../contracts/pipeline-token';
import { IPipelineContext } from '../contracts/start/pipeline-context';
import { IPipelineElement } from '../contracts/start/pipeline-element';
import { IPipelineRegistry } from '../contracts/start/pipeline-registry';

const debug = makeDebug('sync:pipeline-registry');

@Injectable({ providedIn: 'root' })
export class PipelineRegistry implements IPipelineRegistry {
  private _context: IPipelineContext;
  private _ready = new Deferred<void>();
  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  constructor(
    @Inject(PipelineToken) private _pipelineElements: IPipelineElement[],
    private _environmentService: EnvironmentService
  ) {
    this.init();
  }

  private async init() {
    await this._environmentService.ready;

    this._context = {
      registry: this,
      params: { isLogin: false, uri: this._environmentService.alberta_baseurl },
      executeOnlyOnce: new Map<string, boolean>(),
    };
    const firstElement = this._pipelineElements.find(pipelineElement => pipelineElement.parent === '');
    if (!firstElement) {
      return this._ready.reject(new Error('No root of pipe element defined.'));
    }

    const sortedPipelineElements = [firstElement];

    this.sortByParent(sortedPipelineElements);

    this._pipelineElements.length = 0;
    this._pipelineElements.push(...sortedPipelineElements);

    this._ready.resolve();
  }

  public async start(): Promise<void> {
    await this._ready.promise;

    for (const pipelineElement of this._pipelineElements) {
      debug('executing pipeline element', pipelineElement.endpoint, this._context);
      await pipelineElement.execute(this._context);
    }
  }

  sortByParent(sortedPipelineElements: IPipelineElement[]) {
    const childElement = this._pipelineElements.find(
      pipelineElement => sortedPipelineElements[sortedPipelineElements.length - 1].endpoint === pipelineElement.parent
    );
    if (!childElement) {
      return;
    }

    sortedPipelineElements.push(childElement);
    this.sortByParent(sortedPipelineElements);
  }
}
