<div
  class="alberta-button-input-wrapper"
  [ngClass]="{ 'alberta-button-input-wrapper--alert': hasErrors, 'alberta-button-input-wrapper--disabled': disabled }"
  tappable
  (click)="buttonClicked()"
>
  <div class="button-content-wrapper">
    <div
      class="icon-wrapper"
      *ngIf="!noIcon"
      [ngClass]="{
        'icon-wrapper--stdIcon': !icon
      }"
    >
      <ion-icon [name]="icon ? icon : 'alberta-dot'"></ion-icon>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <div class="value-wrapper">
          <span *ngIf="!value" class="placeholder"> {{ placeholder }}</span>
          <span *ngIf="value && !cutText" class="bold"> {{ value }}</span>
          <div *ngIf="value && cutText" class="breaktext-wrapper">
            <div class="bold braketext">
              {{ value }}
            </div>
          </div>
        </div>
        <div class="action-buttons">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="title">{{ title }} <span *ngIf="required" class="required-star">*</span></div>
    </div>
  </div>
  <div class="description" *ngIf="description">
    {{ description }}
  </div>
  <itl-error-display *ngIf="control" [control]="control"></itl-error-display>
</div>
