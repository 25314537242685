import { IModel } from '@pia/pia.shared';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'notarchived',
  pure: true,
})
export class NotArchivedPipe implements PipeTransform {
  constructor() {}
  transform(entities: IModel[]): any[] {
    return entities.filter(entity => !entity.archived);
  }
}
