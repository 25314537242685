import { Inject, Injectable } from '@angular/core';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';
import { IntermediaryDB } from '../../common/repository/databases';
import { IntermediaryDto } from '../../shared/models/institution/intermediary-dto.model';
import { WorkItem } from '../work-item';

@Injectable({ providedIn: 'root' })
export class IntermediaryWorkItem extends WorkItem<IntermediaryDto> {
  unitOfWork: IUnitOfWork;

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    super(unitOfWorkFactory);
  }
  get name(): string {
    return 'IntermediaryWorkItem';
  }
  get database(): string {
    return IntermediaryDB;
  }
}
