import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatChannelDetail } from '../../services/chat/model/chat.model';

@Pipe({ name: 'chatinputplaceholder' })
export class ChatMessageInputPlaceholderPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(selectedChannel: ChatChannelDetail): string {
    if (selectedChannel.isActive) {
      return this._translate.instant('chat.messages.create');
    }
    if (!selectedChannel.friendlyName) {
      return this._translate.instant('chat.messages.userDeactivated');
    }
    return `${selectedChannel.friendlyName} ${this._translate.instant('chat.messages.hasBeenDeactivated')}`;
  }
}
