import { Injectable } from '@angular/core';
import { InstitutionType } from '@pia/pia.shared';
import { InstitutionsService } from 'src/app/institution/master/institutions.service';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';
import { MessagePayloadInstitution } from '../../models/share/message-payload-institution';
import { IRouteParser } from '../contracts/route/route-parser';
import { getInstitutionPathFromType } from './route-helper';

@Injectable()
export class InstitutionRouteParser implements IRouteParser {
  type = MessagePayloadType.Institution;
  constructor(private _institutionService: InstitutionsService) {}

  async parse(payload: MessagePayloadInstitution): Promise<string> {
    const institution =
      payload.institutionType === InstitutionType.Doctor
        ? await this._institutionService.getDoctor(payload.institutionId)
        : await this._institutionService.getInstitution(payload.institutionId, payload.institutionType);

    if (!institution) {
      throw new Error(`No institution found for id ${payload.institutionId}`);
    }

    return `/${getInstitutionPathFromType(payload.institutionType)}/${payload.institutionId}/${payload.path}`;
  }
}
