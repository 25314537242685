import { TranslateService } from '@ngx-translate/core';
import { getIconFromMessagePayloadType } from 'src/app/shared/models/share/message-payload-helper';
import { MessagePayloadType } from 'src/app/shared/models/share/message-payload-type';

export interface IMessagePayload {
  readonly config: { readonly version: number; readonly type: MessagePayloadType };
  title: string;
  subtitle?: string;
  icon?: string;
  version: number;
  type: MessagePayloadType;
}

export abstract class MessagePayload implements IMessagePayload {
  public title: string;
  public subtitle?: string;
  public icon?: string;
  public readonly summary: string;
  public body?: string;
  substituteUser?: null | {
    absent: boolean;
    user: {
      _id: string;
      firstName: string;
      lastName: string;
    };
  };

  public get version(): number {
    return this.config.version;
  }

  public get type(): MessagePayloadType {
    return this.config.type;
  }

  constructor(
    translate: TranslateService,
    public readonly config: { readonly version: number; readonly type: MessagePayloadType }
  ) {
    if (!translate) {
      return;
    }
    this.summary = translate.instant(`share.${this.type}`);
    this.icon = getIconFromMessagePayloadType(this.type);
  }
}
