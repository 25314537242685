import { Permission } from '@pia/pia.shared';
import { MenuItem } from 'src/app/common/menu/menu-item';

import {
  ArticleModelName,
  GroupModelName,
  InstitutionModelName,
  InsuranceContractModelName,
  PatientModelName,
  ProductGroupModelName,
  RegionModelName,
  TemplateModelName,
  UsersModelName,
} from '../../models/model-names';
import { AuthService } from '../../services/auth.service';

export class MainMenu {
  public static get(isWebApp: boolean, authService: AuthService): MenuItem[] {
    const menuItems = [];
    menuItems.push(new MenuItem('Startseite', 'alberta-dashboard', '/dashboard'));
    if (authService.userHasPermission(PatientModelName, Permission.Read)) {
      menuItems.push(new MenuItem('Patienten', 'people', '/patient'));
    }
    if (!isWebApp && authService.userHasPermission('onlineSearch', Permission.Read)) {
      menuItems.push(new MenuItem('Online-Suche', 'people', '/emergency'));
    }
    const notificationMenu = new MenuItem('Mitteilungen', 'alberta-chat');
    notificationMenu.addChild(new MenuItem('Fax-Protokoll', 'alberta-fax', '/fax'));
    menuItems.push(notificationMenu);

    this.setMangementPages(authService, menuItems, isWebApp);
    this.setInstitutionPages(authService, menuItems);
    if (isWebApp && authService.userHasPermission(TemplateModelName, Permission.Read)) {
      menuItems.push(new MenuItem('Formulare', 'alberta-swodoc', '/formulare'));
    }
    const settingsMenu = new MenuItem('Einstellungen', 'alberta-setting');
    settingsMenu.addChild(new MenuItem('Allgemein', 'alberta-hammer', '/setting'));
    if (authService.userHasPermission('calendar', Permission.Read)) {
      settingsMenu.addChild(new MenuItem('Kalender', 'alberta-activities', '/setting/calendar'));
    }
    if (isWebApp) {
      if (this.hasAnyUsermanagementPermission(authService)) {
        settingsMenu.addChild(new MenuItem('Rechtemanagement', 'alberta-key', '/rights-management'));
      }
      if (this.userIsItLabs(authService)) {
        settingsMenu.addChild(new MenuItem('Wartungsmanagement', 'alberta-clock', '/maintenance'));
      }
      if (authService.authentication.account.isAgent) {
        settingsMenu.addChild(new MenuItem('Patienten-App', 'alberta-support', '/agent-admin'));
      }
    }
    menuItems.push(settingsMenu);

    menuItems.push(new MenuItem('Abwesenheiten', 'alberta-activities', 'absences'));

    return menuItems;
  }
  private static setInstitutionPages(authService: AuthService, menuItems: MenuItem[]) {
    if (authService.userHasPermission(InstitutionModelName, Permission.Read)) {
      const instituionsMenu = new MenuItem('CRM', 'alberta-crm');

      instituionsMenu.addChild(new MenuItem('Apotheken', 'alberta-pharmacy', '/pharmacy'));
      instituionsMenu.addChild(new MenuItem('Ärzte', 'alberta-doctor', '/doctor'));
      instituionsMenu.addChild(new MenuItem('Kliniken', 'alberta-hospital', '/hospital'));
      instituionsMenu.addChild(new MenuItem('Pflegedienste', 'alberta-nursingservice', '/nursing-service'));
      instituionsMenu.addChild(new MenuItem('Pflegeheime', 'alberta-nursinghome', '/nursing-home'));

      menuItems.push(instituionsMenu);
    }
  }
  private static setMangementPages(authService: AuthService, menuItems: MenuItem[], isWebApp) {
    if (isWebApp) {
      if (authService.userHasPermission(InsuranceContractModelName, Permission.Read)) {
        menuItems.push(new MenuItem('Verträge', 'alberta-contract', '/insurance-contract'));
      }
    }
    const productMenu = new MenuItem('Produkte', 'alberta-produce');
    if (authService.userHasPermission(ArticleModelName, Permission.Read)) {
      productMenu.addChild(new MenuItem('Artikel', 'alberta-article', isWebApp ? '/article' : '/article-mobile'));
    }
    if (isWebApp) {
      if (authService.userHasPermission(ProductGroupModelName, Permission.Read)) {
        productMenu.addChild(new MenuItem('Warengruppen', 'alberta-productgroup', '/product-group'));
      }
    }
    if (productMenu.children && productMenu.children.length > 0) {
      menuItems.push(productMenu);
    }
  }

  private static hasAnyUsermanagementPermission(authService: AuthService) {
    return (
      authService.userHasPermission(UsersModelName, Permission.Read) ||
      authService.userHasPermission(GroupModelName, Permission.Read) ||
      authService.userHasPermission(RegionModelName, Permission.Read)
    );
  }

  private static userIsItLabs(authService) {
    return (
      authService &&
      authService.authentication &&
      authService.authentication.account &&
      authService.authentication.account.organization &&
      authService.authentication.account.organization.tenantId === '1TLabs'
    );
  }
}
