import { IInstitutionContact } from '@pia/pia.shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { assignIn } from 'lodash';
import { InstitutionContactViewModel } from 'src/app/business/institution/view-model/institution-contact-view-model';
import { InstitutionContactDB } from 'src/app/common/repository/databases';
import { QueryService } from 'src/app/shared/services/query/query.service';

@Injectable()
export class InstitutionContactResolver implements Resolve<IInstitutionContact> {
  constructor(private _queryService: QueryService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const contact = await this._queryService.get<IInstitutionContact>(
      route.paramMap.get('contactId'),
      InstitutionContactDB
    );

    return contact != null ? assignIn(new InstitutionContactViewModel(), contact) : null;
  }
}
