import { Injectable } from '@angular/core';

import makeDebug from '../../../../makeDebug';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { SyncIndicatorPreCloser as PreCloser } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:sync-indicator-pre-closer');

@Injectable()
export class SyncIndicatorPreCloser implements ISyncElement {
  public get name(): string {
    return PreCloser;
  }

  public async execute(context: ISyncContext): Promise<void> {
    debug('closing sync modal and toast');
    if (context.syncModal) {
      await context.syncModal.dismiss().catch(error => console.log(error));
      debug('closed sync modal');
    }
  }
}
