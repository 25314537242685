import { IPayer, PayerType } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { AggregateRoot } from '../aggregate-root';

export class PayerDto extends AggregateRoot implements IPayer, ISearchable {
  type: PayerType;
  ikNumber: string;
  name: string;
  phone: string;
  email: string;
  website: string;
  requirements?: Record<string, unknown>[];
  smofkabiven: { startDate: Date; endDate: Date };

  constructor() {
    super();
  }

  get displayText(): string {
    return this.name;
  }
}
