<itl-text-input-template
  [control]="ngControl"
  [icon]="icon"
  [title]="title"
  [value]="value"
  [maxlength]="maxlength"
  [description]="description"
  [required]="required"
  (focusClicked)="setFocus()"
>
  <textarea
    #textInput
    autosize
    [minRows]="1"
    class="alb-input"
    [(ngModel)]="value"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    [ngClass]="{ bold: value && value.length }"
    (focus)="markAsTouched()"
    (ngModelChange)="updateChanges()"
  ></textarea>
</itl-text-input-template>
