import { IModel } from '@pia/pia.shared';

import { DbQueryStrategy } from './db-query-strategy';

export class LocalQueryStrategy<T extends IModel & {}> extends DbQueryStrategy<T> {
  public getAll(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const items = [];
      this.storage.getAll().subscribe(
        item => items.push(item),
        error => reject(error),
        () => resolve(items)
      );
    });
  }

  public getItems(keys: string[]): Promise<any[]> {
    return this.storage.getItems(keys);
  }

  public async get(id: string): Promise<T> {
    try {
      const result = await this.storage.get(id);

      return result;
    } catch (e) {
      return void 0;
    }
  }

  public searchInternal(ids: string[]): Promise<T[]> {
    return this.storage.getItems(ids).then(items => {
      if (!this.withArchive.includes(this.table)) {
        return items.filter(item => !item.archived || item.archived == null);
      }
      return items;
    });
  }
}
