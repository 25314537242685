import { Inject, Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { SecureStorageEcho, SecureStorageEchoObject } from '@ionic-native/secure-storage-echo/ngx';
import { Platform } from '@ionic/angular';
import hash from 'object-hash';
import randomToken from 'random-token';
import {
  AuthenticationToken,
  IAuthenticationAccount,
} from 'src/app/common/contracts/authentication/authentication-account';
import { environment } from 'src/environments/environment';
import { Branch, EnvironmentService } from './environment/environment.service';

@Injectable({ providedIn: 'root' })
export class SqlCipherConfigService {
  private _encryptionSettings = environment.encryption.settings;
  private _regex = environment.encryption.regex;
  private _keySalt: string;
  private _secureStorageObject: SecureStorageEchoObject;

  constructor(
    private _platform: Platform,
    private _secureStorage: SecureStorageEcho,
    private _device: Device,
    @Inject(AuthenticationToken) private _authenticationAccount: IAuthenticationAccount,
    private _environmentService: EnvironmentService
  ) {}

  public async mustEncryptStorage(): Promise<boolean> {
    await this._authenticationAccount.ready;

    const settings = this._encryptionSettings.find(setting =>
      setting.emails.some(email => this._authenticationAccount.account.email.includes(email))
    );

    return (
      this._regex &&
      this._regex.test(this._authenticationAccount.account.email) &&
      settings.platforms.some(platform => this._platform.platforms().includes(platform))
    );
  }

  public async getKey(): Promise<string> {
    if (!this._keySalt) {
      await this.createKeySalt();
    }

    await this._authenticationAccount.ready;

    this._secureStorageObject = await this._secureStorage.create('alberta');
    const branch = this._environmentService.branch === Branch.production ? 'master' : this._environmentService.branch;

    const secureStorageKey = `sqlite_${this._authenticationAccount.account._id.slice(-4)}_${branch}`;

    let key: string = null;
    const keys = await this._secureStorageObject.keys();
    if (!keys || !keys.includes(secureStorageKey)) {
      key = randomToken.create(this._keySalt)(64);
      await this._secureStorageObject.set(secureStorageKey, key);
    } else {
      key = await this._secureStorageObject.get(secureStorageKey);
    }

    return key;
  }

  private async createKeySalt(): Promise<void> {
    await this._authenticationAccount.ready;
    // UserId vom angemeldeten Benutzer wird mit zur Generierung des Salts verwendet
    // Ich weiß nicht, wie jetzt irgend jemand den Key dann noch rausfinden möchte?!
    // https://discuss.zetetic.net/t/sqlcipher-database-key-material-and-selection/25

    const uuidHashValue =
      this._device.serial == null || this._device.serial === 'unknown'
        ? hash(`${this._device.uuid}${this._authenticationAccount.account._id}`)
        : hash(`${this._device.serial}${this._device.uuid}${this._authenticationAccount.account._id}`);

    const set = new Set([
      ...Array.from(uuidHashValue).map(char => char.toLocaleLowerCase()),
      ...Array.from(uuidHashValue).map(char => char.toLocaleUpperCase()),
      // SqlCipher supports all UTF-8 encoded strings/characters
      // https://discuss.zetetic.net/t/technical-guidance-using-random-values-as-sqlcipher-keys/3715
      ...['#', '$', '%', '?', '!', '*', '=', '&', '@', '<', '>', '/', '-', '^', '_'],
    ]);

    this._keySalt = [...set].join('');
  }
}
