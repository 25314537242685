import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import makeDebug from '../../../../makeDebug';
import { AuthService } from '../../services/auth.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { Authenticator as Authentication } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:authenticator');

@Injectable()
export class Authenticator implements ISyncElement {
  public get name(): string {
    return Authentication;
  }

  constructor(private _authenticationService: AuthService, private _router: Router, private _ngZone: NgZone) {}

  public async execute(context: ISyncContext): Promise<void> {
    debug('entered execute function', context);
    if (!(await this.authenticate(context.params.isOnline)) && context.params.isStartUpSync) {
      debug('User could not be authenticated.');
      await this._ngZone.run(() => this._router.navigate(['/login']));
      context.params.noAuth = true;
    }
  }

  private async authenticate(isOnline: boolean): Promise<boolean> {
    await this._authenticationService.configure();
    let isAuthenticated = await this._authenticationService.authenticate(isOnline);
    if (!isAuthenticated && isOnline && (await this._authenticationService.refreshToken())) {
      // call configure again on mobile to create new defered with access token
      // which is waited for in authenticate();
      await this._authenticationService.configure();
      debug('accessToken refreshed');
      isAuthenticated = await this._authenticationService.authenticate(isOnline);
      debug('authenticate with new accessToken', isAuthenticated);
    }
    this._authenticationService.setAuthenticated(isAuthenticated, isOnline);
    return isAuthenticated;
  }
}
