import { IModel } from '@pia/pia.shared';
import { IQuery } from 'src/app/shared/services/contracts/query/query';

import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { IRepository } from '../contracts/repository/repository';

export class Repository<T extends IModel & {}> implements IRepository<T> {
  constructor(private _dbQueryStrategy: IDbQueryStrategy<T>) {}

  get ready(): Promise<void> {
    return this._dbQueryStrategy.ready;
  }

  // eslint-disable-next-line require-await
  async count(): Promise<number> {
    return this._dbQueryStrategy.storage.length();
  }

  async createOrUpdateFromSync(itemsForDb: { items: T[]; deletable: boolean }, batch: any[]): Promise<any> {
    await this.ready;

    await this._dbQueryStrategy.storage.setItems(itemsForDb, batch);
  }

  execBatch(_batch: any[]): Promise<any> {
    return;
  }

  // eslint-disable-next-line require-await
  public async addOrUpdateIndex(_item: T, _respectReady: boolean = true): Promise<any> {
    return true;
  }

  // eslint-disable-next-line require-await
  async createOrUpdate(item: T): Promise<any> {
    item.timestamp = new Date();
    return this.createOrUpdateInternal(item);
  }

  private async createOrUpdateInternal(item: T): Promise<any> {
    await this.ready;

    return this._dbQueryStrategy.storage.setItem(item._id, item);
  }

  // eslint-disable-next-line require-await
  async getAll(): Promise<T[]> {
    return this._dbQueryStrategy.getAll();
  }

  // eslint-disable-next-line require-await
  async getItems(keys: string[]): Promise<any[]> {
    return this._dbQueryStrategy.getItems(keys);
  }

  // eslint-disable-next-line require-await
  async get(id: string): Promise<T> {
    return this._dbQueryStrategy.get(id);
  }

  delete(_id: string): Promise<void> {
    return;
  }

  // eslint-disable-next-line require-await
  async search(query: IQuery): Promise<T[]> {
    return this._dbQueryStrategy.search(query);
  }
}
