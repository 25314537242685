import { Injectable } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private _statusBar: StatusBar, private _platform: Platform) {}

  public setColorFromCssVariable(color: string): void {
    this.setColorFromHex(getComputedStyle(document.documentElement).getPropertyValue(color).replace(/[ ]/g, ''));
  }

  public setColorFromHex(hexColor: string): void {
    if (this._platform.is('ios')) {
      this._statusBar.overlaysWebView(false);
    }

    this._statusBar.backgroundColorByHexString(hexColor);

    if (this.lightOrDark(hexColor) === 'light') {
      this._statusBar.styleDefault();
    } else {
      this._statusBar.styleLightContent();
    }
    if (this._platform.is('ios')) {
      this._statusBar.overlaysWebView(true);
    }
  }

  public lightOrDark(color: string): string {
    const rgb = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    /* eslint-disable no-bitwise*/
    const r = rgb >> 16;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    /* eslint-enable no-bitwise*/

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    return hsp > 127.5 ? 'light' : 'dark';
  }
}
