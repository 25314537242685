<div class="offline-message">
  <div class="wrapper">
    <div class="image">
      <ion-icon [name]="icon"></ion-icon>
    </div>
    <div class="message">
      <div class="header">
        {{ header }}
      </div>
      <div class="innerMessage" [innerHTML]="message"></div>
    </div>
  </div>
</div>
