import { Component, Input } from '@angular/core';

@Component({
  selector: 'itl-chat-list-header',
  templateUrl: './chat-list-header.component.html',
})
export class ChatListHeaderComponent {
  @Input() header: string;
  @Input() defaultHref = '/chat';
}
