import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';

import { Chat } from './model/chat-instance';

const debug = makeDebug('services:chat:online-status');

type State = 'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'denied';

@Injectable({ providedIn: 'root' })
export class ChatConnectionStateService {
  private _connectionState: BehaviorSubject<State> = new BehaviorSubject('disconnected');
  private _agentConnectionState = new BehaviorSubject('disconnected');

  public setConnectionState(state: State, chat = Chat.Alberta) {
    debug('set state to', state);
    if (chat === Chat.Alberta) {
      this._connectionState.next(state);
    } else {
      this._agentConnectionState.next(state);
    }
  }

  public observeOnlineState(): Observable<boolean> {
    return this._connectionState.pipe(map((state: State) => state === 'connected'));
  }

  public observeAgentOnlineState(): Observable<boolean> {
    return this._agentConnectionState.pipe(map((state: State) => state === 'connected'));
  }

  public get isOnline() {
    return this._connectionState.getValue() === 'connected';
  }

  public get isAgentChatOnline() {
    return this._agentConnectionState.getValue() === 'connected';
  }
}
