import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      break;
    case LogLevel.Warning:
      console.warn(message);
      break;
    case LogLevel.Info:
      // eslint-disable-next-line no-console
      console.info(message);
      break;
    case LogLevel.Verbose:
    case LogLevel.Trace:
      // eslint-disable-next-line no-console
      console.debug(message);
      break;
    default:
      console.log(message);
  }
}

export function createMsalApp(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.calendar.clientId,
      redirectUri: environment.calendar.redirectUri,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Info : LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function createMsalInterceptorConfig(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', environment.o365Scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function createMsalGuardConfig(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: environment.o365Scopes,
    },
    loginFailedRoute: '/',
  };
}
