import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isEqual, isString } from 'lodash';
import { combineLatest, from, interval, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap, timeout } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { v4 } from 'uuid';

import { ChannelStatus } from '../../components/chat/channel-list-entry/channel-list-entry.component';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { ChatConnectionStateService } from './chat-connection-state.service';
import { ChatMessageDataService } from './chat-message-data.service';
import { ChatSendQueueService } from './chat-send-queue.service';
import { ChatSendService } from './chat-send.service';
import { ChatUserService } from './chat-user.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel, ChatMember, ChatMessage } from './data/db-schema';
import { Chat } from './model/chat-instance';
import { ChatChannelDetail } from './model/chat.model';
import { TwilioChatEventSourceService } from './twillio/twilio-chat-event-source.service';
import { fetchMissingMessages } from './utils/missing-messages-finder';

const debug = makeDebug('services:chat:service');

export interface ChatChannelWithDetails extends ChatChannel {
  lastMessage: ChatMessage | null;
  unreadMessages: number;
  isActive: boolean;
  status?: ChannelStatus;
  assignedTo?: string;
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  private userInfoCache: { [key: string]: { name: string; active: boolean } } = {};
  private _chatChannelCache: { [key in Chat]: ChatChannelWithDetails[] } = {
    [Chat.Alberta]: [],
    [Chat.PatientApp]: [],
  };

  constructor(
    private _chatDb: ChatDbService,
    private _chatUserService: ChatUserService,
    private _chatSendService: ChatSendService,
    private _usersService: UserService,
    private _connectionStateService: ChatConnectionStateService,
    private _chatMessagesDataService: ChatMessageDataService,
    private _platform: Platform,
    /* imported to force init */
    private _chatSendQueue: ChatSendQueueService,
    private _twilioChatEventSourceService: TwilioChatEventSourceService,
    private readonly _authService: AuthService
  ) {
    this.registerPlatformResumeHandler();
    this._getInitialUserInfoCache().catch(error => {
      console.error('Failed to load initial userinfo');
    });
  }

  private registerPlatformResumeHandler() {
    this._platform.resume.subscribe(async () => {
      try {
        debug('platform got active. fetch last messages!');
        await this.fetchLastMessagesOfChannels();
      } catch (err) {
        window.logger.error('fetch messages on resume failed', err);
      }
    });
  }

  public observeIsOnline(): Observable<boolean> {
    return this._connectionStateService.observeOnlineState();
  }

  public observeAgentIsOnline(): Observable<boolean> {
    return this._connectionStateService.observeAgentOnlineState();
  }

  public async markChannelAsRead(channelSid: string, index: number) {
    debug('mark channel as read', { channelSid, index });
    await this._chatMessagesDataService.updateChannelConsumptionStatus(channelSid, index);
  }

  public async checkForNewMessagesInChannel(channelSid: string, chat = Chat.Alberta) {
    const lastMessage = await this._chatDb.getLastMessageOfChannel(channelSid);
    const channel = await this._chatDb.getChannel(channelSid);
    const loadNewMessagesCount = lastMessage ? channel.lastMessageIndex - lastMessage.index : 20;

    if (loadNewMessagesCount) {
      await this.fetchMessagesOfChannel(channel.sid, undefined, loadNewMessagesCount, chat);
    }
  }

  /* TODO: This function does not seem to be of any sence to me.
  Loading all last messages of all channels (even if already closed or not displayed)
  seems to be a massive overload in queries.
  This leads to laggy overall performance.
  Loading the messages onDemand if the channel is selected is much faster.
  */

  public async fetchLastMessagesOfChannels(chat = Chat.Alberta) {
    const channels$ = await this._chatDb.getChannels(chat);
    channels$.pipe(take(1)).subscribe(async channels => {
      for (const channel of channels) {
        debug('fetch last message of channel', channel.sid);
        try {
          await this.fetchMessagesOfChannel(channel.sid, undefined, 1, chat);
        } catch (error) {
          console.error(error);
          window.logger.error('Fetching messages of channel failed.', error);
        }
      }
    });
  }

  public async getChannels(chat = Chat.Alberta): Promise<Observable<ChatChannelWithDetails[]>> {
    debug('get channels');
    const channels$ = await this._chatDb.getChannels(chat);

    return channels$.pipe(switchMap(channels => from(this._enrichChannelsBulk(channels, chat))));
  }

  private async _enrichChannelsBulk(channels: ChatChannel[], chat: Chat): Promise<ChatChannelWithDetails[]> {
    const removedChannels = this._chatChannelCache[chat].filter(
      cachedChannel => !channels.find(channel => channel.sid === cachedChannel.sid)
    );
    removedChannels.forEach(channel => this._chatChannelCache[chat].remove(channel));

    const newOrChangedChannels = channels.filter(channel => {
      const cached = this._chatChannelCache[chat].find(cachedChannel => cachedChannel.sid === channel.sid);

      return (
        !cached ||
        !isEqual(cached.attributes, channel.attributes) ||
        channel.dateUpdated !== cached.dateUpdated ||
        channel.friendlyName !== cached.friendlyName ||
        channel.lastConsumedMessageIndex !== cached.lastConsumedMessageIndex ||
        channel.lastLocalUpdateAt !== cached.lastLocalUpdateAt ||
        channel.lastMessageIndex !== cached.lastMessageIndex ||
        channel.lastMessageTimestamp !== cached.lastMessageTimestamp ||
        channel.uniqueName !== cached.uniqueName ||
        channel.updateReasons !== cached.updateReasons
      );
    });

    const channelsWithDetails = newOrChangedChannels.map(channel => channel as ChatChannelWithDetails);

    // unread messages are interessting only for assigned for new channels
    // this reduces loading unnecessary channels
    const assignedOrNewChannels = newOrChangedChannels.filter(
      channel =>
        channel.attributes &&
        (channel.attributes['assignedToId'] === this._authService.authentication.account._id ||
          channel.attributes['assignedToId'] === '')
    );
    const unreadMessages = await this._chatMessagesDataService.getUnreadMessagesOfChannels(assignedOrNewChannels);

    // set last Message
    const sendQueue = await this._chatDb.getCurrentSendQueueBulk(newOrChangedChannels.map(channel => channel.sid));
    const channelsWithSendQueue = channelsWithDetails.filter(channel =>
      sendQueue.find(message => message.channelSid === channel.sid)
    );
    channelsWithSendQueue.forEach(channel => {
      channel.lastMessage = sendQueue.filter(message => message.channelSid === channel.sid).lastOrDefault();
    });
    const channelWithoutSendQueue = newOrChangedChannels.filter(
      channel => !sendQueue.find(message => message.channelSid === channel.sid)
    );
    const lastMessages = await this._chatDb.getLastMessageOfChannels(
      channelWithoutSendQueue.map(channel => channel.sid)
    );
    lastMessages.forEach(async message => {
      const userInfo = await this.getUserInfo(message.memberIdentity);
      message.authorName = userInfo.name;
      const channel = channelsWithDetails.find(chan => chan.sid === message.channelSid);
      channel.lastMessage = message;
      channel.isActive = userInfo.active;
    });

    channelsWithDetails.forEach(async channel => {
      if (channel.uniqueName) {
        const userInfo = await this.setFriendlyNameToRemoteUserName(channel);
        if (userInfo) {
          channel.isActive = userInfo.active;
        }
      } else {
        channel.isActive = true;
      }
    });
    channelsWithDetails.forEach(channel => {
      channel.unreadMessages = unreadMessages.find(unread => unread.sid === channel.sid)?.unreadMessages || 0;

      const index = this._chatChannelCache[chat].findIndex(cachedChannel => cachedChannel.sid === channel.sid);

      if (index === -1) {
        this._chatChannelCache[chat].push(channel);
      } else {
        this._chatChannelCache[chat][index] = channel;
      }
    });

    return this._chatChannelCache[chat];
  }

  private async setFriendlyNameToRemoteUserName(channel: ChatChannel | ChatChannelWithDetails): Promise<{
    name: string;
    active: boolean;
  }> {
    const userIdentity = await this._chatUserService.getUserIdentity();
    const memberIdentities = this.getMemberSidsFromUniqueName(channel.uniqueName);
    const remoteUserIdentity = memberIdentities.find(f => f !== userIdentity);
    if (remoteUserIdentity) {
      const remoteUserName = await this.getUserInfo(remoteUserIdentity);
      if (remoteUserName && remoteUserName.name) {
        channel.friendlyName = remoteUserName.name;
      }
      return remoteUserName;
    }
  }

  private getMemberSidsFromUniqueName(uniqueName: string) {
    return uniqueName.split('§');
  }

  public async createChat(name: string, chatUserChatIds: string[]): Promise<ChatChannel> {
    const userIdentity = await this._chatUserService.getUserIdentity();
    debug('create chat with', name, chatUserChatIds, userIdentity);
    const chatChannel = await this._chatSendService.createChat(name, [userIdentity, ...chatUserChatIds]);
    await this._chatDb.upsertChannel(chatChannel);
    return chatChannel;
  }

  private async getMemberSidByLocalUser(channelSid: string, localUserIdentity: string): Promise<string | null> {
    return await this._chatDb.getMemberSidForIdentity(channelSid, localUserIdentity);
  }

  private async getLocalUserIdentity(): Promise<string | null> {
    return await this._chatUserService.getUserIdentity();
  }

  public async sendMessage(channelSid: string, message: string, attributes = {}) {
    debug('send message', { channelSid, message });
    const uuid = v4();
    const localUserIdentity = await this.getLocalUserIdentity();
    const localUserMemberSid = await this.getMemberSidByLocalUser(channelSid, localUserIdentity);
    const newMessage = this.convertToMessage(
      uuid,
      localUserMemberSid,
      localUserIdentity,
      channelSid,
      message,
      attributes
    );
    await this._chatDb.insertMessage(newMessage);
    await this._chatDb.setChannelLastLocalUpdate(channelSid);
  }

  public async fetchMessagesOfChannel(channelSid: string, anchor: number, count = 100, chat = Chat.Alberta) {
    debug('load messages of channel', { channelSid, anchor, count });
    let normalizedAnchor = anchor;
    if (normalizedAnchor < 0) {
      normalizedAnchor = undefined;
    }
    const messages = await this._chatSendService.fetchMessagesOfConversation(channelSid, normalizedAnchor, count, chat);

    if (messages?.length) {
      const localUserIdentity = await this._chatUserService.getUserIdentity();
      for (const message of messages) {
        const isLocalUser = message.memberIdentity === localUserIdentity;
        message.isLocal = isLocalUser;
      }
      debug('...got messages in channel', channelSid, 'messages:', messages);

      if (await this._chatDb.bulkInsertMessages(messages)) {
        await this._chatDb.setChannelLastLocalUpdate(channelSid);
      }
    }
  }

  public async isChannelReady(channel: ChatChannel, timeoutMs = 10 * 1000): Promise<boolean> {
    const localUser = await this.getLocalUserIdentity();

    const ready = interval(500)
      .pipe(
        map(async () => await this.getMemberSidByLocalUser(channel.sid, localUser)),
        map(memberSid => memberSid != null),
        filter(hasMemberSid => hasMemberSid === true),
        take(1),
        timeout(timeoutMs)
      )
      .toPromise();
    return ready;
  }

  public async getChannelDetails(channel: ChatChannel, chat = Chat.Alberta): Promise<Observable<ChatChannelDetail>> {
    debug('get channel details');
    const enrichChannelMember = async (member: ChatMember) => {
      debug('enricht member', member);
      const userInfo = await this.getUserInfo(member.identity);
      return { ...member, friendlyName: userInfo.name, isActive: userInfo.active };
    };
    const enrichMessages = async (message: ChatMessage) => {
      if (message.memberIdentity === 'System') {
        message.authorName = message.memberIdentity;
      } else {
        const userInfo = await this.getUserInfo(message.memberIdentity);
        message.authorName = userInfo.name;
      }
      return message;
    };
    if (channel.uniqueName) {
      await this.setFriendlyNameToRemoteUserName(channel);
    }

    return combineLatest([
      (await this._chatDb.getChannelMembers(channel.sid)).pipe(
        mergeMap(members => from(Promise.all(members.map(enrichChannelMember))))
      ),
      (await this._chatDb.getMessagesOfChannel(channel.sid)).pipe(
        mergeMap(messages => from(Promise.all(messages.map(enrichMessages))))
      ),
    ]).pipe(
      tap(([_members, messages]) => {
        setTimeout(() => fetchMissingMessages(messages, chat, this.fetchMessagesOfChannel.bind(this)));
      }),
      mergeMap(([members, messages]) =>
        from(
          new Promise<{
            members;
            messages: ChatMessage[];
            channel1: ChatChannel;
          }>(async resolve => {
            // HINT: channel will be loaded by itself? channel1 seems to be equal to channel.
            // Seems to be passed by parameter channel
            const channel1 = await this._chatDb.getChannel(channel.sid);

            if (channel.friendlyName && !channel1.friendlyName) {
              channel1.friendlyName = channel.friendlyName;
            }
            resolve({ members, messages, channel1 });
          })
        )
      ),
      map(({ members, messages, channel1 }) => {
        debug('map', { members, messages });

        const isOneOnOneChat = isString(channel.uniqueName) && channel.uniqueName !== '';
        // group chats are always active
        const isChannelActive = !isOneOnOneChat || members.every(member => member.isActive !== false);
        return {
          members,
          messages,
          channel,
          ...channel1,
          isActive: isChannelActive,
          showAssignAgentDialog: channel1.attributes && channel1.attributes['showAssignAgentDialog'] === true,
        } as ChatChannelDetail;
      })
    );
  }

  private async _getInitialUserInfoCache() {
    // FIXME: use _userServcice.query(...) and request agent-users only, but currently this is not supported
    const users = await this._usersService.getAll();

    if (users) {
      users
        .filter(userDto => userDto.isAgent)
        .forEach(
          userDto =>
            (this.userInfoCache[userDto._id] = {
              name: `${userDto.firstName} ${userDto.lastName}`,
              active: userDto.active === false ? false : true,
            })
        );
    }
  }

  private async getUserInfo(identity: string): Promise<{ name: string; active: boolean }> {
    /*
    This function is not "thread safe".
    Even if JS does not work threadded, this codepart will be interrupted before caching the identity.
    Unfortunately the function is a lot faster this theadunsafe way.
    The initial bulk loading of userInfos reduced the duplicates and improved overall performance significantly.
    */
    const cachedUserInfo = this.userInfoCache[identity];
    if (cachedUserInfo) {
      return cachedUserInfo;
    } else {
      const userDto = await this._usersService.find(identity);
      let userInfo = { name: '', active: false };

      if (userDto) {
        userInfo = {
          name: `${userDto.firstName} ${userDto.lastName}`,
          active: userDto.active === false ? false : true,
        };
      }
      this.userInfoCache[identity] = userInfo;

      return userInfo;
    }
  }

  public async updateChannelAttributes(channelSid: string, attributes = {}) {
    return this._chatSendService.updateConversationAttributes(channelSid, attributes);
  }

  private convertToMessage(
    uuid: string,
    memberSid: string,
    memberIdentity: string,
    channelSid: string,
    body: string,
    attributes: any
  ): ChatMessage {
    return {
      _id: uuid,
      isLocal: true,
      memberSid,
      memberIdentity,
      channelSid,
      body,
      status: 'pending',
      timestamp: new Date().toISOString(),
      retries: 0,
      dateUpdated: new Date().toISOString(),
      attributes,
    } as ChatMessage;
  }
}
