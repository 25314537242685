import { IModel } from '@pia/pia.shared';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Application, Paginated, Params, Query } from '@feathersjs/feathers';
import { upperFirst } from 'lodash';
import { SyncTimestampWorkItem } from 'src/app/business/timestamp/sync-timestamp-work-item';
import { ICancellationToken } from 'src/app/common/contracts/cancellation/cancellation-token';
import { IAppController } from 'src/app/common/contracts/controller/app-controller';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { AppController } from 'src/app/common/controller/app-controller';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { paramsForServer } from 'src/app/common/feathers/params-for-server';

import {
  AttributeTemplateModelName,
  ContractArticleGroupName,
  MatchModelName,
  PayerInfoModelName,
  PayerModelName,
  PostalCodeModelName,
  RegionModelName,
  ReportModelName,
  RightsetModelName,
  TemplateModelName,
} from '../../models/model-names';
import { SyncProgressEvent } from '../../models/sync-progress-event';
import { IDatabaseSynchronizer } from '../contracts/sync/database-synchronizer_T';
import { IFeathersAppProvider } from '../contracts/sync/feathers-app-provider';
import { ISyncService } from '../contracts/sync/service/sync-service';
import { EventService } from '../event.service';

@Injectable()
export class GeneralSyncService {
  private _app: Application;
  private _serviceName: string;
  private _syncTimestampWorkItem: SyncTimestampWorkItem;
  protected _syncables: string[] = [
    MatchModelName,
    ReportModelName,
    PostalCodeModelName,
    RegionModelName,
    RightsetModelName,
    PayerInfoModelName,
    PayerModelName,
    TemplateModelName,
    AttributeTemplateModelName,
    ContractArticleGroupName,
  ];

  protected _baseQuery: Query = {
    $sort: {
      timestamp: 1,
    },
  };

  constructor(
    @Inject(AppController) private _appController: IAppController,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<IModel>,
    private _eventService: EventService<SyncProgressEvent>,
    private _ngZone: NgZone
  ) {
    this._syncTimestampWorkItem = this._appController.getWorkItemByCtor(
      'SyncTimestampWorkItem'
    ) as SyncTimestampWorkItem;
  }

  public resolve(): ISyncService[] {
    return this._syncables.map(serviceName => {
      const service = new GeneralSyncService(this._appController, this._dispatcher, this._eventService, this._ngZone);
      service._serviceName = serviceName;

      return service;
    });
  }

  public removeAllListeners(): void {
    return;
  }

  public async setup(feathersAppProvider: IFeathersAppProvider): Promise<void> {
    await feathersAppProvider.ready;

    if (!feathersAppProvider.app) {
      throw new Error('Feathers application must be provided for synchronisation.');
    }

    this._app = feathersAppProvider.app;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, require-await
  public async reSync(token: ICancellationToken, user: any) {
    return;
  }

  public async sync(
    token: ICancellationToken,
    user: any,
    resetServiceRepeats: (token: ICancellationToken, serviceName: string) => void
  ): Promise<any> {
    const limit = 2000;

    token.syncCompleted[this._serviceName] = false;

    try {
      const timestamp = new Date(await this._syncTimestampWorkItem.getSyncTimestamp(this._serviceName));
      const service = this._app.service(this._serviceName);
      let query: Query = {
        ...this._baseQuery,
        $limit: 0,
        timestamp: {
          $gt: timestamp,
        },
      };

      let total = 1;
      let progress = 0;
      let result: any;
      let skip = 0;

      if (!token.cancelled.get()) {
        result = await service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._serviceName);

        // If the result is not paginated we need to query at least once since the query before limits to 0
        total = this.isPaginated(result) ? result.total : total;

        this._eventService.dispatchEvent(
          new SyncProgressEvent(this._serviceName, this.isPaginated(result) ? result.total : result.length, progress)
        );
      }

      while (total > 0 && !token.cancelled.get()) {
        await this.sleep();

        query = {
          ...query,
          $limit: limit,
          $skip: skip,
        };

        if (token.cancelled.get()) {
          break;
        }

        result = await service.find(this.getParamsWithAuth(user, { query }));
        resetServiceRepeats(token, this._serviceName);

        const payload = await this.updateDatabase(result, token);
        try {
          progress += payload.length;
          this._eventService.dispatchEvent(
            new SyncProgressEvent(this._serviceName, this.isPaginated(result) ? result.total : result.length, progress)
          );
        } catch (e) {}
        total -= result.limit || limit;
        skip += result.limit || limit;
      }

      token.syncCompleted[this._serviceName] = total <= 0;

      if (token.cancelled.get()) {
        delete token.serviceRepeats[this._serviceName];
        // eslint-disable-next-line no-throw-literal
        throw {
          reason: 'cancelled',
          service: this._serviceName,
          progress: `progress: ${progress}`,
          total: `total: ${total}`,
        };
      }
    } catch (error) {
      window.logger.error(`GENERALSYNCSERVICE SYNC / ${this._serviceName}`, error);
      throw error;
    }
  }

  private async updateDatabase(result, token: ICancellationToken): Promise<any[]> {
    try {
      const data = this.isPaginated(result) ? result.data : result;
      const workItem = this._appController.getWorkItemByCtor(`${upperFirst(this._serviceName)}WorkItem`);
      const syncState = await (workItem as IDatabaseSynchronizer<any>).sync(data, token);
      await this._dispatcher.sync(this._serviceName, syncState);
      const payload = [...syncState.created, ...syncState.deleted, ...syncState.updated];
      await this._syncTimestampWorkItem.setSyncTimestamp(this._serviceName, payload, result.documentCount);

      return payload;
    } catch (error) {
      window.logger.error(`GENERALSYNCSERVICE UPDATEDATABASE / ${this._serviceName}`, error);
    }
  }

  private sleep(ms?) {
    return new Promise<void>(resolve => this._ngZone.runOutsideAngular(() => setTimeout(resolve, ms || 0)));
  }

  private getParamsWithAuth(user, params: Params = { query: {} }): Params {
    if (user && user.authorization) {
      const { query } = params;
      paramsForServer({ query, syncData: { authorization: user.authorization } });
    }

    return params;
  }

  private isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
