import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';

export class AlbertaFormGroup extends FormGroup {
  private _sections = {};
  private _sectionStatus: string[] = [];

  constructor(
    controls: {
      [key: string]: AbstractControl;
    },
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  public get sections(): {} {
    return this._sections;
  }
  public set sections(opts: {}) {
    this._sections = opts;
  }
  public getSectionStatus(section: string) {
    return this._sectionStatus[section];
  }
  public setSectionStatus(sectionId: string, status: boolean) {
    this._sectionStatus[sectionId] = status;
  }
}
