import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PopoverController } from '@ionic/angular';

import { ChatEmojiPickerComponent } from '../chat-emoji-picker/chat-emoji-picker.component';

export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChatMessageInputComponent),
  multi: true,
};

@Component({
  selector: 'itl-chat-message-input',
  templateUrl: './chat-message-input.component.html',
  providers: [DEFAULT_VALUE_ACCESSOR],
})
export class ChatMessageInputComponent {
  @Output() submit: EventEmitter<void> = new EventEmitter();
  @Input() showSendButton: boolean;
  @Input() placeholder = '';
  @Input() showEmojiPicker = true;
  @Input() sendOnEnter = true;
  @Input() isActive = true;
  @Input() formControl: FormControl;

  public message = '';

  constructor(private popoverController: PopoverController, private changeDetectorRef: ChangeDetectorRef) {}

  public async presentPopover(ev: Event) {
    if (!this.isActive) {
      return;
    }
    const popover = await this.popoverController.create({
      component: ChatEmojiPickerComponent,
      event: ev,
      translucent: false,
    });
    await popover.present();
    const result = await popover.onDidDismiss();
    if (result.data) {
      this.addEmoji(result.data);
    }
  }

  public handleSubmit() {
    this.submit.emit();
  }

  public addEmoji(emoji: string) {
    this.formControl.setValue(this.formControl.value + emoji);
    this.changeDetectorRef.markForCheck();
  }
}
