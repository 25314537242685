<itl-wizard-page
  [isSinglePage]="true"
  [pageTitleTransPath]="'chat.select-user-dialog.title' | translate"
  [pageIsValid]="this.selectedItem"
  doneIcon="alberta-fab-send"
  (cancelClicked)="close()"
  (doneClicked)="done()"
>
  <itl-channel-list-message
    [header]="'chat.channelList.offlineMessage.header' | translate"
    [message]="'chat.channelList.offlineMessage.message' | translate"
    icon="alberta-chat-channel-list-offline"
    *ngIf="!isOnline"
  ></itl-channel-list-message>
  <itl-search-filter-bar
    [showFilter]="false"
    (searchValueChanged)="searchUser($event)"
    (searchValueReset)="clearSearch()"
    [disabled]="!isOnline"
  >
  </itl-search-filter-bar>
  <div class="content" [ngClass]="{ 'content--offline': !isOnline }">
    <ng-container *ngIf="showChannelList && !isLoading">
      <itl-section [title]="'chat.select-user-dialog.lastChat' | translate" [isDetail]="false">
        <ng-container *ngFor="let channel of groupedChannels['single']; let i = index; let last = last">
          <itl-channel-list-entry
            *ngIf="!this.isSystemChat(channel)"
            [channelName]="channel.friendlyName"
            [lastMessageText]="channel.lastMessage?.body"
            [lastMessageSenderName]="channel.lastMessage?.authorName"
            [lastMessageStatus]="channel.lastMessage?.status"
            [lastListItem]="last"
            [lastActivityDate]="channel | lastChannelActivity"
            [showIsSelectedBadge]="true"
            [isSelected]="sid === channel.sid"
            (click)="this.selectChannel(channel)"
          ></itl-channel-list-entry>
        </ng-container>

        <itl-warning-item *ngIf="!groupedChannels['single'] || groupedChannels['single'].length === 0">
          {{ 'chat.select-user-dialog.noLastChats' | translate }}
        </itl-warning-item>
        <itl-button
          [ngClass]="{ disabled: showMoreButtonDisabled }"
          expand="block"
          class="show-more-button"
          (click)="showMore()"
          *ngIf="allSingleChannels.length > 5"
          >{{ 'chat.select-user-dialog.showMore' | translate }}</itl-button
        >
      </itl-section>
      <itl-section [title]="'chat.select-user-dialog.groups' | translate" [isDetail]="false">
        <itl-channel-list-entry
          *ngFor="let channel of groupedChannels['multi']; let i = index; let last = last"
          [channelName]="channel.friendlyName"
          [lastMessageText]="channel.lastMessage?.body"
          [lastMessageSenderName]="channel.lastMessage?.authorName"
          [lastMessageStatus]="channel.lastMessage?.status"
          [lastActivityDate]="channel | lastChannelActivity"
          [lastListItem]="last"
          [showIsSelectedBadge]="true"
          [isSelected]="sid === channel.sid"
          (click)="this.selectChannel(channel)"
        ></itl-channel-list-entry>
        <itl-warning-item *ngIf="!groupedChannels['multi'] || groupedChannels['multi'].length === 0">
          {{ 'chat.select-user-dialog.noGroupChats' | translate }}</itl-warning-item
        >
      </itl-section>
    </ng-container>
    <itl-loading-indicator *ngIf="isLoading"></itl-loading-indicator>
    <ng-container *ngIf="showSearchList && !isLoading">
      <itl-channel-list-entry
        *ngFor="let user of userResults; let last = last"
        [channelName]="user.firstName + ' ' + user.lastName"
        [lastListItem]="last"
        [showIsSelectedBadge]="true"
        [isSelected]="fieldNurseId === user._id"
        (click)="this.selectUser(user)"
      ></itl-channel-list-entry>
      <itl-warning-item *ngIf="userResults.length === 0">
        {{ 'chat.select-user-dialog.noSearchResults' | translate }}
      </itl-warning-item>
    </ng-container>
  </div>
</itl-wizard-page>
