import { Inject, Injectable } from '@angular/core';
import { IMaintenanceService } from 'src/app/business/maintenance/contracts/maintenance';

import makeDebug from '../../../../makeDebug';
import { MaintenanceInfoService } from '../../components/maintenance/maintenance-info-modal/maintenance-info.service';
import { MaintenanceService } from '../../services/maintenance/maintenance.service';
import { ISyncContext } from '../contracts/sync/sync-context';
import { ISyncElement } from '../contracts/sync/sync-element';
import { MaintenanceWatchStarter as MaintenanceWatch } from '../contracts/sync/sync-endpoints';

const debug = makeDebug('sync:maintenance-watch-starter');

@Injectable()
export class MaintenanceWatchStarter implements ISyncElement {
  public get name(): string {
    return MaintenanceWatch;
  }

  constructor(
    @Inject(MaintenanceService) private _maintenanceService: IMaintenanceService,
    // dont delete this line, otherwise no modal info dialog will popup at start
    private _maintenanceInfoService: MaintenanceInfoService
  ) {}

  public async execute(context: ISyncContext): Promise<void> {
    debug('entered execute function', context.params.isOnline);
    await this._maintenanceService.startWatchingMaintenance();
  }
}
