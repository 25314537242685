import { Inject, Injectable } from '@angular/core';
import { Client as TwilioClient, Conversation as TwilioConversation } from '@twilio/conversations';
import { ChatDbService } from 'src/app/shared/services/chat/data/chat-db.service';
import makeDebug from 'src/makeDebug';

import { ChatSendService } from '../chat-send.service';
import { ChatChannel, ChatMessage } from '../data/db-schema';
import { Chat } from '../model/chat-instance';
import { TWILIO_AGENT_TOKEN } from './agent-twilio.factory';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twilio.factory';

const debug = makeDebug('services:chat:twilio-sendService');

@Injectable({
  providedIn: 'root',
})
export class TwilioChatSendService implements ChatSendService {
  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioClient>,
    @Inject(TWILIO_AGENT_TOKEN) private readonly _twilioAgentChatClient: Promise<TwilioClient>,
    private readonly twilioDtoHelper: TwilioToChatDtoHelpersService,
    private readonly chatDBService: ChatDbService
  ) {}

  /**
   * Only works in online mode
   */
  public async createChat(name: string, chatUserChatIdentitites: string[]): Promise<ChatChannel> {
    const client = await this._twilioChatClient;
    let uniqueName: string;
    if (chatUserChatIdentitites.length === 2) {
      chatUserChatIdentitites.sort((a, b) => a.localeCompare(b));
      uniqueName = chatUserChatIdentitites.join('§');
      if (uniqueName.indexOf('§') === -1) {
        return;
      }
      const existingChannel = await this.tryToGetUniqueChannel(uniqueName);
      if (existingChannel) {
        await this.writeParticipantsToDb(existingChannel);
        return this.twilioDtoHelper.convertToChatChannel(existingChannel);
      }
    }

    const channel = await client.createConversation({
      friendlyName: name,
      uniqueName,
    });
    const invites = chatUserChatIdentitites.map(identity => channel.add(identity));
    await Promise.all(invites);
    await this.writeParticipantsToDb(channel);
    // TODO test
    return this.twilioDtoHelper.convertToChatChannel(channel);
  }

  private async writeParticipantsToDb(channel: TwilioConversation) {
    for (const participant of await channel.getParticipants()) {
      await this.chatDBService.upsertChannelMember(this.twilioDtoHelper.convertToChatMember(participant));
    }
  }

  private async tryToGetUniqueChannel(uniqueName: string) {
    const client = await this._twilioChatClient;
    try {
      const existingChannel = await client.getConversationByUniqueName(uniqueName);
      return existingChannel;
    } catch (error) {
      window.logger.error('Getting channel by unique name failed.', error);
    }
  }

  public async fetchMessagesOfConversation(
    conversationSid: string,
    anchor: number,
    count: number,
    chat = Chat.Alberta
  ): Promise<ChatMessage[]> {
    debug('fetch messages of channel', { conversationSid, anchor, count });
    const client = chat === Chat.Alberta ? await this._twilioChatClient : await this._twilioAgentChatClient;
    if (!client) {
      return [];
    }
    const conversation = await client.getConversationBySid(conversationSid);
    const messages = await conversation.getMessages(count, anchor);
    const convertedMessages = [];

    for (const message of messages.items) {
      const convertedMessage = await this.twilioDtoHelper.convertToChatMessage(message);
      convertedMessages.push(convertedMessage);
    }
    debug('got messages from twlio', { conversationSid, anchor, count, convertedMessages });
    return convertedMessages;
  }

  public async sendMessage(
    conversationId: string,
    bodyText: string,
    attributes: {},
    chat = Chat.Alberta
  ): Promise<ChatMessage> {
    debug('send message in queue', bodyText);
    const twilioClient: TwilioClient =
      chat === Chat.Alberta ? await this._twilioChatClient : await this._twilioAgentChatClient;
    if (!twilioClient) {
      return null;
    }
    const conversation: TwilioConversation = await twilioClient.getConversationBySid(conversationId);
    if (!conversation) {
      throw new Error('conversation not found:' + conversationId);
    }
    debug('sending message...', bodyText);

    const result = await conversation.sendMessage(bodyText, attributes);
    if (!Number.isInteger(result)) {
      throw new Error('send-failed-with:' + result);
    }
    const message = await conversation.getMessages(1, result);
    debug('got send result for', bodyText, 'result:', result);
    return this.twilioDtoHelper.convertToChatMessage(message.items[0]);
  }

  public async setConsumptionIndex(conversationId: string, index: number): Promise<void> {
    debug('set consumption index', { conversationId, index });
    const twilioClient: TwilioClient = await this._twilioChatClient;
    if (!twilioClient) {
      return;
    }
    const conversation: TwilioConversation = await twilioClient.getConversationBySid(conversationId);
    if (!conversation) {
      throw new Error('conversation not found:' + conversationId);
    }
    await conversation.advanceLastReadMessageIndex(index);
  }

  public async updateConversationAttributes(conversationSid: string, attributes = {}): Promise<void> {
    const twilioClient: TwilioClient = await this._twilioAgentChatClient;
    if (!twilioClient) {
      return;
    }

    const conversation: TwilioConversation = await twilioClient.getConversationBySid(conversationSid);

    if (!conversation) {
      throw new Error('converstaion not found:' + conversationSid);
    }

    await conversation.updateAttributes({ ...(conversation.attributes as any), ...attributes }).catch(error => {
      console.error(JSON.stringify(error));
    });
  }
}
